import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col} from 'react-bootstrap';
import { wmsService } from "../services/wmsService"


class SettingWarehouseEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            warehouse: null, 
            locationname: null,
            description: null,                   
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);    
    }

    componentDidMount() { 
        console.log(this.props.location.state);
        if (this.props.location.state) { 
            this.setState({warehouse: this.props.location.state,
                locationname: this.props.location.state.locationName,
                description: this.props.location.state.locationDescription,
            })
        }
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.state[name] = target.value;
      }

    handleSubmit(e) {
        e.preventDefault();     
        let create = false;   
        if (!this.state.warehouse) {
            this.state.warehouse = {};
            create = true;
        }
        this.state.warehouse.locationName = this.state.locationname;
        this.state.warehouse.locationDescription = this.state.description;
        console.log(this.state.warehouse);
        if (create) {
            wmsService.createWarehouse(this.state.warehouse).then(witm => {
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    this.props.history.goBack();
                }             
            });
        } else {
            wmsService.updateWarehouse(this.state.warehouse).then(witm => {
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    this.props.history.goBack();
                }             
            });
        }
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    render() {                
        
        let disabled = false;
        if (this.state.locationname) {
            disabled = true;
        }
        return (
            
            <div>                
                <Card>
                    <Card.Header>Warehouse Edit                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                      
                        <Form.Row>                            
                            <Form.Group as={Col} controlId="locationname"  onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Warehouse Location Name</Form.Label>                            
                                <Form.Control disabled={disabled} required={true} placeholder="" defaultValue={this.state.locationname} />                                    
                            </Form.Group>
                        </Form.Row> 

                        <Form.Row>                            
                            <Form.Group as={Col} controlId="description"  onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Description</Form.Label>                            
                                <Form.Control  placeholder="" defaultValue={this.state.description} />                                    
                            </Form.Group>
                        </Form.Row> 

                        <div style={{float: "left"}} >                                                               
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>                                                      
                  </div>
                      </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default SettingWarehouseEdit;