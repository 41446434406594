import React from "react";
import { wmsService } from '../services/wmsService';
import { inventoryService } from '../services/inventoryService';
import { Card, Button, InputGroup, Form, Col, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Redirect } from "react-router-dom";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

class TransferAddLineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceid: undefined,

      id: undefined,
      transferid: undefined,
      quoteId: undefined,
      itemId: undefined,
      item: undefined,
      description: undefined,
      qty: undefined,
      listPrice: undefined,
      discount: undefined,
      unitPrice: undefined,
      tax1: undefined,
      tax01: undefined,
      scheduledShipDate: undefined,
      companyId: undefined,
      warehouseloc: undefined,
      mou: undefined,
      ////
      edititem: undefined,
      origitem: undefined,
      origItemId: undefined,

      taxOptions: [],
      mouOptions: [],
      gobackto: undefined,
      searchmodal: false,
      invitems: [],
      invcolumns: [
        {
          dataField: 'id',
          text: 'id',
          hidden: true
        }, {
          dataField: 'uniqueid',
          text: 'uniqueid',
          hidden: true
        }, {
          dataField: 'item',
          text: 'Item',
          hidden: false,
          sort: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          },
          formatter: (cellContent, row) => {
            let config = JSON.parse(localStorage.getItem('config'));
            //console.log(config);
            let st = JSON.parse(config.serviceorderstatuses);
            return (
              <h6><small>
                {row.item &&
                  <div className="label label-success">{row.item}</div>
                }
                {row.description &&
                  <div className="label label-success">{row.description}</div>
                }
              </small></h6>
            );
          }
        }, {
          dataField: 'description',
          text: 'Description',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'inventory',
          text: 'Quantity',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        }, {
          dataField: 'unitofmeasure',
          text: 'UOM',
          hidden: true,
          headerStyle: {
            backgroundColor: '#D9D8D9'
          }
        },
      ],
      selectInvRow: {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#adb5bd',
        onSelect: (row, isSelect, rowIndex, e) => {
          this.handleSelectedLineItem(row);
        }
      },
      selectedRow: undefined,
      mouOpt: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectItemClose = this.handleSelectItemClose.bind(this);
    this.handleSelectedLineItem = this.handleSelectedLineItem.bind(this);
    this.searchItems = this.searchItems.bind(this);
    this.goBackWithInvoice = this.goBackWithInvoice.bind(this);
    this.getMou = this.getMou.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params; //invoiceId
    this.state.transferid = id;
    //console.log(this.props.match.params, this.state.transferid);
    this.getMou();
  }

  getMou() {
    let mouOpt = [];
    mouOpt.push(<option>Choose...</option>);

    let user = JSON.parse(localStorage.getItem('user'));
    let uom = {};
    if (user.config.mou && user.config.mou.length > 0) {
      for (let i = 0; i < user.config.mou.length; i++) {
        let obj = user.config.mou[i];
        //console.log('selected test', this.state.mou, obj);
        if (this.state.mou === obj) {
          //console.log('selected', this.state.mou, obj);
          mouOpt.push(<option value={obj} selected>{obj}</option>);
        } else {
          mouOpt.push(<option value={obj}>{obj}</option>);
        }

        uom[user.config.mou[i]] = user.config.mou[i];
        i++;
      }
    }
    this.setState({mouOpt: mouOpt});
  }

  handleInputChange(event) {
    const target = event.target;
    //alert(target.id + ', ' + target.value)
    const name = target.id;
    //alert(name + ', ' + target.value);
    if (name === 'tax01' && target.value === "Choose...") {
      this.setState({
        tax1: undefined,
        tax01: undefined
      })
    }
    else {
      this.setState({
        [name]: target.value
      });
      if (name === 'tax01') {
        //alert(JSON.stringify(this.state.taxOptions));
        for (let i = 0; i < this.state.taxOptions.length; i++) {
          let o = this.state.taxOptions[i];
          if (o["name"] === target.value) {
            //alert(o["rate"]);         
            this.setState({ tax1: o["rate"] });
          }
        }
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    //alert('handleSubmit...:');
    let item = {};
    item.transferid = this.state.transferid;
    item.itemid = this.state.itemId;
    item.itemcode = this.state.item;
    item.description = this.state.description;
    item.qty = this.state.qty;
    item.qtyfulfilled = 0;
    item.uom = this.state.mou;

    wmsService.addOrUpdateTransferLineItem(item).then(titm => {
      if (titm && titm === 401) {
        this.props.history.push("/login");
      } else {
        //goback...        
        wmsService.getTransfer(this.state.transferid).then(tran => {
          if (tran && tran === 401) {
            this.props.history.push("/login");
          } else {
            var { from } = this.props.location.state || { from: { pathname: "/transferview" } };
            this.props.history.push(from, tran);
          }
        });
      }
    });
  }

  goBackWithInvoice = () => {
    wmsService.getTransfer(this.state.transferid).then(tran => {
      if (tran && tran === 401) {
        this.props.history.push("/login");
      } else {
        var { from } = this.props.location.state || { from: { pathname: "/transferview" } };
        this.props.history.push(from, tran);
      }
    });
  }

  handleCancel(event) {
    this.goBackWithInvoice();
  }

  searchItems = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    //wmsService.getWmsInventorySearchIcludePrice(this.state.edititem, user.config.inventoryLocation).then(items => {
    inventoryService.searchItem(this.state.edititem).then(items => {
      if (items && items === 401) {
        this.props.history.push("/login");
      } else {
        console.log(items);
        this.setState({
          invitems: items,
          searchmodal: !this.state.searchmodal
        });
      }
    });

  }

  handleSelectItemClose = () => {
    this.setState({ searchmodal: !this.state.searchmodal });
  }

  handleSelectItem = () => {
    if (this.state.selectedRow && this.state.selectedRow.id) {
      console.log(this.state.selectedRow);
      this.state.mou = this.state.selectedRow.unitofmeasure;
      this.state.qty = this.state.selectedRow.qty;

      this.setState({
        item: this.state.selectedRow.item,
        itemId: this.state.selectedRow.id,
        description: this.state.selectedRow.description,
        qty: this.state.selectedRow.inventory,
        listPrice: this.state.selectedRow.actual_unit_cost,
        discount: this.state.selectedRow.discount,
        unitPrice: this.state.selectedRow.unit_cost,
        warehouseloc: this.state.selectedRow.invlocation,
        searchmodal: !this.state.searchmodal,
        mou: this.state.selectedRow.unitofmeasure,
      });
      //console.log(this.state.mou);
      this.getMou();
      
      //alert(this.state.selectedRow.item + ',' + this.state.edititem + ', ' + this.state.description);
    } else {
      this.setState({
        searchmodal: !this.state.searchmodal
      });
    }
  }

  handleSelectedLineItem = (row) => {
    //alert(JSON.stringify(row));
    this.setState({ selectedRow: row });

    // this.setState({
    //   itemId: row.id,   
    //   item: row.item,
    //   description: row.description,
    //   qty: row.inventory,
    //   listPrice: row.actualUnitCost,
    //   discount: row.discount,
    //   unitPrice: Number(row.unitCost).toFixed(2)
    // });
  }

  render() {

    if (this.state.gobackto) {
      return <Redirect to={this.state.gobackto} />
    }    
    //console.log(this.state.mouOpt);
    return (
      <div className="content-screen">
        <Card style={{ width: '36rem' }}>
          <Card.Header>

            <Form>
              <div style={{ width: "70%", display: "inline-block" }}>

                <Form.Group as={Col} style={{ marginBottom: '1px' }} controlId="edititem" onChange={this.handleInputChange}>
                  <InputGroup>
                    <Form.Control placeholder="Search item in inventory" defaultValue={this.state.edititem} />
                    <InputGroup.Append>
                      <Button onClick={this.searchItems} variant="outline-primary">Search</Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

              </div>
            </Form>

          </Card.Header>
          <Card.Body>

            <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Row></Form.Row>
              <Form.Row>
                    <Form.Group as={Col} controlId="item" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                      {this.state.id ?
                      <Form.Control disabled placeholder="Enter item code" defaultValue={this.state.item} />
                      : <Form.Control placeholder="Enter item code" defaultValue={this.state.item} />
                      }                        
                    </Form.Group>
                   
                  </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="description" onChange={this.handleInputChange}>
                  <Form.Label style={{ fontSize: "14px" }}>Description</Form.Label>
                  <Form.Control required="true" placeholder="Enter item description" defaultValue={this.state.description}></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="qty" onChange={this.handleInputChange}>
                  <Form.Label style={{ fontSize: "14px" }}>Quantity</Form.Label>
                  <Form.Control required="true" type="Number" placeholder="Enter item quantity" defaultValue={this.state.qty}></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="mou" onChange={this.handleInputChange}>
                  <Form.Label style={{ fontSize: "14px" }}>MOU</Form.Label>
                  <Form.Control as="select" defaultValue={this.state.mou} ref="templateSelect">
                    {this.state.mouOpt}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <div style={{ float: "left" }} >
                {/* {this.state.id && 
                      <Button type="submit" variant="outline-primary  mr-3" size="sm" >Update</Button>
                      } */}

                <Button type="submit" variant="outline-primary  mr-3" size="sm">Save</Button>

                <Button onClick={this.handleCancel} variant="outline-primary  mr-3" size="sm" >Cancel</Button>
              </div>
            </Form>

          </Card.Body>

        </Card>

        <>
          <Modal show={this.state.searchmodal} onHide={this.handleSelectItemClose.bind(this)}

            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Select an inventory item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ToolkitProvider
                bootstrap4
                keyField='uniqueid'
                data={this.state.invitems}
                columns={this.state.invcolumns}
                search
              >
                {
                  props => (
                    <div>

                      <BootstrapTable style={{ scrollY: "200px", scrollCollapse: true }}
                        bootstrap4
                        {...props.baseProps}
                        {...props.paginationFactory}
                        {...props.striped}
                        {...props.defaultEquipSorted}
                        selectRow={this.state.selectInvRow}
                        //pagination={ paginationFactory( {sizePerPage: 6}) }                     
                        pagination={paginationFactory({ hideSizePerPage: true })}
                        bordered={false}
                        condensed={true}
                        //defaultSorted = { this.state.defaultSorted } 
                        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>

            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'left' }}>
              <div style={{ float: "left" }} >
                <Button variant="outline-primary mr-3" onClick={this.handleSelectItem.bind(this)}>
                  Select
                </Button>
                <Button variant="outline-primary" onClick={this.handleSelectItemClose.bind(this)}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>

      </div>
    );
  }
}

export default TransferAddLineItem;