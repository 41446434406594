import React from 'react';
import "./../App.css";
import { Card, Table, Col, Form, Button, InputGroup} from 'react-bootstrap';
import { wmsService } from "../services/wmsService";
import Scanner from "./Scanner";

class ShipItems extends React.Component {
    constructor(props) {
        super(props);
        console.log('ShipItems', props);
        console.log('props.location.state', props.location.state);
        this.state = {
            packing: undefined,    
            carrier: null,
            label: undefined,
            fob: undefined,
            container: undefined,
            weight: undefined,
            dimension: undefined,
            note: undefined,
            advance: false,
            showScanner: false,  
            alreadypicked: undefined,                   
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.loadAll = this.loadAll.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);        
        this.advance = this.advance.bind(this);  
    }

    componentDidMount() {         
        if (this.props.location.state ) {
            this.state.packing = this.props.location.state;
            if (this.state.packing.items && this.state.packing.items.length > 0) {
                for (let ii=0; ii<this.state.packing.items.length; ii++) {
                    let i = this.state.packing.items[ii];                
                    i.remainingqtytoship = i.qty - i.shippedqty;
                    i.newqtytoship = 0;                    
                }              
            }
            this.setState({packing: this.props.location.state});   
            
            let actualtransferitems = [];
            if (this.props.location.state.fromtransfer === 0 && this.props.location.state.transferitem) {
                this.state.packing.actualtransfer = this.props.location.state.transfer;
                this.props.location.state.transferitem.forEach(e=> {
                    if (this.state.packing.items && this.state.packing.items.length > 0) {
                        for (let ii=0; ii<this.state.packing.items.length; ii++) {
                            let i = this.state.packing.items[ii];     
                            if (e.itemcode === i.item) {
                                if (this.containsObject(e, actualtransferitems) === true) {
                                    break;
                                } else {
                                    e.batch = i.batch;
                                    e.qty = i.qty; // This is the qty we are actually transferring in this transaction.
                                    actualtransferitems.push(e);
                                    i.description = e.description;
                                    break;
                                }                                
                            }                              
                        }              
                    }
                    
                });
                this.state.packing.actualtransferitems = actualtransferitems;
                this.setState({packing: this.state.packing});
            }
        }
    }
    
    containsObject = (obj, list) => {
        var x;
        for (x of list) {
            console.log(x, obj)
            if (x.itemid === obj.itemid) {            
                return true;
            }
        }    
        return false;
    }

    handleInputChange = (event) => {   
        event.preventDefault(); 
        const target = event.target;
        const name = target.id; 
        this.state[name] = target.value;  
        // this.setState({
        //   [name]: target.value
        // });   
    }

    handleSubmit(e) {
        e.preventDefault();
        let ready = true;
        if (this.state.packing.items && this.state.packing.items.length > 0) {
            for (let ii=0; ii<this.state.packing.items.length; ii++) {   
                let i = this.state.packing.items[ii]; 
                if (!i.newqtytoship || i.newqtytoship <= 0) {
                    ready = false;
                    break;
                }
            }             
        }
        if (ready === false) {
            alert ("Failed. You first need to load all the available items before completing the transfer.")
        } else {            
            
            let user = JSON.parse(localStorage.getItem('user'));        
            
            console.log(this.state.packing);  
            
            if (this.state.packing.fromtransfer === 0) {
                let transfer = {};
                transfer.companyId = user.companyid;
                transfer.id = this.state.packing.actualtransfer.id;
                transfer.transfernum = this.state.packing.actualtransfer.transfernum;
                transfer.requestedby = this.state.packing.actualtransfer.requestedby;                             
                transfer.requestingwh = this.state.packing.actualtransfer.requestingwh;
                transfer.fulfillingwh = this.state.packing.actualtransfer.fulfillingwh;
                transfer.pallet = this.state.packing.pallet;

                let it = [];
                this.state.packing.items.forEach(e => {
                    let i = {};
                    i.companyId = user.companyid;
                    i.discount = 0;
                    i.item = e.itemcode;
                    i.itemId = e.itemid;
                    i.lotNumber = e.batch;
                    i.qty = e.qty;
                    i.tax = 0;
                    i.unitCost = 0;  
                    it.push(i);
                
                });
                transfer.items = it;    
                transfer.packandshipid = this.state.packing.id;            
                transfer.actualtransferitems = this.state.packing.actualtransferitems;

                wmsService.postWmsTransfer(transfer).then(pord => {
                    console.log(pord);
                    if (pord && pord === 401) {
                        this.props.history.push("/login");
                    } else {
                        alert ("Transfer is completed sucessfully.")
                        this.props.history.goBack();
                    }
                });
            } else {
                wmsService.postGoodsIssue(this.state.packing).then(pord => {
                    console.log(pord);
                    if (pord && pord === 401) {
                        this.props.history.push("/login");
                    } else {
                        if (user.config.wmsconfig.picktoship) {
                            this.props.history.push('/picking');
                            //this.props.history.goBack();
                        } else {
                            var { from } = { from: { pathname: "/shipping" } };  
                            this.props.history.push(from);
                        }
                    }
                });
            }
        }
    }
    
    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }

    loadAll =() => {
        if (this.state.packing.items && this.state.packing.items.length > 0) {
            for (let ii=0; ii<this.state.packing.items.length; ii++) {   
                let i = this.state.packing.items[ii];  
                i.newqtytoship = i.remainingqtytoship;                                        
            } 
            this.setState({packing: this.state.packing});             
        }
    }

    advance = () => {
        this.setState({advance: !this.state.advance});
    }

    render() {
        console.log('ShipItems', this.state.packing);
        
        if (!this.state.packing) {
            return (<div></div>);
        }
        console.log(this.state.packing.fromtransfer);
        let displayadvavace = false;
        if (this.state.advance && this.state.packing.fromtransfer !== 0) {
            displayadvavace = true;
        }
        const carrier = [];
        carrier.push(<option key={0}>Select...</option>);
        carrier.push(<option key={1}>{'UPS'}</option>);
        carrier.push(<option key={2}>{'FedEx'}</option>);

        let labels = [];
        labels.push(<option key={0}>Default</option>);
        labels.push(<option key={1}>Large</option>);
        labels.push(<option key={2}>Small</option>);


        let ritms = [];
        if (this.state.packing.items && this.state.packing.items.length > 0) {
            for (let ii=0; ii<this.state.packing.items.length; ii++) {   
                let i = this.state.packing.items[ii];                          
                ritms.push(<tr key={ii.toString()}>
                    <td>{i.item}</td>
                    <td>{i.remainingqtytoship}</td>
                    <td>{i.newqtytoship}</td>                    
                </tr>);
                
            }              
        }
        this.state.counter = this.state.counter +1;
        return (
            
            <div>                
                <Card key = {this.state.counter}>
                    { this.state.packing.fromtransfer !== 0 && 
                    <Card.Header>Shipping Items                        
                    </Card.Header>
                    }
                    { this.state.packing.fromtransfer === 0 && 
                    <Card.Header>Load Transfer Items                        
                    </Card.Header>
                    }
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text>
                                <div className="font-weight-bold">Order:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.packing.sorder.orderNum}</div>                            
                                <div className="font-weight-bold">Pallet:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.packing.pallet}</div>                            
                            </Card.Text>
                            { this.state.packing.fromtransfer !== 0 && 
                            <>
                            <Form.Row>
                                <Form.Group as={Col} controlId="carrier" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Carrier</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.carrier} >
                                            {carrier}                            
                                    </Form.Control>            
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="label" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Label</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.label} >
                                            {labels}                            
                                    </Form.Control>            
                                </Form.Group>
                            </Form.Row>
                            </>
                            }
                            {displayadvavace &&
                            <div>
                            <Form.Row>
                                <Button variant="link" size="sm" type="button" onClick={() => {this.advance()}}>Advance</Button>                              
                            </Form.Row>
                            
                            
                            <Form.Row>
                                <Form.Group as={Col} controlId="fob" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>FOB</Form.Label>
                                    <Form.Control  placeholder="Enter FOB" defaultValue={this.state.fob} />                                                                                                            
                                </Form.Group>
                            </Form.Row> 

                            <Form.Row>
                                <Form.Group as={Col} controlId="container" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Container</Form.Label>
                                    <Form.Control  placeholder="Enter container ID" defaultValue={this.state.container} />                                                                                                            
                                </Form.Group>
                            </Form.Row> 

                            <Form.Row>
                                <Form.Group as={Col} controlId="weight" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Weight</Form.Label>
                                    <Form.Control  placeholder="Enter weight" defaultValue={this.state.weight} />                                                                                                            
                                </Form.Group>
                            </Form.Row> 

                            <Form.Row>
                                <Form.Group as={Col} controlId="dimensions" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Dimensions</Form.Label>
                                    <Form.Control  placeholder="Enter dimensions" defaultValue={this.state.dimensions} />                                                                                                            
                                </Form.Group>
                            </Form.Row> 

                            <Form.Row>
                                <Form.Group as={Col} controlId="note" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Note</Form.Label>
                                    <Form.Control  placeholder="Enter shipping note" defaultValue={this.state.note} />                                                                                                            
                                </Form.Group>
                            </Form.Row> 
                            </div>
                            }

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Packed Qty</th>
                                        { this.state.packing.fromtransfer !== 0 && 
                                        <th>Loaded Qty for Ship</th>
                                        }
                                        { this.state.packing.fromtransfer === 0 && 
                                        <th>Loaded Qty for Transfer</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {ritms}
                                </tbody>
                            </Table>
                            
                            
                            <div style={{float: "left"}} >    
                                {/*                                                                                         
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.scan()}}>Scan License</Button>                              
                                */}
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.loadAll()}}>Load All</Button>                              
                                { this.state.packing.fromtransfer !== 0 && 
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="submit">Ship</Button>
                                }
                                { this.state.packing.fromtransfer === 0 && 
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="submit">Complete Transfer</Button>
                                }
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>                            
                        </Form>    
                        <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>                    
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        decodedText = "PACKING";
        if (decodedText === this.state.packing.pallet) {
            this.loadAll();
        } else {
            alert('Please scan the correct license plate or PALLET.');
        }        
        this.setState({showScanner: false}); 
    }
}
export default ShipItems;