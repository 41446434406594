import React, { forwardRef } from "react";
import { Button } from 'react-bootstrap';
import "./../App.css";
import { wmsService } from "../services/wmsService";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import { TramOutlined } from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),    
  };

class OperationInboundOrderStatus extends React.Component {
    constructor(props) {
        super(props);       
        this.state = {    
            count: new Map(),
            scanitem: null,
            cycle: null,                          
            showScanner: false,  
            reload: 0,
            selectedrows: undefined,
            warehouse: undefined,
            data: [],
            totalCount: 0,
            page: 0,
            columns: [
                {
                  field: 'id',
                  title: 'id',  
                  hidden: true,        
                }, 
                {
                    field: 'srcorder',
                    title: 'Order',
                    editable: false,
                },
                {
                    field: 'licenseplate',
                    title: 'License Plate',
                    editable: false,
                },
                {
                    field: 'status',
                    title: 'status',                    
                    render: rowData => {if (rowData.status) {
                        if (rowData.status === 1) {
                            return 'Received';
                        }
                        if (rowData.status === 2) {
                            return 'Goods receipt';
                        }
                        if (rowData.status === 3) {
                            return 'Put Away';
                        }
                        return "Reconcilled"
                    }},
                },
                {
                    field: 'item',
                    title: 'Item',                      
                },
                {
                  field: 'rcved',
                  title: 'Qty Rcved',              
                },
                {
                    field: 'batch',
                    title: 'Batch',              
                  },
                  {
                    field: 'expirationdate',
                    title: 'Expiration',  
                    render: rowData => {if (rowData.expirationdate) { return new Date(rowData.expirationdate).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}},            
                  },                              
                {
                    field: 'updateddate',
                    title: 'Last Update',
                    render: rowData => {if (rowData.updateddate) { return new Date(rowData.updateddate).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}},
                    editable: false,
                },                                         
              ],                                      
        };
        this.goBack = this.goBack.bind(this);        
    }

    componentDidMount() {  
        console.log('props.location.state', this.props.location.state);       
        if (this.props.location.state ) {
            this.setState({cycle: this.props.location.state});
        }        

        let user = JSON.parse(localStorage.getItem('user'));
        this.state.warehouse = user.config.inventoryLocation;
        wmsService.getInboundActivityPage(this.state.warehouse , 0, 200).then(result => {
            if (result && result === 401) {             
                this.props.history.push("/login");  
            } else {
                this.setState({data: result});
            }
        });
    }
    
    
    goBack =() => {
        this.props.history.goBack();
    }
    
    render() {
        return (
                <div>
                <MaterialTable key={this.state.count}
                    title="Inbound Orders Status"
                    icons={tableIcons}
                    columns={this.state.columns}
                    data={this.state.data}
                    // data = {query =>(
                    //     new Promise((resolve, reject) => {
                    //         wmsService.getInboundActivityPage(this.state.warehouse , query.page * query.pageSize, query.pageSize).then(result => {
                    //             if (result && result === 401) {             
                    //                 this.props.history.push("/login");  
                    //             } else {
                    //                 resolve({
                    //                     data: result,
                    //                     page: result.page - 1,
                    //                     totalCount: result.total,
                    //                   });
                    //             }
                    //         })
                    //     })
                    // )}
                                         
                    options={{
                        search: true,
                        paging: true,
                        actionsColumnIndex: -1,
                        pageSize: 20,
                        exportButton: true,
                    }}  
                                      
                />
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                              
                </div>
        );
    }
}
export default OperationInboundOrderStatus;