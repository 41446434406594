import React from 'react';
import "./../App.css";
import {Box, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
//import Box from '@mui/material/Box';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
import Cycle from '@material-ui/icons/EventNote';
import CycleBlind from '@material-ui/icons/FormatListNumberedRtl';
import Physical from '@material-ui/icons/DirectionsWalk';
import BlurLinear from '@material-ui/icons/BlurLinear';
import { Link } from 'react-router-dom'


class Counting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
        };

    }

    handleChange(e) {
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { location: { pathname } } = this.props
        return (
            
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>                    
                    <ListItem  button component={Link} to="/countcyitm" selected={'/countcyitm' === pathname}>
                        
                        <ListItemIcon>
                            <Cycle />
                        </ListItemIcon>
                        <ListItemText primary="Cycle Count By Item" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/countcyitmblnd" selected={'/countcyitmblnd' === pathname}>
                        
                        <ListItemIcon>
                            <CycleBlind />
                        </ListItemIcon>
                        <ListItemText primary="Cycle Count By Item - Blind" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/countcy" selected={'/countcy' === pathname}>
                        
                        <ListItemIcon>
                            <BlurLinear />
                        </ListItemIcon>
                        <ListItemText primary="Cycle Count By Bin Without Batch" />
                        
                    </ListItem>
                    
                    <ListItem  button component={Link} to="/countcyitm" selected={'/countcyitm' === pathname}>
                        
                        <ListItemIcon>
                            <Physical />
                        </ListItemIcon>
                        <ListItemText primary="Physical Count" />
                        
                    </ListItem>                    
                    </List>
                </nav>
            </Box>      
        );
    }
}
export default Counting;