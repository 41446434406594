import React from 'react';
import "./../App.css";
import { Card, Table, Col, Form, Button, InputGroup} from 'react-bootstrap';
import { wmsService } from "../services/wmsService";
import Scanner from "./Scanner";

class PackItems extends React.Component {
    constructor(props) {
        super(props);
        //console.log('ReceiveItems', props);
        //console.log('props.location.state', props.location.state);
        this.state = {    
            lp: null,
            sorder: null,
            sorderid: null,            
            items: null,
            pkitmcode: undefined,
            pkqty: undefined,
            counter: 0,
            wmsempactivityreport: undefined,
            showScanner: false,  
            alreadypicked: undefined,                   
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);        
    }

    componentDidMount() {         
        if (this.props.location.state && this.props.location.state.lp && this.props.location.state.sorder) {
            this.state.sorder = this.props.location.state.sorder;
            this.state.lp = this.props.location.state.lp;
            this.state.wmsempactivityreport = this.props.location.state.wmsempactivityreport;
            let itm = [];
            for (let i of this.props.location.state.lineItem) {
                i.originalpackedqty = i.totalpackedqty;
                itm.push(i);
                this.state.sorderid = i.sorderid;
            }
            this.setState({items: itm}) ;  
        }
    }
    
    handleInputChange = (event) => {   
        event.preventDefault(); 
        const target = event.target;
        const name = target.id; 
        this.state[name] = target.value;  
        // this.setState({
        //   [name]: target.value
        // });   
    }

    handleSubmit(e) {
        e.preventDefault();
        let packed = [];
        let found = false;
        for (let ii=0; ii<this.state.items.length; ii++) {
            if (this.state.items[ii].totalpackedqty - this.state.items[ii].originalpackedqty > 0) {
                packed.push(this.state.items[ii]);
            }
        }
        if (packed.length > 0) {            
            this.state.items = packed;
            var { from } =  { from: { pathname: "/packingdone" } };  
            this.props.history.push(from, this.state);
        } else {
            alert ('You did not add items to pack. Please select items and quantity first.');
        }            
    }
    
    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }

    conform =() => {
        console.log('Conform is called', this.state.pkitmcode, this.state.pkqty);
        if (!this.state.pkitmcode) {
            alert('Please enter the item code');
        } else if (Number(this.state.pkqty) > 0) {
            for (let ii=0; ii<this.state.items.length; ii++) {
                if (this.state.items[ii].item === this.state.pkitmcode) {
                    if ((this.state.items[ii].qty - this.state.items[ii].totalpackedqty) < Number(this.state.pkqty)) {
                        alert("You can't packed more items than allowed");
                    } else {
                        this.state.items[ii].totalpackedqty = this.state.items[ii].totalpackedqty + Number(this.state.pkqty);
                        this.setState({pkitmcode: undefined, pkqty: undefined});
                    }
                }
            }
        } else {
            alert('Please enter the quantity');
        }
    }

    render() {
        console.log('PickItems', this.state);
        if (!this.state.sorder) {
            return (<div></div>);
        }
        let ritms = [];
        const itemcodes = [];
        itemcodes.push(<option key={0}>Select...</option>);
        if (this.state.items && this.state.items.length > 0) {
            for (let ii=0; ii<this.state.items.length; ii++) {
                let i = this.state.items[ii];                
                let uom = i.uom;
                if (uom === 'null') {
                    uom = '';
                }
                let picked = i.qty - (i.originalpackedqty === null ? 0 : i.originalpackedqty);
                let loaded =  (i.totalpackedqty === null ? 0 : i.totalpackedqty) - (i.originalpackedqty === null ? 0 : i.originalpackedqty);
                ritms.push(<tr key={ii.toString()}>
                    <td>{i.item}</td>
                    <td>{picked}</td>
                    <td>{loaded}</td>
                    <td>{uom}</td>                    
                </tr>);
                itemcodes.push(<option key={i}>{i.item}</option>);
            }              
        }
        this.state.counter = this.state.counter +1;
        return (
            
            <div>                
                <Card key = {this.state.counter}>
                    <Card.Header>Packing Items                        
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text>
                            <div className="font-weight-bold">Order:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.sorder}</div>                            
                            <div className="font-weight-bold">Picking License Plate: {this.state.lp}</div></Card.Text>
                                                     
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Picked</th>
                                        <th>Packed</th>
                                        <th>UOM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ritms}
                                </tbody>
                            </Table>
                            <Form.Row>
                                <Form.Group as={Col} controlId="pkitmcode" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Item</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.pkitmcode} >
                                            {itemcodes}                            
                                    </Form.Control>            
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="pkqty" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Packing Quantity</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control  placeholder="Enter packing quantity" defaultValue={this.state.pkqty} />                                                                        
                                    <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.conform()}}>
                                        Confirm
                                    </Button>
                                </InputGroup>
                                </Form.Group>
                            </Form.Row> 
                            <div style={{float: "left"}} >                                       
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="submit">Done</Button>                              
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.scan()}}>Scan Item</Button>                              
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>                            
                        </Form>    
                        <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>                    
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        decodedText = 'KK02';
        console.log('Scanned Result', decodedText, decodedResult);  
        let e = {};
        let found = false;
        if (this.state.items && this.state.items.length > 0) {            
            for (let ii=0; ii<this.state.items.length; ii++) {
                let i = this.state.items[ii];
                if (i.item === decodedText) {
                    e = i;
                    found = true; 
                    
                    if ((i.qty - i.totalpackedqty) < 1) {
                        alert("You can't packed more items than allowed");
                    } else {
                        i.totalpackedqty = i.totalpackedqty + 1;
                        this.setState({pkitmcode: undefined, pkqty: undefined});
                    }
                    break;
                }
            }
            if (!found) {
                alert('No item in the order matched with the scanned item!');
                return;
            }
        }
        this.setState({showScanner: false}); 
    }
}
export default PackItems;