import React from 'react';
import "./../App.css";
import {Box, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
//import Box from '@mui/material/Box';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
import BallotOutlined from '@material-ui/icons/BallotOutlined';
import CardGiftcardOutlined from '@material-ui/icons/CardGiftcardOutlined';
import ArrowBackOutlined from '@material-ui/icons/ArrowBackOutlined';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import PrintOutlined from '@material-ui/icons/PrintOutlined';
import { Link } from 'react-router-dom'


class TransferMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
        };

    }

    handleChange(e) {
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { location: { pathname } } = this.props
        return (
            
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>
                    <ListItem  button component={Link} to="/transferlist" selected={'/transferlist' === pathname}>
                        
                        <ListItemIcon>
                            <BallotOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Add New Transfer" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/transferlistreadytoload" selected={'/transferlistreadytoload' === pathname}>
                        
                        <ListItemIcon>
                            <LocalShippingOutlined />
                        </ListItemIcon>
                        <ListItemText primary="My Packed Orders" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/transferlistpending" selected={'/transferlistpending' === pathname}>
                        
                        <ListItemIcon>
                            <CardGiftcardOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Orders to Fulfill" />
                        
                    </ListItem>
                    </List>
                </nav>
            </Box>      
        );
    }
}
export default TransferMenu;