import React from 'react';
import "./../App.css";
import { Card, ListGroup, Form, Button} from 'react-bootstrap';


class PalletItems extends React.Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {    
            wmsitm: [],
            pallet: undefined,

        };
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {         
        this.setState({pallet: this.props.location.state.lp, wmsitm: this.props.location.state.wmsitm});
        console.log(this.props)
    }
    
    
    goBack =() => {
        //console.log(this.props);
        this.props.history.goBack();
    }

    rowClicked = (e) => {
        //e.preventDefault();
        //alert(e.item)
        var { from } = { from: { pathname: "/assingtobin" } };
        this.state.wmsitm = []
        this.state.pagefrom = 'palletitems'
        this.state.wmsitm.push(e)
        this.state.lp = this.props.location.state.lp;
        this.state.po = this.props.location.state.po;
        this.props.history.push(from, this.state); 
    }

    render() {
        let ritms = (<ListGroup as="ol" numbered></ListGroup>);
        if (this.state.wmsitm && this.state.wmsitm.length > 0) {
            let itms = [];
            itms.push(<ListGroup.Item
                as="li" variant="dark"
                className="d-flex justify-content-between align-items-start"
            >
                <div className="ms-2 me-auto">
                    <div class="font-weight-bold">Product</div>                
                </div>
                {/*
                <div style={{ display: "inline-block" }}>
                    <div style={{minWidth : "80px",  marginRight: "auto" }} >
                            Qty                    
                    </div>                        
                </div>
                <div style={{ display: "inline-block" }}>
                    <div style={{minWidth : "80px",  marginRight: "auto" }} >
                            Batch                    
                    </div>                        
                </div>
            */}
            </ListGroup.Item>);
          
            for (let ii=0; ii<this.state.wmsitm.length; ii++) {
                let i = this.state.wmsitm[ii];
                let qty = (i.qty).toString();
                if (i.qty < 100) {
                    if (i.qty < 10) {
                        qty = '  ' + qty;
                    } else {
                        qty = ' ' + qty;
                    }
                }
                
                itms.push(<ListGroup.Item
                    as="li"
                    className="justify-content-between align-items-start"
                    action onClick={e => this.rowClicked(i)}
                >
                    <div className="ms-2 me-auto">
                        <div class="font-weight-bold" style={{width : "80%" }}>{i.item}</div>
                        {i.description}
                    </div>
                    <div > Quantity: 
                        {qty}                        
                    </div>
                    {i.batch &&
                        <div >Batch: 
                            {i.batch}                        
                        </div>
                    }
                    {/*
                    <div className="ms-2 me-auto">
                    <div class="font-weight-bold" style={{width : "80%" }}>{i.item}</div>
                    {i.description}
                    </div>
                    <div >
                        <div style={{width : "10%"}} >
                                {qty}
                        
                        </div>                        
                    </div>
                    <div >
                        <div style={{width : "10%"}} >
                                {i.batch}
                        
                        </div>                        
                    </div>
                    */}
                </ListGroup.Item>);
              }
              ritms = (<ListGroup as="ol" numbered>
                  {itms}
              </ListGroup>);
        }
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Putaway - Select One                       
                    </Card.Header>
                    <Card.Body>
                        <Form >
                            <Card.Text><div class="font-weight-bold">License Plate: {this.state.pallet}</div></Card.Text>                            
                            {ritms}                            

                            <div style={{float: "left"}} >                                       
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default PalletItems;