import React from 'react';
import "./../App.css";
import { Card, CardDeck, Modal, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class CountCycleItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            item: null, 
            itemobj: null,   
            blind: false,                
            showScanner: false,
            modal: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
        this.continueCount = this.continueCount.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {  
        if (this.props.location.pathname === "/countcyitmblnd") {
            this.state.blind = true;
        }
        console.log(this.props.location.pathname);
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user')); 
        wmsService.getItemCount(user.config.inventoryLocation, this.state.item).then(pord => {
            console.log(pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                if (!pord || pord.length === 0) {
                    //alert('Item is not found in the WMS')
                    this.setState({modal: true});
                } else {
                    this.state.itemobj = {};
                    this.state.itemobj.items =  pord;
                    this.state.itemobj.item = this.state.item;
                    this.state.itemobj.blind = this.state.blind;
                    console.log('1...', user.config);
                    if (user.config.wmsconfig && (user.config.wmsconfig.batchnotsupport === false || user.config.wmsconfig.expdatenotsupport === false)) {
                        var { from } = this.props.location.state || { from: { pathname: "/countcyitmbu" } };  
                        this.props.history.push(from, this.state.itemobj);
                    } else {                         
                        var { from } = { from: { pathname: "/countcyitmbin" } };  
                        this.props.history.push(from, this.state.itemobj);

                    }               
                }
            }
        });        
    }
    
    goBack =() => {
        var { from } = { from: { pathname: "/count" } };  
        this.props.history.push(from, this.state);
    }

    continueCount =() => {                
        this.setState({modal: false});

        let user = JSON.parse(localStorage.getItem('user')); 
        this.state.itemobj = {};
        this.state.itemobj.items =  [];
        this.state.itemobj.item = this.state.item;
        this.state.itemobj.blind = this.state.blind;
        if (user.config.wmsconfig && (user.config.wmsconfig.batchnotsupport === false || user.config.wmsconfig.expdatenotsupport === false)) {
            var { from } = this.props.location.state || { from: { pathname: "/countcyitmbu" } };  
            this.props.history.push(from, this.state.itemobj);
        } else {                         
            var { from } = { from: { pathname: "/countcyitmbin" } };  
            this.props.history.push(from, this.state.itemobj);

        }  
    }
    
    handleClose = () => {
        this.setState({modal: false});
    }

    render() {
        return (
            
            <div>                
                <Card>
                    <Card.Header>Count By Item                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="item" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                            <Form.Control required={true} placeholder="Scan or enter bin number" defaultValue={this.state.item} />                                                                
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>                
                <Modal show={this.state.modal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Item Not Found in WMS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>This is a new Item. Do you want to continue to count?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.handleClose()}}>No</Button>
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.continueCount()}}>Yes</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, item: decodedText}); 
    } 
}
export default CountCycleItem;