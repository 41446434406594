import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col } from 'react-bootstrap';
import Scanner from "./Scanner";

class LabelPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            label: null,        
            printqty: 1,
            labeltype: undefined,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state.label, this.state.printqty, this.state.labeltype);
    }
    
    goBack =() => {
        var { from } = { from: { pathname: "/inbound" } };  
        this.props.history.push(from, this.state);
    }


    render() {

        let labels = [];
        labels.push(<option key={0}>Default</option>);        
        labels.push(<option key={2}>Small</option>);
        labels.push(<option key={2}>Medium</option>);
        labels.push(<option key={1}>Large</option>);
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Label Print                       
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="label" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Label</Form.Label>
                                <Form.Control required={true} placeholder="Scan or enter label number" defaultValue={this.state.label} />                                
                           </Form.Group>
                        </Form.Row> 
                        <Form.Row>
                          <Form.Group as={Col} controlId="printqty" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Print Copy</Form.Label>
                                <Form.Control required={true} placeholder="Enter number of label copy to print" defaultValue={this.state.printqty} />                                
                           </Form.Group>
                        </Form.Row> 
                        <Form.Row>
                                <Form.Group as={Col} controlId="labeltype" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Type</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.labeltype} >
                                            {labels}                            
                                    </Form.Control>            
                                </Form.Group>
                         </Form.Row>
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Print</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, label: decodedText}); 
    } 
}
export default LabelPrint;