import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AppBar, Toolbar, IconButton, CssBaseline, MenuItem} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Menu } from '@material-ui/icons'
import { compose } from 'recompose'
import HomeIcon from '@material-ui/icons/Home';


const drawerWidth = 230

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'scroll',
    position: 'relative',
    display: 'flex',
    width: '100%',
    //backgroundColor: '#354052',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#425c84',
    height: '50px',  
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        overflow: "scroll",
        position: 'relative',
        height: "100%",  
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    marginTop: '-10px',
    overflowY: 'scroll',
    overflowX: 'scroll',
    height: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
})

class Layout extends Component {
  state = {
    mobileOpen: false,
    settingOpen: false,
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  handleClick = () => {
    this.setState({settingOpen: !this.state.settingOpen});
  };

  render() {
    //console.log('Layout');
    let tmp = JSON.parse(localStorage.getItem('user')); 
    //console.log(tmp);
    let companyname = '';
    if (tmp) {
      companyname = tmp.config.compnayname;
    }
    const { classes, location: { pathname }, children } = this.props

    return <Fragment>
      <CssBaseline/>

      <div className={classes.root}>
        <AppBar elevation={0} position="absolute" className={classes.appBar}>
          <Toolbar>
            {/*
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}              
              <Menu />
            </IconButton> */} 
            {/* <Typography variant="h6" color="inherit" noWrap> */}
            <div className="h6">
                <MenuItem component={Link} to="/" selected={'/' === pathname}>                                    
                    <HomeIcon fontSize="small" style={{fill: "white"}}/>                  
                </MenuItem>                  
            </div>

               <div className="h6">
                <div style={{float: 'left', flex: '1'}}>{companyname}</div>                    
              </div>          
            {/* </Typography> */}
            <section style={{marginLeft: 'auto',marginRight: '10px'}}>
            {
              <div className="h6">
                <MenuItem component={Link} to="/login" selected={'/login' === pathname}>                  
                  Logout
                </MenuItem>                  
            </div>     
            }
            </section>
          </Toolbar>
        </AppBar>
        
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}     
        </main>
      </div>              
    </Fragment>
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Layout)