import React from 'react';
import "./../App.css";
import { Card, Button, ListGroup } from 'react-bootstrap';

class PackingSelectLicensePlate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            lineItem: null,
            sorder: undefined,     
            lp: undefined,  
            licenseplates: undefined, 
            wmsempactivityreport: undefined,
        };        
        this.packItem = this.packItem.bind(this);
        this.goBack = this.goBack.bind(this);                
    }

    componentDidMount() {      
        console.log(this.props.location.state)   
        if (this.props.location.state && this.props.location.state.sorder && this.props.location.state.lineItem) {
            this.state.sorder = this.props.location.state.sorder;
            this.state.lineItem = this.props.location.state.lineItem;
            this.state.wmsempactivityreport = this.props.location.state.wmsempactivityreport;
            this.setState({licenseplates: this.props.location.state.licenseplates});
        }
        
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    packItem(w){        
        console.log(w);
        this.state.lp = w;
        let itm = [];
        for (let i of this.state.lineItem) {
            if (i.licenseplate === w) {
                itm.push(i);
            }
        }
        this.state.lineItem = itm;
        var { from } = { from: { pathname: "/packitems" } };  
        this.props.history.push(from, this.state);
    }

    render() {        
        
        if (!this.state.licenseplates) {
            return (<div></div>);
        }

        let list = [];
        if (this.state.licenseplates.length > 0) {
            for (let w of this.state.licenseplates) {
                list.push(<ListGroup.Item action onClick={() => {this.packItem(w)}}>{w}</ListGroup.Item>);
            }
        }
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Select License Plate
                    </Card.Header>
                    <Card.Body>
                      
                        <ListGroup >
                            {list}
                        </ListGroup>
                        <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                              
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default PackingSelectLicensePlate;