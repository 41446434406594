import React from 'react';
import "./../App.css";
import { Card, Form, Button, Col} from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class PutAwayToNewBin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            pallet: null,
            wmsitm: [],
            bin: undefined,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.process = this.process.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.scan = this.scan.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);          
        this.closeScanner = this.closeScanner.bind(this);
    }

    componentDidMount() {    
        this.setState({pallet: this.props.location.state.pallet, wmsitm: this.props.location.state.wmsitm});     
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state.bin);
        this.process()
        
    }

    process() {
        // Check whether this bin is available in this warehouse.
        // If does not then alert.
        // If it exists then pass the new bin number while going back to the caller.

        let user = JSON.parse(localStorage.getItem('user'));             
        let invloc = user.config.inventoryLocation;
        wmsService.getBin(invloc, this.state.bin).then(bin => {
            console.log(bin);
            if (bin && bin === 401) {
                this.props.history.push("/login");
            } else if (bin && bin.length > 0) {
                //alert('Bin is found...')
                user.defaultbin =  this.state.bin;
                localStorage.setItem('user', JSON.stringify(user));
                //console.log(user);
                this.props.history.goBack();      
            } else {
                alert("The bin you entered does not exist. Go to Settings and Bin and enter this bin for warehouse: " + invloc);
            }
        });
    }

    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }

    render() {        
        console.log(this.props);
        return (
            
            <div>                
                <Card>
                    <Card.Header>Putaway Goods                        
                    </Card.Header>
                    <Card.Body>
                    <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;License Plate: {this.state.pallet}</Form.Label>
                    </Form.Row>                   
                        <Form.Row>
                            <Form.Group as={Col} controlId="bin" 
                                onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Assign a new Bin</Form.Label>
                            <Form.Control required={true} placeholder="Scan or enter bin number" value={this.state.bin} autoFocus />
                            </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                        </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, bin: decodedText}); 
        this.process();
    } 
}
export default PutAwayToNewBin;