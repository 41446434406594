import React, { forwardRef } from "react";
import "./../App.css";
import { Button } from 'react-bootstrap';
import MaterialTable from "material-table";
import { wmsService } from "../services/wmsService"

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class SettingLicensePlateEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouse: undefined,
            data: [],
            count: 1,
            zones: {},
            columns: [
            {
              field: 'id',
              title: 'id',  
              hidden: true,        
            }, {
                field: 'lp',
                title: 'License Plate',  
              },
              {
              field: 'zone',
              title: 'Zone',
              //lookup: this.getZone(),  
            }, {
                field: 'warehouse',
                title: 'Warehouse',    
                editable: 'never',          
            },     
          ],

          actions: [                        
            {
                icon: AddBox,
                tooltip: 'Add Zone',
                isFreeAction: true,
                onClick: (event) => this.addNew(),
            },            
          ],
          editable: {            
              onRowUpdate:(updatedRow,oldRow)=>new Promise((resolve,reject)=>{
                const index=oldRow.tableData.id;
                const updatedRows=[...this.state.data]
                updatedRows[index]=updatedRow
                this.saveRow(updatedRow, updatedRows);                   
                resolve()                
              }), 
              onRowDelete: selectedRow => new Promise((resolve, reject) => {
                const index = selectedRow.tableData.id;
                const deleteRow = this.state.data[index]
                const updatedRows = [...this.state.data]
                updatedRows.splice(index, 1)
                setTimeout(() => {
                  this.setState({data: updatedRows})
                  this.deleteRow(deleteRow);
                  resolve()
                }, 10)
              }),                  
          },  
        };
        this.goBack = this.goBack.bind(this);    
        this.saveRow = this.saveRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.addNew = this.addNew.bind(this);  
        this.getRandomInt = this.getRandomInt.bind(this);     
    }

    componentDidMount() { 
        console.log(this.props.location.state);
        if (this.props.location.state) {
            this.state.warehouse = this.props.location.state;
            this.setState({zones: this.props.location.state.zones}); 
            let tmp = {};
            for (let z of this.props.location.state.zones) {   
                tmp[z.id] = z.zone;                                                   
            }
            for (let col of this.state.columns) {
                if (col.field === 'zone') {
                    col['lookup'] = tmp;
                    break;
                }
            }
            wmsService.getLps(this.state.warehouse.locationName).then(witm => {
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    console.log(witm);
                    if (witm.length > 0) {
                        this.setState({data: witm});     
                    }                            
                }             
            });
        }        
    }
  
    saveRow(row, newRows) {
        console.log(row)
        let found = false;
        for (let r of this.state.data) {
            if (r.lp === row.lp && r.id != row.id) {
                found = true; 
            }
        }    
        if (found) {
            alert ('You can not have same license plate number')
            return false;
        } else {
            if (row.id) {
                wmsService.updateLp(row).then(witm => {
                    //console.log(witm);
                    if (witm && witm === 401) {             
                        this.props.history.push("/login");  
                    } else {
                        this.setState({data: newRows});
                    }             
                });
            } else {
                wmsService.createLp(row).then(witm => {
                    console.log(witm);
                    if (witm && witm === 401) {             
                        this.props.history.push("/login");  
                    } else {                        
                        wmsService.getLps(this.state.warehouse.locationName).then(witm => {
                            if (witm && witm === 401) {             
                                this.props.history.push("/login");  
                            } else {
                                console.log(witm);
                                if (witm.length > 0) {
                                    this.setState({data: witm});     
                                }                            
                            }             
                        });
                    }             
                });
            }                        
        }
    }

    deleteRow(row) {
        console.log(row)
        wmsService.removeLp(row.id).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                return true;
            }             
        });
    } 
    
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    addNew() {
        let row = {};
        row.warehouse = this.state.warehouse.locationName;
        row.lp = 'Change It - ' + this.getRandomInt(99, 99999999);
        row.zone = this.state.zones[0].id;
        let user = JSON.parse(localStorage.getItem('user'));         
        row.userwhoupdated = user.username;
        this.state.data.push(row); 
        this.saveRow(row, this.state.data);        
    }

    goBack =() => {
        this.props.history.goBack();
    }

    render() {        
        return (            
                
                    <div>
                    <MaterialTable key={this.state.count}
                        title="License Plate Edit"
                        icons={tableIcons}
                        columns={this.state.columns}
                        data={this.state.data}        
                        actions={this.state.actions}
                        options={{
                            search: false,
                            paging: false,
                            actionsColumnIndex: -1,
                        }}
                        editable={this.state.editable}                                        
                    />
                      
                    <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                                                  
                    </div>
           
            
        );
    }

}
export default SettingLicensePlateEdit;