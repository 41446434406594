import React, { forwardRef } from "react";
import "./../App.css";
import { Card, CardDeck, Modal, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { TramOutlined } from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class CountCycleItemBinScan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            bin: null, 
            binitemcylcnt: null,
            itemobj: null,   
            blind: false,                
            showScanner: false,
            selectedrows: undefined,
            data: [],
            columns: [
                {
                  field: 'id',
                  title: 'id',  
                  hidden: true,        
                }, {
                    field: 'bin',
                    title: 'Bin',  
                  },
                  {
                  field: 'batch',
                  title: 'Batch',              
                },
                {
                    field: 'qty',
                    title: 'Cyl Count',
                  },                                         
              ],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
        this.getItemCountRender = this.getItemCountRender.bind(this);
        this.approval = this.approval.bind(this);
        
    }

    componentDidMount() {  
        
        if (this.props.location.state) {
            //this.setState({itemobj: this.props.location.state});
            this.state.itemobj = this.props.location.state;
            this.setState({blind: this.state.itemobj.blind});
        }
        console.log(this.props.location.pathname, this.state.itemobj);
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getItemCycleCounts(user.config.inventoryLocation, this.state.itemobj.item).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                this.setState({data: witm});
            }             
        });
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user')); 
        wmsService.getItemForBinCycleCounts(user.config.inventoryLocation, this.state.itemobj.item, this.state.bin).then(pord => {
            console.log(pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                //this.setState({binitemcylcnt: pord});
                this.state.itemobj.binitemcylcnt = pord;
                this.state.itemobj.bin = this.state.bin;
                var { from } = { from: { pathname: "/countcyitmbinitm" } };  
                this.props.history.push(from, this.state.itemobj);                
            }
        });        
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    getItemCountRender =() => {
        console.log('getItemCountRender...1', this.state.itemobj);
        if (this.state.itemobj && this.state.itemobj.items) {
            console.log('getItemCountRender...2', this.state.itemobj.items);
            if (this.state.itemobj.items.length === 1) {
                console.log('getItemCountRender', this.state.itemobj.items[0].batch);
                if (this.state.itemobj.items[0].batch === '0') {
                    return <div>Batch {this.state.itemobj.items[0].sum}</div>
                } else {                    
                    return <div>Batch {this.state.itemobj.items[0].batch}: {this.state.itemobj.items[0].sum}</div>
                }
            } else if (this.state.itemobj.items.length > 1) {
                let d = [];
                for (let o of this.state.itemobj.items) {
                    if (o.batch === '0') {
                        d.push(<div>Batch NA: {o.sum}</div>)
                    } else {
                        d.push(<div>Batch {o.batch}: {o.sum}</div>);
                    }
                }
                return d;
            } else {
                return (<div></div>);
            }
        }
    }

    approval() {
        console.log('approval is called...')
        if (!this.state.selectedrows || this.state.selectedrows.length === 0) {
            alert ('Please select items to submit for approval');
        } else {
            console.log(this.state.selectedrows);
            let obj = [];
            for (let r of this.state.selectedrows) {
                let tmp = {};
                tmp.id = r.id;
                tmp.qty = r.qty;
                tmp.bin = r.bin;
                tmp.item = r.item;
                if (r.batch) {
                    tmp.batch = r.batch;
                }
                tmp.invlocation = r.invlocation;
                tmp.approve = 1;
                obj.push(tmp);
            }
            wmsService.approveCycleCount(obj).then(witm => {
                console.log(witm);
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    alert ("Successfully submitted for approval");
                    this.setState({selectedrows: undefined});
                    let user = JSON.parse(localStorage.getItem('user'));
                    wmsService.getCycleCounts(user.config.inventoryLocation).then(witm => {
                        console.log(witm);
                        if (witm && witm === 401) {             
                            this.props.history.push("/login");  
                        } else {
                            this.setState({data: witm});
                        }             
                    });
                }             
            });
        }
    }

    render() {
        if (!this.state.itemobj) {
            return (<div></div>)
        }
        return (
            
            <div>                
                <Card>
                    <Card.Header>Count By Item - Bin                       
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                            <div className="font-weight-bold">Item: {this.state.itemobj.item}</div>
                            {!this.state.blind &&
                            <span><div className="font-weight-bold">Qunatity On-hand:</div>{this.getItemCountRender()}</span>
                            }   
                      </Card.Text> 
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="bin" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Bin #</Form.Label>
                            <Form.Control required={true} placeholder="Scan or enter bin number" defaultValue={this.state.bin} />                                                                
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <div>
                      <div>&nbsp;&nbsp;</div>
                      <div>&nbsp;&nbsp;</div>
                        <MaterialTable key={this.state.count}
                            title="Last Saved Count"
                            icons={tableIcons}
                            columns={this.state.columns}
                            data={this.state.data}        
                            
                            options={{
                                search: false,
                                paging: false,
                                actionsColumnIndex: -1,
                                selection: true,
                            }}
                            onSelectionChange={(rows) => this.state.selectedrows = rows}
                        />
                        <Button variant="outline-primary mr-3 mt-2" size="sm" type="button" onClick={() => {this.approval()}}>Submit for Approval</Button>
                        </div> 
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, bin: decodedText}); 
    } 
}
export default CountCycleItemBinScan;