import { buildTimeValue } from '@testing-library/user-event/dist/utils';
import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const wmsService = {    
    getActiveShippingOrder,
    updateToPartialShipping,
    updateToFullyShipped,
    getSingleWmsActivity,
    getWmsActivityGroupByItem,
    getWmsActivityByOrderAndType,
    getActiveWmsByLicense,
    addOrUpdateWmsActivity,
    addNewWmsActivity,
    deleteWmsActivity,
    deleteWmsActivities,
    updateWmsActivity,
    getPo,
    getSo,
    postGoodReceipt,
    adjustWms,
    addGoodsToBin,    
    getSoForPick,
    getBinsForPick,
    pick,
    getPickedItems,
    getPickedOrder,   
    addPackAndShip, 
    getShipReadyPackedItemsByOrder,
    getShipReadyPackedItemsByPallet,
    postGoodsIssue,
    getWarehouses,
    createWarehouse,
    updateWarehouse,
    deleteWarehouse,
    getZones,
    updateZone,
    createZone,
    removeZone,
    getStorageSections,
    createStorageSection,
    updateStorageSection,
    removeStorageSection,
    getBinTypes,
    createBinType,
    updateBinType,
    removeBinType,
    getBin,
    getBins,
    createBin,
    updateBin,
    removeBin,
    getLps,
    createLp,
    updateLp,
    removeLp,
    getEmployees,
    createEmployee,
    updateEmployee,
    removeEmployee,
    getEmployee,
    getBinItems,
    getCycleCounts,
    createCycleCount,
    updateCycleCount,
    removeCycleCount,
    approveCycleCount,
    getItemCount,
    getItemCycleCounts,
    getItemForBinCycleCounts,
    getPendingApprovals,
    getApprovedCounting,
    approveCount,
    getOrAddItem,
    getItemByItemCode,
    createWmsItem,
    createOrAddWmsItem,
    updateSoRelease,
    deleteSoRelease,
    getPicking,
    getShipReadyPackItems,

    getAvailableBatchNumbersForItem,
    getWmsConfig,
    createWmsConfig,
    updateWmsConfig,
    removeWmsConfig,
    getInboundActivityPage,

    getWmsInventory,
    getWmsInventorySearch,
    updateWmsInventoryQty,
    getTotalQty,
    releaseSo,
    getPickingBatch,

    getWmsInventorySearchIcludePrice,
    getWmsInventoryByItemCode,

    addOrUpdateTransferLineItem,
    getTransfer,
    searchTransfer,
    getTransfers,
    createTransfer,
    deleteTransfer,
    deleteTransferLineItem,
    updateTransfer,
    updateTransferHeader,

    updateTransferStatusToInProcess,
    postWmsTransfer,
    getTransfersToLoad,
    getTransfersToFulfill,
    getShipReadyPackedItemsByPalletAndOrder,
    searchItemInPoAndSo,
};


function searchItemInPoAndSo(itemcode) {
    console.log('called searchItemInPoAndSo')
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsitemsearchinposo/'  + itemcode , requestOptions).then(handleResponse);
}

function updateTransferStatusToInProcess(transfer) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(transfer) 
    };
    return fetch(config.app.url + '/api/wmstransfertoinprocess' , requestOptions).then(handleResponse);
}

function deleteTransfer(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransfer/'  + id, requestOptions).then(handleResponse);
}

function deleteTransferLineItem(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransferitem/'  + id, requestOptions).then(handleResponse);
}

function createTransfer(transfer) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(transfer)   
    };
    return fetch(config.app.url + '/api/wmstransfer' , requestOptions).then(handleResponse);
}

function getTransfers(offset, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransfers/'  + offset + '/limit/' + limit, requestOptions).then(handleResponse);

}

function getTransfersToLoad(offset, limit, warehouse) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransferstoload/'  + warehouse + '/offset/' + offset + '/limit/' + limit, requestOptions).then(handleResponse);

}

function getTransfersToFulfill(offset, limit, warehouse) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransferstofulfill/'  + warehouse + '/offset/' + offset + '/limit/' + limit, requestOptions).then(handleResponse);

}

function searchTransfer(offset, limit, search) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransferss/' + search + '/offset/' + offset + '/limit/' + limit, requestOptions).then(handleResponse);
}

function getTransfer(transferid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmstransfer/'  + transferid, requestOptions).then(handleResponse);
}

function addOrUpdateTransferLineItem(titem) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(titem) 
    };
    return fetch(config.app.url + '/api/wmstransferitem' , requestOptions).then(handleResponse);

}

function updateTransferHeader(transfer) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(transfer) 
    };
    return fetch(config.app.url + '/api/wmstransferheader/', requestOptions).then(handleResponse);

}

function updateTransfer(transfer) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(transfer) 
    };
    return fetch(config.app.url + '/api/wmstransfer/', requestOptions).then(handleResponse);

}

function getPickingBatch(bin, item, wh) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsbatch/' + bin + '/item/' + item + '/wh/' + wh , requestOptions).then(handleResponse);
}

function releaseSo(warehouse, soid, owner) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/releaseso/' + warehouse +'/soid/' + soid + '/owner/' + owner , requestOptions).then(handleResponse);
}

function getTotalQty(warehouse, item) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsitemqty/' + warehouse + '/item/' + item, requestOptions).then(handleResponse);
}

function updateWmsInventoryQty(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/wmsinventory' , requestOptions).then(handleResponse);
}

function getWmsInventorySearch(warehouse, search) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsinventory/' + warehouse + '/search/' + search, requestOptions).then(handleResponse);
}

function getWmsInventorySearchIcludePrice(search, warehouse) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsinventoryprice/' + warehouse + '/search/' + search, requestOptions).then(handleResponse);
}

function getWmsInventoryByItemCode(search, warehouse) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsinventorybarcode/' + warehouse + '/search/' + search, requestOptions).then(handleResponse);
}

function getWmsInventory(warehouse, offset, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsinventory/' + warehouse + '/offset/' + offset + '/limit/' + limit, requestOptions).then(handleResponse);
}

function getInboundActivityPage(warehouse, offset, limit) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsactivitypage/' + warehouse + '/offset/' + offset + '/limit/' + limit, requestOptions).then(handleResponse);
}

function removeWmsConfig(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsconfig/'  + id, requestOptions).then(handleResponse);
}

function updateWmsConfig(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/wmsconfig' , requestOptions).then(handleResponse);
}

function createWmsConfig(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/wmsconfig' , requestOptions).then(handleResponse);
}

function getWmsConfig(warehouse) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsconfig/' + warehouse, requestOptions).then(handleResponse);
}

function getAvailableBatchNumbersForItem(item) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };

    return fetch(config.app.url + '/api/wmsbatch/'  + item, requestOptions).then(handleResponse);

}

function getShipReadyPackItems(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/shipreadypack/'  + invloc, requestOptions).then(handleResponse);
}

function getPicking(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/picking/'  + invloc, requestOptions).then(handleResponse);
}

function deleteSoRelease(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/sorelease/'  + id, requestOptions).then(handleResponse);
}

function updateSoRelease(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/sorelease' , requestOptions).then(handleResponse);
}

function createWmsItem(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/wmsitem' , requestOptions).then(handleResponse);
}

function createOrAddWmsItem(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/wmsactivitycreateorupdate' , requestOptions).then(handleResponse);
}

function getOrAddItem(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/item' , requestOptions).then(handleResponse);
}

function getItemByItemCode(itemCode, inventoryLocation) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',  
    };
    return fetch(config.app.url + '/api/itembyitemcode/' + itemCode , requestOptions).then(handleResponse);
}

function approveCount(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/approvecount' , requestOptions).then(handleResponse);
}

function getApprovedCounting(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/approvedcnt/'  + invloc, requestOptions).then(handleResponse);
}

function getPendingApprovals(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/pendingapprv/'  + invloc, requestOptions).then(handleResponse);
}

function getItemForBinCycleCounts(invloc, item, bin, batch) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    if (batch) {
        return fetch(config.app.url + '/api/itemcyclecount/'  + invloc + '/item/' + item + '/bin/' + bin + '/batch/' + batch, requestOptions).then(handleResponse);
    } else {
        return fetch(config.app.url + '/api/itemcyclecount/'  + invloc + '/item/' + item + '/bin/' + bin, requestOptions).then(handleResponse);
    }
}

function getItemCycleCounts(invloc, item) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/itemcyclecount/'  + invloc + '/item/' + item, requestOptions).then(handleResponse);
}

function getItemCount(invloc, item) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/itemcount/'  + invloc + '/item/' + item, requestOptions).then(handleResponse);
}

function approveCycleCount(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/cyclecountapprove' , requestOptions).then(handleResponse);
}

function getCycleCounts(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/cyclecount/'  + invloc, requestOptions).then(handleResponse);
}

function createCycleCount(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/cyclecount' , requestOptions).then(handleResponse);
}

function updateCycleCount(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/cyclecount' , requestOptions).then(handleResponse);
}

function removeCycleCount(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/cyclecount/'  + id, requestOptions).then(handleResponse);
}

//
function getBinItems(invloc, bin) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/binitems/'  + invloc + '/bin/' + bin, requestOptions).then(handleResponse);
}

function getEmployee(emp) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsemp/'  + emp, requestOptions).then(handleResponse);
}

function getEmployees(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsemployee/'  + invloc, requestOptions).then(handleResponse);
}

function createEmployee(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/wmsemployee' , requestOptions).then(handleResponse);
}

function updateEmployee(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/wmsemployee' , requestOptions).then(handleResponse);
}

function removeEmployee(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsemployee/'  + id, requestOptions).then(handleResponse);
}

//
function getLps(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/license/'  + invloc, requestOptions).then(handleResponse);
}

function createLp(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/license' , requestOptions).then(handleResponse);
}

function updateLp(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/license' , requestOptions).then(handleResponse);
}

function removeLp(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/license/'  + id, requestOptions).then(handleResponse);
}
///
function getBin(invloc, bin) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/bin/'  + invloc + '/bin/' + bin, requestOptions).then(handleResponse);
}

function getBins(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/bin/'  + invloc, requestOptions).then(handleResponse);
}

function createBin(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/bin' , requestOptions).then(handleResponse);
}

function updateBin(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/bin' , requestOptions).then(handleResponse);
}

function removeBin(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/bin/'  + id, requestOptions).then(handleResponse);
}
//
function getBinTypes(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/bintype/'  + invloc, requestOptions).then(handleResponse);
}

function createBinType(bt) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(bt)   
    };
    return fetch(config.app.url + '/api/bintype' , requestOptions).then(handleResponse);
}

function updateBinType(bt) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(bt) 
    };
    return fetch(config.app.url + '/api/bintype' , requestOptions).then(handleResponse);
}

function removeBinType(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/bintype/'  + id, requestOptions).then(handleResponse);
}

///
function getStorageSections(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/section/'  + invloc, requestOptions).then(handleResponse);
}

function createStorageSection(sec) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(sec)   
    };
    return fetch(config.app.url + '/api/section' , requestOptions).then(handleResponse);
}

function updateStorageSection(sec) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(sec) 
    };
    return fetch(config.app.url + '/api/section' , requestOptions).then(handleResponse);
}

function removeStorageSection(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/section/'  + id, requestOptions).then(handleResponse);
}

function getZones(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/zone/'  + invloc, requestOptions).then(handleResponse);
}
function updateZone(zone) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',    
        body: JSON.stringify(zone) 
    };
    return fetch(config.app.url + '/api/zone' , requestOptions).then(handleResponse);
}

function createZone(zone) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(zone)   
    };
    return fetch(config.app.url + '/api/zone' , requestOptions).then(handleResponse);
}

function removeZone(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/zone/'  + id, requestOptions).then(handleResponse);
}

function getWarehouses() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',             
    };
    return fetch(config.app.url + '/api/warehouselocs', requestOptions).then(handleResponse);
}

function createWarehouse(wh) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(wh)           
    };
    return fetch(config.app.url + '/api/warehouseloc' , requestOptions).then(handleResponse);
}

function updateWarehouse(wh) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(wh)           
    };
    return fetch(config.app.url + '/api/warehouseloc' , requestOptions).then(handleResponse);
}

function deleteWarehouse(wh) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(wh)           
    };
    return fetch(config.app.url + '/api/warehouseloc' , requestOptions).then(handleResponse);
}

function postWmsTransfer(transfer) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(transfer)           
    };
    return fetch(config.app.url + '/api/postwmstransfer' , requestOptions).then(handleResponse);
}

function postGoodsIssue(packing) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(packing)           
    };
    return fetch(config.app.url + '/api/ship' , requestOptions).then(handleResponse);
}

function getShipReadyPackedItemsByOrder(sorder, warehouse ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',             
    };
    return fetch(config.app.url + '/api/shipreadypacks/'  + sorder + '/warehouse/' + warehouse, requestOptions).then(handleResponse);
}

function getShipReadyPackedItemsByPallet(pallet, warehouse ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',             
    };
    return fetch(config.app.url + '/api/shipreadypacksbypallet/'  + pallet + '/warehouse/' + warehouse, requestOptions).then(handleResponse);
}

function getShipReadyPackedItemsByPalletAndOrder(pallet, orders ) {
    let obj = {};
    obj.pallet = pallet;
    obj.order = orders;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(obj)           
    };
    return fetch(config.app.url + '/api/shipreadypacksbypalletandorder' , requestOptions).then(handleResponse);
}

function addPackAndShip(pack) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(pack)           
    };
    return fetch(config.app.url + '/api/addpackandship' , requestOptions).then(handleResponse);
}

function pick(picking) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',   
        body: JSON.stringify(picking)           
    };
    return fetch(config.app.url + '/api/pick' , requestOptions).then(handleResponse);
}

function getPickedItems(warehouse, items) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include', 
        body: JSON.stringify(items)     
    };
    return fetch(config.app.url + '/api/pickeditems/'  + warehouse, requestOptions).then(handleResponse);
}

function getPickedOrder(sorder, warehouse) {    
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',  
    };
    return fetch(config.app.url + '/api/pickedorder/'  + sorder + '/warehouse/' + warehouse, requestOptions).then(handleResponse);
}

function getBinsForPick(invloc, items) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(items)   
    };
    return fetch(config.app.url + '/api/binstopick/' + invloc, requestOptions).then(handleResponse);
}

// WMS user reserves this order so that only this user can pick it (no dupliate picking)
function getSoForPick(user, orderid, invloc, zone) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/soforpick/'  + user + '/orderid/' + orderid + '/warehouse/' + invloc  + '/zone/' + zone, requestOptions).then(handleResponse);
}

function getActiveShippingOrder(invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/activeshippingorder/'  + invloc, requestOptions).then(handleResponse);
}

function updateToPartialShipping(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',           
    };
    return fetch(config.app.url + '/api/updatetopartialshipping/' + id , requestOptions).then(handleResponse);
}

function updateToFullyShipped(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/updatetofullyshipped/' + id , requestOptions).then(handleResponse);
}

function addGoodsToBin(wms, srcorder, license, invloc) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(wms)   
    };
    return fetch(config.app.url + '/api/addwmsgoods/' + srcorder + '/license/' + license + '/warehouse/' + invloc, requestOptions).then(handleResponse);
}

function getActiveWmsByLicense(pallet, invloc) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/activewmsbylicense/' + pallet + '/warehouse/' + invloc, requestOptions).then(handleResponse);
}

function getWmsActivityGroupByItem(srcorder, type, srcorderid) {
    let t = "";
    if (srcorderid) {
        t = "/srcorderid/" + srcorderid;
    }
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsactivitygroupbyitem/' + srcorder + "/type/" + type + t, requestOptions).then(handleResponse);
}

function getSingleWmsActivity(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsactivitybyid/' + id , requestOptions).then(handleResponse);
}

function getWmsActivityByOrderAndType(srcorder, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/wmsactivitybyorderandtype/' + srcorder  + "/type/" + type, requestOptions).then(handleResponse);
}

function addOrUpdateWmsActivity(wmsactivity) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(wmsactivity)   
    };
    if (wmsactivity.id) {
        return fetch(config.app.url + '/api/wmsactivity/' + wmsactivity.id , requestOptions).then(handleResponse);        
    } else {
        return fetch(config.app.url + '/api/wmsactivitycreateorupdate/' , requestOptions).then(handleResponse);
    }
}

function addNewWmsActivity(wmsactivity) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(wmsactivity)   
    };
    return fetch(config.app.url + '/api/wmsactivity/' , requestOptions).then(handleResponse);
}

function updateWmsActivity(wmsactivity) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(wmsactivity)     
    };
    return fetch(config.app.url + '/api/wmsactivity/' + wmsactivity.id , requestOptions).then(handleResponse);
}

function deleteWmsActivities(item, itemid, order, orderid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/wmsactivitiesdelete/' + itemid + "/item/" + item + "/srcorder/" + order + "/srcorderid/" + orderid, requestOptions).then(handleResponse);
}

function deleteWmsActivity(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/wmsactivitybyid/' + id , requestOptions).then(handleResponse);
}

function getPo(order) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/purchaseorderbyorder/' + order , requestOptions).then(handleResponse);
}

function getSo(order) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/salesorderbyorder/' + order , requestOptions).then(handleResponse);
}

function postGoodReceipt(order, license) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(order) 
    }
    return fetch(config.app.url + '/api/purchaseordergoodsreceive/' + order.id + '/license/' + license, requestOptions).then(handleResponse);
}

function adjustWms(ponum, type, poid, lineItem) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(lineItem) 
    }
    return fetch(config.app.url + '/api/adjustwms/' + poid +'/ponum/' + ponum + '/type/' + type , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }

    if (!response.ok) {
        const error = response.statusText;
        console.log(error);
        return [];
    }

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.status === 'failed') {
            alert (data.message);
        }
        //console.log(data.data);
        return data.data;
    });
}

