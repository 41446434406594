import React from 'react';
import "./../App.css";
import { Card, Button, InputGroup, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class PackingDone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            packing: null,    
            targetlicenseplate: undefined,    
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
    }

    componentDidMount() {         
        if (this.props.location.state  && this.props.location.state.sorder) {
            this.state.packing = this.props.location.state;
            console.log('this.state.packing', this.state.packing);
        }
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;  
        this.state[name] =  target.value;           
    }

    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user')); 
        // wmsService.getPickedOrder(this.state.sorder, user.config.inventoryLocation).then(pord => {            
        //     if (pord && pord === 401) {
        //         this.props.history.push("/login");
        //     } else {
        //         console.log('pickedorder', pord);
        //         if (pord.length > 0) {
        //             this.state.lp = pord[0].licenseplate;
        //             this.state.lineItem = pord;
        //             var { from } = this.props.location.state || { from: { pathname: "/packitems" } };  
        //             this.props.history.push(from, this.state);
        //         } else {
        //             alert('Order or picked items for the order are not found');
        //             return;
        //         }
        //     }
        // });  
        let pitems = [];   
        for (let i of this.state.packing.items) {
            let p = {};
            p.pickid = i.id;
            p.qty = i.totalpackedqty - i.originalpackedqty;
            pitems.push(p);
        }
        let packandship = {
            sorder: this.state.packing.sorder,
            sorderid: this.state.packing.sorderid,
            pallet: this.state.targetlicenseplate,
            warehouse: user.config.inventoryLocation,
            userwhoupdated: user.username,
            packitems:  pitems, 
            wmsempactivityreport: this.state.packing.wmsempactivityreport,    
        }
        console.log(packandship);
        wmsService.addPackAndShip(packandship).then(pord => {
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                var { from } = { from: { pathname: "/packinglabelprint" } };  
                this.props.history.push(from, this.state);
            }
        });
    }
    
    goBack =() => {
        this.props.history.goBack();       
    }

    render() {
        return (
            
            <div>                
                <Card>
                    <Card.Header>Packing Order                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="targetlicenseplate" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Target License Plate</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control required={true} placeholder="Scan or enter target license plate" defaultValue={this.state.targetlicenseplate} />                                
                                <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.scan()}}>
                                    Scan
                                </Button>
                            </InputGroup>
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Confirm</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        this.setState({showScanner: false, targetlicenseplate: decodedText}); 
    } 
}
export default PackingDone;