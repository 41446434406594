import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import Scanner from "./Scanner";

class PickingItemSItem extends React.Component {
    constructor(props) {
        super(props);
        console.log('PickingItemSItem', props);
        this.state = {    
            item: {},   
            qty: undefined,
            itemcode: undefined,
            bin: undefined,
            batch: undefined, 
            batchopt: undefined,
            expirationdate: undefined,
            binInput: false,
            itemInput: true,
            qtyInput: true,
            qrcodescan: false,
            resetkey: 1,
            showScanner: false,
            timer: null,
            batchoptions: [{batch: 'Select one', expirationdate: undefined}],
        };
        this.goBack = this.goBack.bind(this);
        this.scanAny = this.scanAny.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.goNext = this.goNext.bind(this);
        this.handleAndVerifyItemCode = this.handleAndVerifyItemCode.bind(this);
    }

    componentDidMount() { 
        if (this.props.location.state) {
            this.setState({item: this.props.location.state});     
            this.state.transfer = this.props.location.state.transfer;       
        }        
    }

    handleAndVerifyItemCode = (event) => {   
        const target = event.target;
        const name = target.id;           
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({timer: null});
        }
        this.setState({timer:
            setTimeout(() => {
                this.setState({itemcode: target.value});
                console.log('timer', this.state.itemcode, target.value);
                this.handleNext();     
                event.preventDefault();
            }, 500)}
        );  
        
        if (event.key === 'Enter' || event.key === "Tab") {            
            event.preventDefault();        
        }        
    }

    
    handleNext() {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(this.state.binInput, this.state.itemInput, this.state.qtyInput);
        if (!this.state.itemInput) {
            console.log(this.state.itemcode, this.state.item);
            if (!this.state.itemcode || this.state.itemcode !== this.state.item.item) {
                //alert("Your input is not correct. Please enter or scan correct item")
                this.setState({itemcode: ''});
                this.setState({resetkey: this.state.resetkey+1})
            } else {                
                this.setState({binInput: true, itemInput: true, qtyInput: false});
            }
        } 
    }

    goNext(e) {        
        //this.props.history.push("/pickitmqtybatchitm", this.state);
        if (this.state.itemcode && this.state.itemcode === this.state.item.item.item) {
            this.state.item.itemcode = this.state.itemcode;
            this.props.history.push("/pickitemsq", this.state.item);        
        } else {
            alert ('You entered wrong item code. Entered: ' + this.state.itemcode + ' expected: ' + this.state.item.item.item);
        }
    }   
    
    goBack =() => {   
        //alert('goBack...');     
        this.props.history.goBack();
    }

    scanAny = () => {
        this.setState({showScanner: true});
    }

    onNewScanResult(decodedText, decodedResult) {
        if (decodedText !== this.state.item.item.item) {
            alert ('You scanned ' + decodedText + '. Please scan item ' + this.state.item.item);                 
        } else {
            this.setState({showScanner: false, itemcode: decodedText, binInput: true, itemInput: true, qtyInput: false});
        }   
    }

    closeScanner() {
        this.setState({showScanner: false})
    }

    render() {
        if (!this.state.item.item) {
            return <div></div>
        }
        console.log('this.state.item', this.state.item);
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            
            <div key={this.state.resetkey}>                
                <Card>
                    <Card.Header>Picking Items from Bin                        
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.goNext}>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px"}}>&nbsp;Bin: {this.state.item.bin}</Form.Label>
                            </Form.Row>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Item Code: {this.state.item.item.item}</Form.Label>
                            </Form.Row>
                                                       
                            <Form.Row>
                                <Form.Group as={Col} controlId="itemcode" 
                                    onKeyDown={this.handleAndVerifyItemCode}>
                                <Form.Label style={{fontSize: "14px"}}>Enter or Scan Item</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control  required={true} autoComplete="off" placeholder="Enter or scan Item" defaultValue={this.state.itemcode} autoFocus = {true} />                                    
                                </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            
                            
                            <div style={{float: "left"}} >     
                            {/* {(!this.state.qtyInput || this.state.qty) &&                                  
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>  
                            } */}
                            
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.scanAny()}}>Scan</Button>                                                       
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>
                            
                            <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default PickingItemSItem;