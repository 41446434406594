import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col} from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import { ThumbDownSharp } from '@material-ui/icons';


class PickingItemSQuantity extends React.Component {
    constructor(props) {
        super(props);
        console.log('PickingIteSQuantity', props);
        this.state = {    
            item: {},   
            qty: undefined,
            itemcode: undefined,
            bin: undefined,
            batch: undefined, 
            batchopt: undefined,
            expirationdate: undefined,
            binInput: false,
            itemInput: true,
            qtyInput: true,
            qrcodescan: false,
            resetkey: 1,
            showScanner: false,
            timer: null,
            batchoptions: [{batch: 'Select one', expirationdate: undefined}],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAndVerifyQty = this.handleAndVerifyQty.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() { 
        if (this.props.location.state) {
            this.setState({item: this.props.location.state}); 
            this.state.batchoptions = this.state.batchoptions.concat(this.props.location.state.batchoptions); 
            this.state.transfer = this.props.location.state.transfer;
            this.state.bintype = this.props.location.state.bintype;
            console.log('this.state',this.state)          
        }        
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
    }

    handleAndVerifyQty = (event) => {           
        const target = event.target;
        const name = target.id;   
        
        this.setState({timer:
            setTimeout(() => {
                this.setState({qty: target.value});
                console.log('timer', this.state.qty, target.value);
                //this.handleNext();     
                event.preventDefault();
            }, 1000)}
        ); 

        // if (event.key === 'Enter' || event.key === "Tab") {            
        //     this.handleNext();       
        //     event.preventDefault();   
        // }
        console.log('handleAndVerifyQty', this.state.qty);   
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.qty) {
            alert ('You must enter quantity.')
        }
        
        if (this.state.batchopt === 'select one') {
            this.state.batchopt = undefined;
        }
        
        this.state.item.qty = this.state.qty;
        this.state.item.batchopt = this.state.batchopt;        

        console.log("this.state.item", this.state.item);
       
        if (!this.state.item.itemcode || !this.state.item.bin ) {
            alert('Please enter values in all the input fields');
            return;
        } else {
            if (this.state.item.itemcode !== this.state.item.item.item) {                
                alert ('Please enter or scan a correct item code. ' + this.state.item.itemcode + ' ' + this.state.item.item.item)
                return;
            } else if (!this.state.qty && 
                      ( Number(this.state.qty) <= 0 ||
                       Number(this.state.qty) > Number(this.state.item.receivableQty)))  {                
                alert ('You entered 0 or higher than the ordered quantity.')
                return;
            } else {
                let user = JSON.parse(localStorage.getItem('user')); 
                //Checking user must not select 'Select One' from the dropdown
                if (this.state.batchopt === this.state.batchoptions[0].batch) {
                    this.state.batchopt = undefined;
                }
                if (user.config.wmsconfig && user.config.wmsconfig.batchmust === true) {
                    if (!this.state.batchopt) {
                        alert ("Batch information is required")
                        return;
                    }                    
                }

                if (this.state.batchopt) {
                    let ed = this.state.batchopt.split(' - ');
                    if (ed[0] === 'NA') {
                        ed[0] = '0';
                    }
                    this.state.batch = ed[0];
                    for (let b of this.state.batchoptions) {                        
                        console.log(ed.length, b.batch, ed[0], ed, ed[ed.length-1]);

                        if (ed.length > 1 && b.batch === ed[0]) {
                            this.state.expirationdate = ed[ed.length-1];
                            break;
                        }
                    }
                }

                let o = {};
                o.bin = this.state.item.bin;
                o.bintype = this.state.item.bintype;
                
                o.licenseplate = this.state.item.item.licenseplate;
                o.sorder = this.state.item.item.sorder;

                if (this.props.location.state.item.transferid) {
                    o.sorderid = this.props.location.state.item.transferid;    
                } else {
                    o.sorderid = this.state.item.item.orderId;
                }
                
                o.itemid = this.state.item.item.itemId;
                o.item = this.state.item.item.item;
                o.batch = this.state.batch;
                o.expirationdate = this.state.expirationdate;
                o.qty = this.state.qty;
                o.userwhoupdated = user.username;
                o.warehouse = user.config.inventoryLocation;
                o.wmsempactivityreport = this.state.item.item.wmsempactivityreport;
                console.log('o', o);
                //alert ('Got it!');
                
                // Two paths: #1: bin is matched, #2: bin does not match (user scans different bin number))
                // #1: find the records for that bin for items. Deduct the qty.
                // If there are multiple records then start deducting items from the qty which
                // is just above the ordered qty. If there are no such larger bucket then use 
                // multiple records and adjust qty for those.
                // If batch # number is available and if batch is used for fulfillment then,
                // We need to put batch number in the good issued deocument.
                // #2: check whether user scanned bin has required items in the WMS. If it has
                // then adjust those items. If there are no such items then flag error.
                // If bin was "NOT FOUND" then check why system did not pick it up and if possible
                // update the record so that next time this bin can be listed.

                wmsService.pick(o).then(witm => {
                    console.log('witm', witm);
                    if (witm && witm === 401) {
                        this.props.history.push("/login");
                    } else {
                        if (this.props.location.state.transfer === true) {
                            this.goToTransferPicking(o.sorderid);
                        } else {
                            this.goToSorderPicking(o.sorderid);
                        }
                        //this.props.history.push(from, obj);                         
                    }
                });
            }
        }        
    }
    
    goToTransferPicking = (id) => {        
        let obj = {};
        wmsService.getTransfer(id).then(transfer => {            
            obj.sorder = transfer;
            obj.sorder.orderNum = transfer.transfernum;
            transfer.lineItem.forEach(it => {
                it.item = it.itemcode;
                delete it.itemcode;
            })    
            obj.lp = this.state.item.item.licenseplate;                      

            obj.wmsempactivityreport = this.state.item.item.wmsempactivityreport;
            console.log('go Transfer pickitem obj ', obj);
            var { from } = { from: { pathname: "/pickitems" } };  
            this.props.history.push(from, obj);
        });
    }

    goToSorderPicking = (id) => {
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getSoForPick(user.username, id, user.config.inventoryLocation, this.state.zone).then(sorder => {
            console.log(sorder);
            if (sorder && sorder === 401) {
                this.props.history.push("/login");
            } else {
                if (sorder.lineItem && sorder.lineItem.length > 0) {
                    let obj = {};
                    obj.sorder = sorder;    
                    obj.lp = this.state.item.item.licenseplate;             
                    obj.wmsempactivityreport = this.state.item.item.wmsempactivityreport;
                    console.log('go Sorder pickitem obj ', obj);
                    //var { from } =  { from: { pathname: "/pickitems" } };  
                    this.props.history.push("/pickitems" , obj);
                } else {
                    alert('Sales order is not found. It could be being picked by other user');
                    return;
                }
            }
        }); 
    }

    goBack =() => {   
        //alert('goBack...');     
        this.props.history.goBack();
    }

    render() {
        if (!this.state.item.item) {
            return <div></div>;
        }
        console.log('this.props.location.state', this.props.location.state, ' this.props.location.state.transfer', this.props.location.state.transfer)
        
        let opt = [];
        //opt.push(<option value={'select one'}>Select one</option>)            
        for (let p of this.state.batchoptions) {
            //console.log(p);
            let expd = undefined;
            if (p.expirationdate) {
                let date = new Date(p.expirationdate);
                expd = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
            }
            let k = (p.batch === '0' ? 'NA' : p.batch);
            if (expd) {
                k = k + ' - ' + expd;
            }
            opt.push(<option value={k.batch}>{k}</option>)            
        }
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            
            <div key={this.state.resetkey}>                
                <Card>
                    <Card.Header>Picking Items from Bin                        
                    </Card.Header>
                    <Card.Body>
                        {/* <Form onSubmit={this.handleSubmit}> */}
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Bin: {this.state.item.bin}</Form.Label>
                            </Form.Row>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Item Code: {this.state.item.item.item}</Form.Label>
                            </Form.Row>
                            
                            <Form.Row>
                                <Form.Group as={Col} controlId="qty" onKeyDown={this.handleAndVerifyQty}>
                                <Form.Label style={{fontSize: "14px"}}>Enter Quantity</Form.Label>
                                <Form.Control required={true} autoComplete="off" placeholder="Enter quantity" defaultValue={this.state.qty} autoFocus />
                                </Form.Group>
                            </Form.Row> 

                            {(user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false ) &&
                            <Form.Row>                   
                                <Form.Group as={Col} controlId="batchopt" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Select a Batch</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.batchopt}>{opt}</Form.Control> 
                                </Form.Group>
                            </Form.Row> 
                            }
                            
                            <div style={{float: "left"}} >     
                            {/* {(!this.state.qtyInput || this.state.qty) &&                                  
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>  
                            } */}
                            
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>                               
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>                            
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default PickingItemSQuantity;