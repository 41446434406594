import React from 'react'
import { Button } from 'react-bootstrap';
import SignaturePad from 'react-signature-pad-wrapper'
import { Redirect } from "react-router-dom";
import { signService } from "../services/signService";
import moment from 'moment';

class Signature extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceid: undefined,
            signid: undefined,
            goBack: undefined,
            signaturedate: undefined,
            signature: undefined,
        }
    }

    //create table datamoto_113845.invoicesignatures(id bigint not null, invoiceid bigint not null, signature character varying null, signaturedate timestamp with time zone, "createdAt" timestamp with time zone, "updatedAt" timestamp with time zone, primary key(id));

    componentDidMount() {
        console.log(this.props.location.state);
        this.setState({ invoiceid: this.props.location.state });
        this.signaturePad.penColor = 'rgb(66, 133, 244)';
        this.signaturePad.backgroundColor = 'rgb(255,255,255)';
        this.signaturePad.instance.clear();

        signService.getSignature(this.props.location.state).then(ord => {
            if (ord && ord === 401) {
                this.props.history.push("/login");
            } else {
                console.log(ord);
                if (ord.id) {
                    this.state.signid = ord.id;
                    this.setState({ signaturedate: ord.signaturedate, signature: ord.signature })
                }
            }
        });
    }



    handleClear() {
        this.signaturePad.instance.clear();
    }

    handlegoBack() {
        this.props.history.goBack();
    }

    handleSave() {
        if (this.signaturePad.isEmpty()) {
            // eslint-disable-next-line no-alert
            alert('Please provide a signature first.');
        } else {
            //console.log(this.signaturePad.toDataURL("image/jpeg")); 
            let inv = {};
            inv.invoiceid = this.state.invoiceid;
            inv.Signature = this.signaturePad.toDataURL("image/jpeg");
            inv.signaturedate = new Date();

            if (this.state.signid) {
                inv.id = this.state.signid;
                signService.updateSignature(inv, this.state.signid).then(ord => {
                    if (ord && ord === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.props.history.goBack();
                        this.setState({ goBack: true });
                    }
                });
            } else {
                signService.addSignature(inv).then(ord => {
                    if (ord && ord === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.props.history.goBack();
                        this.setState({ goBack: true });
                    }
                });
            }
        }
    }

    renderTitle() {
        return (
            <div className="columns">
                <div className="column">
                    <h6 className="title" style={{ fontWeight: 'bold', marginTop: '6px' }}>Sign the Invoice</h6>
                </div>
            </div>
        );
    }


    renderSignaturePad() {

        return (
            <div className="columns">
                <div className="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
                    <div className="card" style={{ width: '27rem' }}>
                        <div className="card-content">
                            {/* <div className="content"> */}
                            <SignaturePad
                                redrawOnResize={true}
                                ref={ref => this.signaturePad = ref} height={130} />
                            {/* </div> */}
                        </div>
                    </div>
                    <Button variant="outline-primary mt-1" size="sm" onClick={this.handleClear.bind(this)}>Clear</Button>
                    <Button variant="outline-primary ml-3 mt-1" size="sm" onClick={this.handlegoBack.bind(this)}>Cancel</Button>
                    <Button variant="outline-primary ml-3 mt-1" size="sm" onClick={this.handleSave.bind(this)}>Save</Button>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.goBack) {
            return <Redirect to={"/servicedetail/" + this.state.invoiceid} />
        }

        return (
            <>
                <section className="section">
                    <div className="container">
                        {this.renderTitle()}
                        {this.renderSignaturePad()}
                        {this.state.signaturedate &&
                            <div>
                                <div>&nbsp;</div>
                                <div className="columns">
                                    <div className="column">
                                        <h6 className="title" style={{marginTop: '12px' }}>Last signed date: {moment(this.state.signaturedate).format('ll')}</h6>
                                    </div>
                                    <div><img src={this.state.signature} width="300px" /></div>
                                </div>
                            </div>
                        }
                    </div>

                </section>

            </>
        );
    }

}

export default Signature;