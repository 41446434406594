import React from 'react';
import "./../App.css";
import {Box, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
//import Box from '@mui/material/Box';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
import HomeWork from '@material-ui/icons/HomeWork';
import Apps from '@material-ui/icons/Apps';
import Business from '@material-ui/icons/Business';
import CardMembership from '@material-ui/icons/CardMembership';
import WorkOutline from '@material-ui/icons/WorkOutline';
import Beenhere from '@material-ui/icons/Beenhere';
import Photo from '@material-ui/icons/Photo';
import People from '@material-ui/icons/People';
import SettingApp from '@material-ui/icons/SettingsApplications';

import { Link } from 'react-router-dom'


class Setting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
        };

    }

    handleChange(e) {
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { location: { pathname } } = this.props
        return (
            
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>
                    <ListItem  button component={Link} to="/wmsappcfg" selected={'/wmsappcfg' === pathname}>
                        
                        <ListItemIcon>
                            <SettingApp />
                        </ListItemIcon>
                        <ListItemText primary="Application Configuration" />
                        
                    </ListItem>   
                    <ListItem  button component={Link} to="/swarehouse" selected={'/swarehouse' === pathname}>
                        
                        <ListItemIcon>
                            <HomeWork />
                        </ListItemIcon>
                        <ListItemText primary="Warehouse" />
                        
                    </ListItem>    
                    <ListItem  button component={Link} to="/szone" selected={'/szone' === pathname}>
                        
                        <ListItemIcon>
                            <Apps />
                        </ListItemIcon>
                        <ListItemText primary="Zones" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/ssection" selected={'/ssection' === pathname}>
                        
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary="Storage Section" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/sbintype" selected={'/sbintype' === pathname}>
                        
                        <ListItemIcon>
                            <CardMembership />
                        </ListItemIcon>
                        <ListItemText primary="Bin Type" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/sbin" selected={'/sbin' === pathname}>
                        
                        <ListItemIcon>
                            <WorkOutline />
                        </ListItemIcon>
                        <ListItemText primary="Bin" />
                        
                    </ListItem>                    
                    <ListItem  button component={Link} to="/slp" selected={'/slp' === pathname}>
                        
                        <ListItemIcon>
                            <Beenhere />
                        </ListItemIcon>
                        <ListItemText primary="Lincense Plate" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/semp" selected={'/semp' === pathname}>
                        
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText primary="Employee" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/wexample" selected={'/wexample' === pathname}>
                        
                        <ListItemIcon>
                            <Photo fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Warehouse Setting Example" />
                        
                    </ListItem>
                    </List>
                </nav>
            </Box>      
        );
    }
}
export default Setting;