import React from 'react';
import "./../App.css";
import { Button, Modal } from 'react-bootstrap';
import Html5QrcodePlugin from "../components/Html5QrcodePlugin"

class Scanner extends React.Component {
    constructor(props) {
        super(props);
    }


    render () {
        return (
        <>
        <Modal show={this.props.showScanner} onHide={this.props.closeScanner} animation={false}>
            <Modal.Header closeButton>                    
            </Modal.Header>
            <Modal.Body>
            <Html5QrcodePlugin 
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={this.props.onNewScanResult}/>                            
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.props.closeScanner}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </>);
    }
}
export default Scanner;