import React from "react";
import { customerService } from '../services/customerService';
import { messageService } from '../services/messageService';
import { Card, Button, Form, Col } from 'react-bootstrap';

class EmailSend extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      module: undefined,
      to: undefined,
      cc: undefined,
      subject: undefined,
      message: undefined,
      signature: undefined,
      pdfattach: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);    
  }

  componentDidMount() {  
    
    console.log('this.props.location.state', this.props.location.state)
    if (this.props.location.state) {
      this.state.id = this.props.location.state.id;
      this.state.module = this.props.location.state.module;
      this.state.customerid = this.props.location.state.customerid;
    }
    //console.log(this.state.id , this.state.module, this.state.customerid);
    if (!this.state.id || !this.state.module || !this.state.customerid) {
      return;
    }

    let usr = JSON.parse(localStorage.getItem('user'));
    console.log('user', usr);
    if (usr.config.config) {
      usr.config.config = JSON.parse(usr.config.config);
      if (this.state.module === 'invoice') {
        if (usr.config.config.emailInvoiceBody) {
          this.setState({message: usr.config.config.emailInvoiceBody})
        }
        if (usr.config.config.emailInvoiceSubject) {
          this.setState({subject: usr.config.config.emailInvoiceSubject})
        }
        if (usr.config.config.emailInvoiceSignature) {
          this.setState({signature: usr.config.config.emailInvoiceSignature})
        }
      } else if (this.state.module === 'quote') {
        if (usr.config.config.emailQuoteBody) {
          this.setState({message: usr.config.config.emailQuoteBody})
        }
        if (usr.config.config.emailQuoteSubject) {
          this.setState({subject: usr.config.config.emailQuoteSubject})
        }
        if (usr.config.config.emailQuoteSignature) {
          this.setState({signature: usr.config.config.emailQuoteSignature})
        }
      }
    }
    //console.log('user', usr);

    customerService.getAddressList(this.state.customerid).then(cl => {
      if (cl && cl === 401) {
        this.props.history.push("/login");
      } else if (cl && cl.length > 0) {
        //console.log('cl', cl);
        for (let a of cl) {
          if (a.is_a_contact === true) {
            this.setState({to: a.email});
            break;  
          }
        }
      }
    });

    if (this.state.module === 'invoice' || this.state.module === 'quote') {
       this.setState({pdfattach: ' - PDF is already attached'})
    } 

  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.id;
    //alert(name + ', ' + target.value);
    this.setState({
      [name]: target.value
    });
   
  }

  goBack = (e) => {
    this.props.history.goBack();
  }
  
  handleSubmit(e) {
    e.preventDefault();
    let email = {};
    email.id = this.state.id;
    email.module = 'invoice';
    email.to = this.state.to;
    if (this.state.cc) {
      email.cc = this.state.cc;      
    }
    if (this.state.subject) {
      email.subject = this.state.subject;      
    }
    if (this.state.msg) {
      email.msg = this.state.message;      
    }
    if (this.state.signature) {
      email.signature = this.state.signature;      
    }

    messageService.sendEmail(email).then(cl => {
      if (cl && cl === 401) {
        this.props.history.push("/login");
      } else  {
        alert('Email is sent');
        this.props.history.goBack();
      }
    });
  }

  render() {
    return (
      <div className="content-screen">        
        <Card className="mb-3" style={{ width: '36em' }}>
          <Card.Header>
              <div style={{float: 'left', display: "inline-block"}}>
              <>Sending Email {this.state.pdfattach}</>                   
              </div>              
          </Card.Header>
          <Card.Body>      
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} controlId="to" onChange={this.handleInputChange}>
                  <Form.Label style={{fontSize: "14px"}}>To</Form.Label>
                  <Form.Control  required="true" placeholder="" defaultValue={this.state.to} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="cc" onChange={this.handleInputChange}>
                  <Form.Label style={{fontSize: "14px"}}>CC</Form.Label>
                  <Form.Control  placeholder="" defaultValue={this.state.cc} />
                </Form.Group>
              </Form.Row> 

              <Form.Row>
                <Form.Group as={Col} controlId="subject" onChange={this.handleInputChange}>
                  <Form.Label style={{fontSize: "14px"}}>Subject</Form.Label>
                  <Form.Control  placeholder="" defaultValue={this.state.subject} />
                </Form.Group>
              </Form.Row> 

              <Form.Row>
                <Form.Group as={Col} controlId="message" onChange={this.handleInputChange}>
                  <Form.Label style={{fontSize: "14px"}}>Message</Form.Label>
                  <Form.Control  as="textarea" rows={8} defaultValue={this.state.message} />
                </Form.Group>
              </Form.Row> 

              <div style={{float: "left"}} >                                       
                <Button variant="outline-primary mr-2" size="sm" type="submit">Send</Button>  
                <Button variant="outline-primary" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                            
              </div>
            </Form>
              
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default EmailSend;