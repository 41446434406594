import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";
import { TransferWithinAStationTwoTone } from '@material-ui/icons';
import { withRouter } from "react-router-dom";

class PickingItemSBin extends React.Component {
    constructor(props) {
        super(props);
        console.log('PickingItemSBin', props);
        this.state = {
            item: {},
            qty: undefined,
            itemcode: undefined,
            bin: undefined,
            batch: undefined,
            batchopt: undefined,
            expirationdate: undefined,
            binInput: false,
            itemInput: true,
            qtyInput: true,
            qrcodescan: false,
            resetkey: 1,
            showScanner: false,
            timer: null,
            batchoptions: [{ batch: 'Select one', expirationdate: undefined }],
        };
        this.handleInputChangeBin = this.handleInputChangeBin.bind(this);
        this.goBack = this.goBack.bind(this);
        this.scanAny = this.scanAny.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.goNext = this.goNext.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ item: this.props.location.state });
            this.state.transfer = this.props.location.state.transfer;
        }
    }

    handleInputChangeBin = (event) => {
        const target = event.target;
        const name = target.id;

        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({ timer: null });
        }
        this.setState({
            timer:
                setTimeout(() => {
                    this.setState({ bin: target.value });
                    console.log('timer', this.state.bin, target.value);
                    this.handleNext(event);
                    event.preventDefault();
                }, 500)
        }
        );

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
        }
    }

    handleNext() {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(this.state.binInput, this.state.itemInput, this.state.qtyInput);
        if (!this.state.bin) {
            //alert("Your input is not correct. Please enter or scan correct bin")
            //this.setState({blur: this.state.blur}); // just a reloading react component technicue
        } else {
            console.log('user', user);
            console.log('user.config.wmsconfig', user.config.wmsconfig);
            if (user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false) {
                wmsService.getPickingBatch(this.state.bin, this.state.item.item, user.config.inventoryLocation).then(witm => {
                    console.log('batch', witm);
                    if (witm && witm === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.setState({ batchoptions: witm });
                    }
                });
            }
            //this.setState({binInput: true, itemInput: false});
        }

    }

    goNext(e) {                        
        //this.props.history.push("/pickitmqtybatchitm", this.state);
        if (this.state.bin && this.state.item.bins.indexOf(this.state.bin) >= 0) {
            this.state.bintype = 1;
            this.props.history.push("/pickitemsi", this.state);        
        } else {
            alert('Enter the correct bin location');
        }
    }

    goBack = () => {
        //alert('goBack...');     
        this.props.history.goBack();
    }

    scanAny = () => {
        this.setState({ showScanner: true });
    }

    onNewScanResult(decodedText, decodedResult) {
        this.setState({ showScanner: false, bin: decodedText, binInput: true, itemInput: false, qtyInput: true });
    }

    closeScanner() {
        this.setState({ showScanner: false })
    }

    render() {
        //console.log('qty',this.state.qty, this.state.itemcode);
        let bins = 'Not found';
        if (this.state.item && this.state.item.bins) {
            for (let b of this.state.item.bins) {
                if (bins.length > 0) {
                    if (bins === 'Not found') {
                        bins = '';
                    } else {
                        bins = bins + ' | '
                    }
                }
                bins = bins + b;
            }
        }

        return (

            <div key={this.state.resetkey}>
                <Card>
                    <Card.Header>Picking Items from Bin
                    </Card.Header>
                    <Card.Body>
                        {/* <Form onSubmit={this.handleSubmit}> */}
                        <Form onSubmit={this.goNext}>
                            <Form.Row>
                                <Form.Label style={{ fontSize: "14px", fontWeight: 'bold' }}>&nbsp;Bin: {bins}</Form.Label>
                            </Form.Row>
                            <Form.Row>
                                <Form.Label style={{ fontSize: "14px"}}>&nbsp;Item Code: {this.state.item.item}</Form.Label>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="bin"
                                    onKeyDown={this.handleInputChangeBin}>
                                    <Form.Label style={{ fontSize: "14px" }}>Enter or Scan Bin</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control autoFocus={this.state.binInput} disabled={this.state.binInput} required={true} autoComplete="off" placeholder="Enter or scan bin" defaultValue={this.state.bin} autoFocus />
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>

                            <div style={{ float: "left" }} >
                                {/* {(!this.state.qtyInput || this.state.qty) &&                                  
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>  
                            } */}

                                <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>

                                <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => { this.scanAny() }}>Scan</Button>
                                <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => { this.goBack() }}>Cancel</Button>
                            </div>

                            <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

        );
    }
}
export default PickingItemSBin;