import React from "react";
import "./App.css";
import { Switch } from "react-router-dom";

import Home from "./components/Home";
import Inblund from "./components/Inbound";
import Outbound from "./components/Outbound";
import Receive from "./components/Receive";
import ReceivablePoSelection from "./components/ReceivablePoSelection";
import ReceiveLicensePlate from "./components/ReceiveLicensePlate";
import ReceiveItems from "./components/ReceiveItems";
import ReceiveEnterItemAndQuantity from "./components/ReceiveEnterItemAndQuantity";
import PutAwayToBin from "./components/PutAwayToBin";
import PutAwayToBinAssignment from './components/PutAwayToBinAssignment';
import PalletItems from './components/PalletItems';
import PutAwayToNewBin from './components/PutAwayToNewBin';
import Picking from './components/Picking';
import PickingItems from './components/PickingItems';
import PickingItemAndQuantity from './components/PickingItemAndQuantity';
import Shipping from './components/Shipping';
import Packing from './components/Packing';
import PackItems from './components/PackItems';
import PackingDone from './components/PackingDone';
import PackingLabelPrint from './components/PackingLabelPrint';
import ShipItems from './components/ShipItems';
import Setting from './components/Setting';
import WarehouseExample from './components/WarehouseExample';
import SettingWarehouse from './components/SettingWarehouse';
import SettingWarehouseEdit from './components/SettingWarehouseEdit';
import SettingZone from "./components/SettingZone";
import SettingZoneEdit from "./components/SettingZoneEdit";
import SettingSection from "./components/SettingSection";
import SettingSectionEdit from "./components/SettingSectionEdit";
import SettingBinType from "./components/SettingBinType";
import SettingBinTypeEdit from "./components/SettingBinTypeEdit";
import SettingBin from "./components/SettingBin";
import SettingBinEdit from "./components/SettingBinEdit";
import SettingLicensePlate from "./components/SettingLicensePlate";
import SettingLicensePlateEdit from "./components/SettingLicensePlateEdit";
import SettingEmployee from "./components/SettingEmployee";
import SettingEmployeeEdit from './components/SettingEmployeeEdit';
import Counting from './components/Counting';
import CountCycleBin from './components/CountCycleBin';
import CountCycleBinStart from './components/CountCycleBinStart'
import CountCycleItem from './components/CountCycleItem';
import CountCycleItemBatchUom from "./components/CountCycleItemBatchUom";
import CountCycleItemBinScan from './components/CountCycleItemBinScan';
import CountCycleItemBinItemScan from './components/CountCycleItemBinItemScan';
import Operation from './components/Operation';
import OperationPending from './components/OperationPending';
import OperationApproved from './components/OperationApproved';
import OperationActiveOutboundOrders from './components/OperationActiveOutboundOrders';
import OperationPickStatus from './components/OperationPickStatus';
import  OperationPackedNotYetShip from './components/OperationPackedNotYetShip';
import SettingWmsApplication from "./components/SettingWmsApplication";
import OperationInboundOrderStatus from "./components/OperationInboundOrderStatus";
import OperationWmsInventory from "./components/OperationWmsInventory";
import LabelPrint from "./components/LabelPrint";
import PickingScanBin from "./components/PickingScanBin";
import PackingSelectLicensePlate from "./components/PackingSelectLicensePlate";
import PickingItemAndQuantityItemBatch from "./components/PickingItemAndQuantityItemBatch";
import InvoiceList from "./components/InvoiceList";
import InvoiceView from "./components/InvoiceView";
import InvoiceAddLineItem from "./components/InvoiceAddLineItem";
import EmailSend from "./components/EmailSend";
import Signature from "./components/Signature";
import InvoiceCreate from "./components/InvoiceCreate";
import CustomerCreate from "./components/CustomerCreate";
import TransferList from "./components/TransferList";
import TransferView from "./components/TransferView";
import TransferAddLineItem from "./components/TransferAddLineItem";

import PickingItemSBin from "./components/PickingItemSBin";
import PickingItemSItem from "./components/PickingItemSItem";
import PickingItemSQuantity from "./components/PickingItemSQuantity";
import TransferMenu from "./components/TransferMenu";
import TransferListReadyToLoad from "./components/TransferListReadyToLoad";
import TransferListPending from "./components/TransferListPending";
import SearchItem from "./components/SearchItem";
import SearchItemFound from "./components/SearchItemFound";

import { PrivateRoute } from './PrivateRoute';


export default function Routes() {
  
  let loginUsr = localStorage.getItem('user');
  if (loginUsr) {
    loginUsr = JSON.parse(loginUsr);
    let currentTm = Math.floor(Date.now() / 1000);    
    if (Number(currentTm) - Number(loginUsr.cachetouch) > 14400) {  // no activity for 4 hours then logout
      localStorage.removeItem('user');   
    } else {
      loginUsr.cachetouch = currentTm;
      localStorage.setItem('user', JSON.stringify(loginUsr)); 
    }
  }
  
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/inbound" exact component={Inblund} />
      <PrivateRoute path="/outbound" exact component={Outbound} />
      <PrivateRoute path="/receive" exact component={Receive} />
      <PrivateRoute path="/receivelp" exact component={ReceiveLicensePlate} />
      <PrivateRoute path="/receiveitms" exact component={ReceiveItems} />
      <PrivateRoute path="/receiveitmqty" exact component={ReceiveEnterItemAndQuantity} />
      <PrivateRoute path="/receiveposel" exact component={ReceivablePoSelection} />
      <PrivateRoute path="/putaway" exact component={PutAwayToBin} />
      <PrivateRoute path="/assingtobin" exact component={PutAwayToBinAssignment} />
      <PrivateRoute path="/palletitems" exact component={PalletItems} />
      <PrivateRoute path="/assignnewbin" exact component={PutAwayToNewBin} />
      <PrivateRoute path="/picking" exact component={Picking} />
      <PrivateRoute path="/picking/:sonum" exact component={Picking} />
      <PrivateRoute path="/pickitems" exact component={PickingItems} />
      <PrivateRoute path="/pickitemsb" exact component={PickingItemSBin} />
      <PrivateRoute path="/pickitemsi" exact component={PickingItemSItem} />
      <PrivateRoute path="/pickitemsq" exact component={PickingItemSQuantity} />

      <PrivateRoute path="/pickitmqty" exact component={PickingItemAndQuantity} />
      <PrivateRoute path="/pickitmqtybatchitm" exact component={PickingItemAndQuantityItemBatch} />
      <PrivateRoute path="/shipping" exact component={Shipping} />
      <PrivateRoute path="/packing" exact component={Packing} />
      <PrivateRoute path="/packitems" exact component={PackItems} />
      <PrivateRoute path="/packingdone" exact component={PackingDone} />
      <PrivateRoute path="/packinglabelprint" exact component={PackingLabelPrint} />
      <PrivateRoute path="/shipitems" exact component={ShipItems} />
      <PrivateRoute path="/setting" exact component={Setting} />
      <PrivateRoute path="/wexample" exact component={WarehouseExample} />
      <PrivateRoute path="/swarehouse" exact component={SettingWarehouse} />
      <PrivateRoute path="/swarehouseedit" exact component={SettingWarehouseEdit} />
      <PrivateRoute path="/szone" exact component={SettingZone} />
      <PrivateRoute path="/szoneedit" exact component={SettingZoneEdit} />
      <PrivateRoute path="/ssection" exact component={SettingSection} />
      <PrivateRoute path="/ssectionedit" exact component={SettingSectionEdit} />
      <PrivateRoute path="/sbintype" exact component={SettingBinType} />
      <PrivateRoute path="/sbintypeedit" exact component={SettingBinTypeEdit} />
      <PrivateRoute path="/sbin" exact component={SettingBin} />
      <PrivateRoute path="/sbinedit" exact component={SettingBinEdit} />
      <PrivateRoute path="/slp" exact component={SettingLicensePlate} />
      <PrivateRoute path="/slpedit" exact component={SettingLicensePlateEdit} />
      <PrivateRoute path="/semp" exact component={SettingEmployee} />
      <PrivateRoute path="/sempedit" exact component={SettingEmployeeEdit} />
      <PrivateRoute path="/count" exact component={Counting} />
      <PrivateRoute path="/countcy" exact component={CountCycleBin} />
      <PrivateRoute path="/countcycle" exact component={CountCycleBinStart} />      
      <PrivateRoute path="/countcyitm" exact component={CountCycleItem} />
      <PrivateRoute path="/countcyitmbu" exact component={CountCycleItemBatchUom} />
      <PrivateRoute path="/countcyitmblnd" exact component={CountCycleItem} />
      <PrivateRoute path="/countcyitmbin" exact component={CountCycleItemBinScan} />
      <PrivateRoute path="/countcyitmbinitm" exact component={CountCycleItemBinItemScan} />
      <PrivateRoute path="/oprtn" exact component={Operation} />
      <PrivateRoute path="/oprtnpend" exact component={OperationPending} />
      <PrivateRoute path="/oprtnapprv" exact component={OperationApproved} />
      <PrivateRoute path="/opractoutbord" exact component={OperationActiveOutboundOrders} />
      <PrivateRoute path="/oprpckstatus" exact component={OperationPickStatus} />
      <PrivateRoute path="/oprpaknotship" exact component={OperationPackedNotYetShip} />
      <PrivateRoute path="/wmsappcfg" exact component={SettingWmsApplication} />
      <PrivateRoute path="/oprinboundstatus" exact component={OperationInboundOrderStatus} />
      <PrivateRoute path="/oprinventory" exact component={OperationWmsInventory} />
      <PrivateRoute path="/lblprnt" exact component={LabelPrint} />
      <PrivateRoute path="/pickbinscan" exact component={PickingScanBin} />
      <PrivateRoute path="/packinglp" exact component={PackingSelectLicensePlate} />
      <PrivateRoute path="/invoiceList" exact component={InvoiceList} />
      <PrivateRoute path="/InvoiceView" exact component={InvoiceView} />
      <PrivateRoute path="/invoicelineitem/:id" exact component={InvoiceAddLineItem} />
      <PrivateRoute path="/sendemail"  exact component={EmailSend} />
      <PrivateRoute path="/sign"  exact component={Signature} />
      <PrivateRoute path="/invoicecreate"  exact component={InvoiceCreate} />
      <PrivateRoute path="/createcustomer"  exact component={CustomerCreate} />
      <PrivateRoute path="/transferlist" exact component={TransferList} />
      <PrivateRoute path="/transferview" exact component={TransferView} />
      <PrivateRoute path="/transferlineitem/:id" exact component={TransferAddLineItem} />
      <PrivateRoute path="/transfermenu" exact component={TransferMenu} /> 
      <PrivateRoute path="/transferlistreadytoload" exact component={TransferListReadyToLoad} />
      <PrivateRoute path="/transferlistpending" exact component={TransferListPending} /> 
      <PrivateRoute path="/searchitem" exact component={SearchItem} />   
      <PrivateRoute path="/searchitemfound" exact component={SearchItemFound} />      
   
    </Switch>
  );
}