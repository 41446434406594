import React, { forwardRef } from "react";
import { Button, Table } from 'react-bootstrap';
import "./../App.css";
import { wmsService } from "../services/wmsService";
import { invoiceService } from "../services/invoiceService";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import ScannerIcon from '@material-ui/icons/GradientOutlined';
import { ControlPointDuplicateOutlined, TramOutlined } from "@material-ui/icons";

import Scanner from "./Scanner";
import { Divider, Grid, Typography, List, ListItem, ListItemText } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    //Edit: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ScannerIcon: forwardRef((props, ref) => <ScannerIcon {...props} ref={ref} />),
};


class TransferView extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            config: undefined,
            count: 0,
            scanitem: null,
            cycle: null,
            showScanner: false,
            reload: 0,
            selectedrows: undefined,
            warehouse: undefined,
            data: [],
            totalCount: 0,
            page: 0,
            uom: 0,
            columns: [
                {
                    field: 'itemcode',
                    title: 'Item',
                    editable: false,
                },
                {
                    field: 'description',
                    title: 'Description',
                    editable: false,
                },
                {
                    field: 'qty',
                    title: 'Qty',
                },
                {
                    field: 'uom',
                    title: 'UOM',
                    lookup: this.getUom(),
                },
                {
                    field: 'qtyfulfilled',
                    title: 'Qty Fulfilled',
                    editable: false,
                },
            ],

            showScanner: false,
        };
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);
        this.scan = this.scan.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
        this.addLineItem = this.addLineItem.bind(this);
        this.addItemInTransfer = this.addItemInTransfer.bind(this);
        this.deleteLineItem = this.deleteLineItem.bind(this);
        this.submitToFulfill = this.submitToFulfill.bind(this);
        this.goPickAndPack = this.goPickAndPack.bind(this);
        this.goToPicking = this.goToPicking.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.state.warehouse = user.config.inventoryLocation;
        console.log('props.location.state', this.props.location.state);
        if (this.props.location.state && this.props.location.state.lineItem) {
            this.state.data = this.props.location.state.lineItem;
            this.setState({ data: this.props.location.state.lineItem });
        }
    }

    getUom() {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        let uom = {};
        if (user.config.mou && user.config.mou.length > 0) {
            for (let i = 0; i < user.config.mou.length; i++) {
                uom[user.config.mou[i]] = user.config.mou[i];
                i++;
            }
        }
        console.log('uom', uom);
        return uom;
    }

    goBack = () => {
        //this.props.history.goBack();
        var { from } = { from: { pathname: "/transferlist" } };
        this.props.history.push(from);
    }

    goAddLineItem = () => {
        if (this.props.location.state.status === 1) {
            var { from } = { from: { pathname: "/transferlineitem/" + this.props.location.state.id } };
            this.props.history.push(from);
        } else {
            alert ('You can not edit a submitted transfer.')
        }
    }

    submitToFulfill = () => {
        if (this.props.location.state.status === 1) { // if it is in draft status    
            if (this.props.location.state.lineItem && this.props.location.state.lineItem.length > 0) {
                let tr = {};
                tr.id = this.props.location.state.id;
                tr.status = 2;
                wmsService.updateTransferHeader(tr).then(pinfo => {
                    if (pinfo && pinfo === 401) {
                        this.props.history.push("/login");
                    } else {
                        alert('This transfer order is submitted');
                        this.props.location.state.status = 2;
                        this.setState({ count: this.state.count + 1 });
                    }
                });
            }
            else {
                alert('This transfer is not submitted. It must have line items to submit for fulfillment');
            }
        }
    }

    goPickAndPack = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (this.props.location.state.requestingwh === user.config.inventoryLocation) {
            alert ('You are part of the requesting WH. Only person from fulfillment WH can fulfill the transfer');
        } else if (this.props.location.state.status === 3) { // for In-process status            
            if  (this.props.location.state.fulfilledby !== user.username) {
                alert ('Failed. Becuase other user is actively fulfilling this transfer.');
            } else {
                // go to picking item page
                this.goToPicking();
            }
        } else {
            let tr = {};
            tr.id = this.props.location.state.id;
            tr.fromstatus = 2;
            tr.tostatus = 3;
            tr.fulfilledby = user.username;
            tr.fulfillingdate = new Date();
            tr.fulfillingwh = user.config.warehouse;

            wmsService.updateTransferStatusToInProcess(tr).then(t => {
                if (t && t === 401) {
                    this.props.history.push("/login");
                } else if (t && t > 0) {
                    // go to picking item page
                    this.goToPicking();
                } else {
                    alert ('Failed. Refresh the transfer to check the status. It must be in pending state to pick and pack.');
                }
            });
        }
    }

    goToPicking = () => {
        console.log(this.props.location.state);
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getTransfer(this.props.location.state.id).then(transfer => {            
            this.state.sorder = transfer;
            this.state.sorder.orderNum = transfer.transfernum;
            transfer.lineItem.forEach(it => {
                it.item = it.itemcode;
                delete it.itemcode;
            })
            let wmsempactivityreport = {};
            wmsempactivityreport.invlocation = user.config.inventoryLocation;
            wmsempactivityreport.wmsorder = transfer.transfernum;
            wmsempactivityreport.employee = user.username
            wmsempactivityreport.type = 1;
            wmsempactivityreport.starttime = (new Date()).toISOString();
            this.state.wmsempactivityreport = wmsempactivityreport;
            this.state.transfer = true;
            var { from } = { from: { pathname: "/pickbinscan" } };  
            this.props.history.push(from, this.state);
        });
    }

    goLoad = () => {
        /*
        1. First check whether there is anything to load, if not then display message
        2. If there is something to load then go to transfer load page.
        */
        wmsService.getShipReadyPackedItemsByOrder(this.props.location.state.transfernum, this.props.location.state.fulfillingwh).then(pord => {
            console.log('pord', pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                if (pord && pord.length > 0) {
                    let user = JSON.parse(localStorage.getItem('user'));
                    let wmsempactivityreport = {};
                    wmsempactivityreport.invlocation = user.config.inventoryLocation;
                    wmsempactivityreport.wmsorder = this.props.location.state.transfernum;
                    wmsempactivityreport.employee = user.username
                    wmsempactivityreport.type = 1;
                    wmsempactivityreport.starttime = (new Date()).toISOString();
                    pord[0].wmsempactivityreport = wmsempactivityreport;
                    pord[0].fromtransfer = 0;
                    pord[0].transferitem = this.props.location.state.lineItem;
                    pord[0].transfer = this.props.location.state;

                    var { from } = { from: { pathname: "/shipitems" } };  
                    this.props.history.push(from, pord[0]);
                } else {
                    //alert ("No packed items available to load.")
                }
            }
        });
    }

    render() {
        /*
        1. When the load is clicked ask to scan the license plate or pallet (this op should be 
            configurable)
        2. On the transfer load page, remove the scan operation.
        3. Only user from the requested warehouse can receive items - so enable the load button 
           for users who belongs to the receiving warehouse. (a user must assigned to only one warehouse)
        4. Users in the receiving warehouse can't fulfill the order - so there is no pick and 
           pack button for these users. Once a warehouse picks up a transfer order then only the 
           users from that warehouse can see the pick and pack button.
        */

        let titl = <div></div>;

        let rdate = '';
        let fdate = '';
        let status = '';

        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.requesteddate) {
                rdate = new Date(this.props.location.state.requesteddate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
            }
            if (this.props.location.state.fulfillingdate) {
                fdate = new Date(this.props.location.state.fulfillingdate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
            }
            if (this.props.location.state.status) {
                if (this.props.location.state.status == 1) {
                    status = "Draft";
                } else if (this.props.location.state.status == 2) {
                    status = "Pending";
                } else if (this.props.location.state.status == 3) {
                    status = "In Process";
                } else if (this.props.location.state.status == 4) {
                    status = "Loaded";
                } else {
                    status = "Ready to load";
                }
            }

            titl =

                <Table size="sm" bordered="false" className='mt-1'>
                    <tbody>
                        <tr>
                            <td>Transfer #</td>
                            <td>{this.props.location.state.transfernum}</td>
                        </tr>
                        <tr>
                            <td>Requesting WH</td>
                            <td>{this.props.location.state.requestingwh}</td>
                        </tr>
                        <tr>
                            <td>Requested Date</td>
                            <td>{rdate}</td>
                        </tr>
                        <tr>
                            <td>Requested by</td>
                            <td>{this.props.location.state.requestedby}</td>
                        </tr>
                        <tr>
                            <td>Fulfilled WH</td>
                            <td>{this.props.location.state.fulfillingwh}</td>
                        </tr>
                        <tr>
                            <td>Fulfilled Date</td>
                            <td>{fdate}</td>
                        </tr>
                        <tr>
                            <td>Fulfilled by</td>
                            <td>{this.props.location.state.fulfilledby}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td><strong>{status}</strong></td>
                        </tr>
                    </tbody>
                </Table>
        }
        let currency = "$";
        if (this.props.location.state.currencyCode) {
            if (this.props.location.state.currencyCode === "USD") {
                currency = "$";
            }
        }
        let rowEditable = false;
        if (this.props.location.state.status === 1 ) {
            rowEditable = true;
        }

        let canload = false;
        let canpick = false;
        let user = JSON.parse(localStorage.getItem('user'));

        if (user.config.inventoryLocation !== this.props.location.state.requestingwh && 
            (this.props.location.state.status === 2 || this.props.location.state.status === 3)) {
            canpick = true;
        }

        if (user.config.inventoryLocation === this.props.location.state.requestingwh &&
            this.props.location.state.status === 3) {
            canload = true;
        }

        console.log('canpick',canpick, user.config.inventoryLocation, this.props.location.state.requestingwh)
        return (
            <div>

                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Back</Button>
                { this.props.location.state.status === 1 &&
                    <>
                    <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.submitToFulfill() }}>Submit to Fulfill</Button>                                
                    <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goAddLineItem() }}>Add Item</Button>
                    </>
                }
                { (canpick === true && (this.props.location.state.status == 2 || this.props.location.state.status == 3)) &&
                    <>
                    <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goPickAndPack() }}>Pick &#38; Pack</Button>                    
                    </>
                }
                { canload && 
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goLoad() }}>Load</Button>
                }
                <div>&nbsp;</div>

                <MaterialTable key={this.state.count}
                    title={titl}
                    icons={tableIcons}
                    columns={this.state.columns}
                    data={this.state.data}

                    options={{
                        search: false,
                        paging: false,
                        actionsColumnIndex: -1,
                        exportButton: false,
                    }}

                    actions={[
                        {
                            icon: () => <ScannerIcon />,
                            tooltip: 'Scan Item',
                            isFreeAction: true,
                            onClick: () => {
                                if (this.props.location.state.status === 1) {
                                    this.scan();
                                } else {
                                    alert ('You can not edit a submitted transfer.')
                                }
                            }
                        }
                    ]}

                    editable={{
                        isEditable: rowData => rowEditable === true,
                        isDeletable: rowData => rowEditable === true,
                        onRowUpdate: (newData, oldData) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.qty = Number((parseFloat(newData.qty)).toFixed(2));
                                    newData.uom = newData.uom;

                                    const dataUpdate = [...this.state.data];
                                    // In dataUpdate, find target
                                    const target = dataUpdate.find(
                                        (el) => el.id === oldData.id
                                    );
                                    const index = dataUpdate.indexOf(target);

                                    dataUpdate[index] = newData;

                                    this.setState({ data: dataUpdate });
                                    this.updateTransferLineItem(newData);
                                    resolve();
                                }, 100);
                            });
                        },
                        onRowDelete: (oldData) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    //console.log('oldData', oldData);
                                    const dataDelete = [...this.state.data];
                                    const target = dataDelete.find(
                                        (el) => el.id === oldData.id
                                    );

                                    const index = dataDelete.indexOf(target);

                                    dataDelete.splice(index, 1);

                                    this.setState({ data: dataDelete });
                                    this.deleteLineItem(oldData);
                                    resolve();
                                }, 100);
                            });
                        },
                    }}

                />
                <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
            </div>
        );
    }

    scan = () => {
        //console.log('Scan item is called');        
        this.setState({ showScanner: true });
    }

    closeScanner() {
        this.setState({ showScanner: false })
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        this.setState({ showScanner: false, sorder: decodedText });
        //console.log('decodedText', decodedText);
        this.addLineItem(decodedText);
    }

    addLineItem(itemCode) {
        let user = JSON.parse(localStorage.getItem('user'));
        //console.log(user);
        wmsService.getItemByItemCode(itemCode).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo) {
                console.log('pinfo', pinfo)
                let itm = this.addItemInTransfer(pinfo);
                this.updateTransferLineItem(itm);
            } else {
                alert("Item is not found in this warehouse")
            }
        });
    }

    addItemInTransfer(pinfo) {

        let user = JSON.parse(localStorage.getItem('user'));
        let item = {};
        item.transferid = this.props.location.state.id;
        item.itemId = pinfo.id;
        item.itemcode = pinfo.item;
        item.description = pinfo.description;
        item.qty = 1;
        item.uom = pinfo.unitOfMeasure;

        item.qtyfulfilled = 0;

        var BreakException = {};
        let found = false;
        try {
            this.state.data.forEach(function (itm) {
                if (itm.item === item.item) {
                    itm.qty = itm.qty + 1;
                    found = true;
                    item = itm;
                    throw BreakException;
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }

        if (!found) {
            this.state.data.push(item)
        }
        this.setState({ count: this.state.count + 1 });
        return item;
    }

    deleteLineItem = (row) => {
        wmsService.deleteTransferLineItem(row.id).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo && pinfo.rpcStatus != 0) {
                console.log('Transfer line item is updated')
            } else {
                alert("Transfer line item delete is failed!")
            }
        });
    }

    updateTransferLineItem = (row) => {
        wmsService.addOrUpdateTransferLineItem(row).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo && pinfo.rpcStatus != 0) {
                console.log('Transfer line item is updated')
            } else {
                alert("Transfer line item update is failed!")
            }
        });
    }

}
export default TransferView;