import React from 'react';
import "./../App.css";
import { Card, Button, InputGroup, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class PackingLabelPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            packing: null,    
            label: undefined,    
            printqty: 1,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.skip = this.skip.bind(this);        
    }

    componentDidMount() {         
        if (this.props.location.state) {
            console.log('this.state.packing', this.state.packing);
            this.setState({packing: this.props.location.state});
        }
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;  
        this.state[name] =  target.value;           
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log('print...');
    }
    
    skip =() => {
        console.log('skip', this.state)
        if ((this.state.packing && this.state.packing.tranfer === 0) ||
            (this.state.packing.packing && this.state.packing.packing.tranfer === 0)) {
            var { from } = { from: { pathname: "/transfermenu" } };  
            this.props.history.push(from, this.state);       
        } else {
            var { from } = { from: { pathname: "/outbound" } };  
            this.props.history.push(from, this.state);       
        }
    }

    render() {
        if (!this.state.packing) {
            return(<div></div>);
        }
        console.log(this.state);        
        let labels = [];
        labels.push(<option key={0}>Default</option>);
        labels.push(<option key={1}>Large</option>);
        labels.push(<option key={2}>Small</option>);

        let sorder = '';
        let lp = '';
        if (this.state.packing.packing) {
            sorder = this.state.packing.packing.sorder;
            lp = this.state.packing.packing.lp;
        } else if (this.state.packing.sorder) {
            sorder = this.state.packing.sorder.orderNum;
            lp = this.state.packing.lp;
        }

        return (
            
            <div>                
                <Card>
                    <Card.Header>Packing Label Print                        
                    </Card.Header>
                    <Card.Body>
                    <Card.Text>
                        <div className="font-weight-bold">Order: {sorder}</div>
                        <div className="font-weight-bold">License Plate: {lp}</div>                            
                    </Card.Text>
                                                     

                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                                <Form.Group as={Col} controlId="label" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Label</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.label} >
                                            {labels}                            
                                    </Form.Control>            
                                </Form.Group>
                         </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="printqty" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Label Qyantity</Form.Label>
                            <Form.Control required={true} placeholder="Scan or enter target license plate" defaultValue={this.state.printqty} />                                                                
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Print</Button>                                                      
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.skip()}}>Skip</Button>                          
                            
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default PackingLabelPrint;