import React from 'react';
import "./../App.css";
import { Card, Form, Button, Col} from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class PutAwayToBinAssignment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {    
            pallet: null,
            wmsitm: [],
            bin: '3-4-5',
            wmsempactivityreport: undefined,
            confirmbin: '3-4-5',
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.scan = this.scan.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.closeScanner = this.closeScanner.bind(this);
        this.changeBin = this.changeBin.bind(this);
        this.goPalletItems = this.goPalletItems.bind(this);
    }

    componentDidMount() {    
        this.setState({pallet: this.props.location.state.pallet, wmsitm: this.props.location.state.wmsitm});     
        this.state.wmsempactivityreport = this.props.location.state.wmsempactivityreport;
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(this.state.wmsitm);

        if (user.defaultbin) {
            console.log(user.defaultbin);
            this.setState({bin: user.defaultbin})
            this.setState({confirmbin: user.defaultbin})
        }
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        //console.log('TEST', this.state);
        if (!this.state.wmsitm || !this.state.wmsitm.length) {
            alert("No items to putaway for this license plate");
            return;
        }
        //console.log(this.state.confirmbin);
        if (this.state.bin === this.state.confirmbin) {
            let user = JSON.parse(localStorage.getItem('user')); 
            //console.log('USER', user);
            let invloc = user.config.inventoryLocation;
            let wms = [];
            let srcorder = undefined;
            for (let i=0; i<this.state.wmsitm.length; i++) {
                let w = {};
                if (!srcorder) {
                    srcorder = this.state.wmsitm[i].srcorder;
                }
                w.invlocation = invloc;
                w.zone = 'XX';
                w.parentbin = this.state.confirmbin;
                w.bin = this.state.pallet;
                w.itemid = this.state.wmsitm[i].itemid;
                w.item = this.state.wmsitm[i].item;
                w.description = this.state.wmsitm[i].description;
                w.batch = this.state.wmsitm[i].batch;
                w.qty = this.state.wmsitm[i].qty;
                w.uom = this.state.wmsitm[i].uom;
                w.expirationdate = this.state.wmsitm[i].expirationdate;
                w.userwhoupdated = user.username;
                wms.push(w);
            }
            if (wms.length > 0) {
                let wmsAndReport = {};
                wmsAndReport.wms = wms;
                wmsAndReport.wmsempactivityreport = this.state.wmsempactivityreport;
                if (!this.state.wmsempactivityreport) {
                    let wmsempactivityreport = {};
                    wmsempactivityreport.invlocation = user.config.inventoryLocation;
                    wmsempactivityreport.wmsorder = srcorder;
                    wmsempactivityreport.employee = user.username
                    wmsempactivityreport.type = 5;
                    wmsempactivityreport.starttime = (new Date()).toISOString();
                    this.state.wmsempactivityreport = wmsempactivityreport;
                    wmsAndReport.wmsempactivityreport = this.state.wmsempactivityreport;
                }
                console.log('wmsAndReport', wmsAndReport);
                wmsService.addGoodsToBin(wmsAndReport, srcorder, this.state.pallet, invloc).then(witm => {
                    if (witm && witm === 401) {
                        this.props.history.push("/login");
                    } else {                    
                        console.log(witm)
                        if (witm.status === 'failed') {
                            alert('The goods assigned to a bin oprtation failed!');
                        } else {
                            if (this.props.location.state.pagefrom && this.props.location.state.pagefrom == 'palletitems') {
                                //
                                let user = JSON.parse(localStorage.getItem('user'));     
                                let wmsempactivityreport = {};
                                wmsempactivityreport.invlocation = user.config.inventoryLocation;
                                wmsempactivityreport.wmsorder = witm[0].srcorder;
                                wmsempactivityreport.employee = user.username
                                wmsempactivityreport.type = 5;
                                wmsempactivityreport.starttime = (new Date()).toISOString();   

                                wmsService.getActiveWmsByLicense(this.state.pallet, user.config.inventoryLocation).then(witm => {
                                    if (witm && witm === 401) {
                                        this.props.history.push("/login");
                                    } else if (witm && witm.length > 0) {
                                        //console.log('got date from getActiveWmsByPallete...', witm);
                                        
                                        
                                        this.state.wmsempactivityreport = wmsempactivityreport;
                                        /*
                                        if (witm.length == 1) {
                                            console.log('go to assignbin');
                                            this.state.wmsItems = witm;
                                            var { from } = { from: { pathname: "/assingtobin" } };  
                                        } else {
                                            console.log('go to palletitems...');
                                            this.state.wmsitm = witm;
                                            var { from } = { from: { pathname: "/palletitems" } };  
                                        }*/
                                        this.state.lp = this.state.pallet
                                        this.state.wmsitm = witm;
                                        this.state.po = this.props.location.state.po; 
                                        var { from } = { from: { pathname: "/palletitems" } };  
                                        this.props.history.push(from, this.state);                      
                                    } else {
                                        //alert('Pallet ' + this.state.pallet + ' is not found! Please enter another Pallet.');
                                        let obj = {};
                                        obj.purchaseorder = this.props.location.state.po.poNum;    
                                        obj.lp = this.props.location.state.lp;             
                                        obj.wmsempactivityreport = wmsempactivityreport;
                                        this.props.history.push("/receiveitms" , obj);
                                    }
                                });
                            } else {
                                this.props.history.goBack();
                            }
                        }
                    }
                });
            }
        } else {
            alert ('Auto assigned bin and confirm bin numbers are not matched.');
        }
    }

    changeBin =() => {
        //
        var { from } = { from: { pathname: "/assignnewbin" } };  
        this.props.history.push(from, this.state);    
    }

    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }


    goPalletItems =() => {        
        var { from } = { from: { pathname: "/palletitems" } };  
        this.props.history.push(from, this.state);    
    }

    render() {        
        console.log(this.props);
        let itm = '';
        if (this.state.wmsitm && this.state.wmsitm.length > 0) {
            itm = this.state.wmsitm[0].item + ' - ' + this.state.wmsitm[0].description
        }

        return (
            
            <div>                
                <Card>
                    <Card.Header>Putaway Goods                        
                    </Card.Header>
                    <Card.Body>
                    <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;License Plate:<Button variant="link" onClick={() => {this.goPalletItems()}}>{this.state.pallet} </Button></Form.Label>
                    </Form.Row> 
                    <Form.Row>
                        <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Item: {itm}</Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Auto Bin Assignment: {this.state.bin} &nbsp;&nbsp;&nbsp;&nbsp;<Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.changeBin()}}>Change</Button></Form.Label>
                    </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="confirmbin" 
                                onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Confirm Bin</Form.Label>
                            <Form.Control required={true} placeholder="Scan or enter bin number" value={this.state.confirmbin} autoFocus />
                            </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                        </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, bin: decodedText, confirmbin: decodedText}); 
    } 
}
export default PutAwayToBinAssignment;