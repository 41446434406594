import React from 'react';
import "./../App.css";
import {Box, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
//import Box from '@mui/material/Box';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
import Cycle from '@material-ui/icons/EventNote';
import CycleBlind from '@material-ui/icons/FormatListNumberedRtl';
import Physical from '@material-ui/icons/DirectionsWalk';
import BlurLinear from '@material-ui/icons/BlurLinear';
import { Link } from 'react-router-dom'


class Operation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
        };

    }

    handleChange(e) {
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { location: { pathname } } = this.props
        return (
            
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>                    
                    <ListItem  button component={Link} to="/oprinventory" selected={'/oprinventory' === pathname}>                        
                        <ListItemIcon>
                            <Cycle />
                        </ListItemIcon>
                        <ListItemText primary="WMS Inventory" />                        
                    </ListItem>

                    <ListItem  button component={Link} to="/oprinboundstatus" selected={'/oprinboundstatus' === pathname}>                        
                        <ListItemIcon>
                            <Cycle />
                        </ListItemIcon>
                        <ListItemText primary="Inbound Orders" />                        
                    </ListItem>
                    <ListItem  button component={Link} to="/opractoutbord" selected={'/opractoutbord' === pathname}>
                        
                        <ListItemIcon>
                            <CycleBlind />
                        </ListItemIcon>
                        <ListItemText primary="Outbound Orders to Pick" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/oprpckstatus" selected={'/opractoutbord' === pathname}>
                        
                        <ListItemIcon>
                            <CycleBlind />
                        </ListItemIcon>
                        <ListItemText primary="Picked Order Status" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/oprpaknotship" selected={'/oprpaknotship' === pathname}>
                        
                        <ListItemIcon>
                            <CycleBlind />
                        </ListItemIcon>
                        <ListItemText primary="Packed Orders - Not Shipped" />
                        
                    </ListItem>
                    
                    <ListItem  button component={Link} to="/oprtnpend" selected={'/oprtnpend' === pathname}>
                        
                        <ListItemIcon>
                            <BlurLinear />
                        </ListItemIcon>
                        <ListItemText primary="Pending Counting Approvals" />
                        
                    </ListItem>
                    
                    <ListItem  button component={Link} to="/oprtnapprv" selected={'/oprtnapprv' === pathname}>
                        
                        <ListItemIcon>
                            <Physical />
                        </ListItemIcon>
                        <ListItemText primary="Approved Countings" />
                        
                    </ListItem>                    
                    </List>
                </nav>
            </Box>      
        );
    }
}
export default Operation;