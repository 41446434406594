import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class Shipping extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            sorder: null,        
            pallet: null,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user')); 
        let wmsempactivityreport = {};
        wmsempactivityreport.invlocation = user.config.inventoryLocation;
        wmsempactivityreport.wmsorder = this.state.sorder;
        wmsempactivityreport.employee = user.username
        wmsempactivityreport.type = 3;
        wmsempactivityreport.starttime = (new Date()).toISOString();

        console.log('wmsempactivityreport', wmsempactivityreport);

        if (this.state.sorder) {
            wmsService.getShipReadyPackedItemsByOrder(this.state.sorder, user.config.inventoryLocation).then(pord => {
                console.log('pord', pord);
                if (pord && pord === 401) {
                    this.props.history.push("/login");
                } else {
                    if (pord && pord.length > 0) {
                        pord[0].wmsempactivityreport = wmsempactivityreport;
                        var { from } = this.props.location.state || { from: { pathname: "/shipitems" } };  
                        this.props.history.push(from, pord[0]);
                    } 
                }
            });
        } else if (this.state.pallet) {
            wmsService.getShipReadyPackedItemsByPallet(this.state.pallet, user.config.inventoryLocation).then(pord => {
                console.log(pord);
                if (pord && pord === 401) {
                    this.props.history.push("/login");
                } else {
                    if (pord && pord.length > 0) {
                        pord[0].wmsempactivityreport = wmsempactivityreport;
                        var { from } = this.props.location.state || { from: { pathname: "/shipitems" } };  
                        this.props.history.push(from, pord[0]);
                    } else {
                        alert ("This order does not have packed items");
                    }
                }
            });
        } else {
            alert("Please enter either order or pallet number");
        }              
    }
    
    goBack =() => {
        var { from } = { from: { pathname: "/outbound" } };  
        this.props.history.push(from, this.state);
    }

    render() {
        return (
            
            <div>                
                <Card>
                    <Card.Header>Shipping
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="sorder" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Order #</Form.Label>
                            <Form.Control placeholder="Scan or enter sales order number" defaultValue={this.state.sorder} />                                                                
                           </Form.Group>
                        </Form.Row> 

                        <Form.Row>
                          <Form.Group as={Col} controlId="pallet" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>License Plate or Pallet #</Form.Label>
                            <Form.Control  placeholder="Enter license plate or pallet" defaultValue={this.state.pallet} />                                                                
                           </Form.Group>
                        </Form.Row> 

                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                                                      
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan Order</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        this.setState({showScanner: false, sorder: decodedText}); 
    } 
}
export default Shipping;