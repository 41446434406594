import React, { forwardRef } from "react";
import { Button } from 'react-bootstrap';
import "./../App.css";
import { wmsService } from "../services/wmsService";
import { invoiceService } from "../services/invoiceService";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import Transfer from '@material-ui/icons/LocalShipping';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import { TramOutlined } from "@material-ui/icons";
import ScannerIcon from '@material-ui/icons/GradientOutlined';

import Scanner from "./Scanner";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ScannerIcon: forwardRef((props, ref) => <ScannerIcon {...props} ref={ref} />),
};

class TransferListReadyToLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            scanitem: null,
            showScanner: false,
            reload: 0,
            selectedrows: undefined,
            data: [],
            totalCount: 0,
            page: 0,
            datarowCount: 0,
            columns: [
                {
                    field: 'transfernum',
                    title: 'Transfer #',
                    editable: false,
                },
                {
                    field: 'requestingwh',
                    title: 'Requesting WH',
                    editable: false,
                },
                {
                    field: 'requesteddate',
                    title: 'Requested Date',
                    render: rowData => { if (rowData.requesteddate) { return new Date(rowData.requesteddate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) } },
                    editable: false,
                },
                {
                    field: 'status',
                    title: 'State',
                    editable: false,
                    render: rowData => { if (rowData.status == 1) { return "Draft"}
                        else if (rowData.status == 2) { return "Pending"}
                        else if (rowData.status == 3) { return "In Process"}
                        else if (rowData.status == 4) { return "Loaded"}
                        else { return "Ready to load"}
                    },
                },
                {
                    field: 'fulfillingwh',
                    title: 'Fulfilling WH',
                    editable: false,
                },
                {
                    field: 'fulfillingdate',
                    title: 'Fulfilled Date',
                    render: rowData => { if (rowData.invdate) { return new Date(rowData.invdate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) } },
                    editable: false,
                },
                {
                    field: 'loadingdate',
                    title: 'Loading Date',
                    render: rowData => { if (rowData.invdate) { return new Date(rowData.invdate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) } },
                    editable: false,
                },                
            ],
        };
        this.goBack = this.goBack.bind(this);     
        this.createTransfer = this.createTransfer.bind(this);     
    }

    componentDidMount() {
        
    }

    goBack = () => {
        this.props.history.goBack();
    }  

    createTransfer = () => {        
        let user = JSON.parse(localStorage.getItem('user'));
        //console.log(user);
        let t = {};
        t.requestingwh = user.config.inventoryLocation;
        t.requesteddate = new Date();
        t.status = 1;
        t.requestedby = user.username;
        wmsService.createTransfer(t).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo ) {
                console.log('pinfo', pinfo[0])                
                this.setState({count: this.state.count + 1});               
            } 
        });        
    }

    render() {
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            <div>

                <MaterialTable key={this.state.count}
                    title="Ready to Load"
                    icons={tableIcons}
                    columns={this.state.columns}
                    //data={this.state.data}
                    data={query => (
                        new Promise((resolve, reject) => {
                            wmsService.getTransfersToLoad(query.page * query.pageSize, query.pageSize, user.config.inventoryLocation).then(result => {
                                if (result && result === 401) {
                                    this.props.history.push("/login");
                                } else {
                                    //console.log(result);
                                    this.setState({datarowCount: result.length});
                                    this.setState({data: result});
                                    resolve({
                                        data: result,
                                        page: 0,
                                        totalCount: result.length,
                                    });
                                }
                            })
                        })
                    )}

                    options={{
                        search: false,
                        paging: true,
                        actionsColumnIndex: -1,
                        pageSize: 20,
                        exportButton: false,
                        debounceInterval: 1500,
                    }}
                    //editable={this.state.editable}   
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Search'
                        },
                        body: {
                            editTooltip: 'View'
                        }
                    }}
                    actions={[
                        {
                            icon: () => <ViewColumn />,
                            tooltip: "View and Load",
                            onClick: (event, rowData) => {
                                if (!rowData.lineItem) {
                                    wmsService.getTransfer(rowData.id).then(result => {
                                        if (result && result === 401) {
                                            this.props.history.push("/login");
                                        } else {
                                            console.log(result);
                                            var { from } = this.props.location.state || { from: { pathname: "/transferview" } };
                                            this.props.history.push(from, result);                                            
                                        }
                                    })
                                } else {
                                    var { from } = this.props.location.state || { from: { pathname: "/transferview" } };
                                    this.props.history.push(from, rowData);
                                }
                            },
                        },    
                        {
                            icon: () => <ScannerIcon />,
                            tooltip: 'Scan License Plate to Load',
                            isFreeAction: true,
                            onClick: () => {
                                if (this.state.datarowCount > 0) {
                                    this.scan();
                                } else {
                                    alert ('No order is ready to load.')
                                }
                            }
                        }                    
                        /*{
                            icon: () => <AddBox />,
                            tooltip: 'Create Transfer',
                            isFreeAction: true,
                            onClick: () => {                                
                                this.createTransfer();
                            }
                        },    */                    
                    ]}
                    
                />
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Back</Button>
                <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
            </div>
        );
    }
    scan = () => {
        //console.log('Scan item is called');  
        if (!this.state.data || this.state.data.length <= 0) {
            alert ('No orders to load')
        } else {      
            this.setState({ showScanner: true });
        }
    }

    closeScanner = () => {
        this.setState({ showScanner: false })
    }

    onNewScanResult = (decodedText, decodedResult) => {
        // Handle the result here.
        this.setState({ showScanner: false, sorder: decodedText });
        console.log('decodedText', decodedText);   
        
        if (!this.state.data || this.state.data.length <= 0) {
            alert ('No orders to load')
        } else {
            let order = [];
            this.state.data.forEach(e => {
                order.push(e.transfernum);
            })
            
            wmsService.getShipReadyPackedItemsByPalletAndOrder(decodedText, order).then(pord => {
                if (pord && pord === 401) {
                    this.props.history.push("/login");
                } else if (pord ) {
                    console.log('pord', pord)  
                    if (pord && pord.length > 0) {                        
                        wmsService.getTransfer(pord[0].sorder).then(transfer => {
                            if (transfer && transfer === 401) {
                                this.props.history.push("/login");
                            } else if (transfer ) {
                                let user = JSON.parse(localStorage.getItem('user'));
                                let wmsempactivityreport = {};
                                wmsempactivityreport.invlocation = user.config.inventoryLocation;
                                wmsempactivityreport.wmsorder = pord[0].sorder;
                                wmsempactivityreport.employee = user.username
                                wmsempactivityreport.type = 1;
                                wmsempactivityreport.starttime = (new Date()).toISOString();
                                pord[0].wmsempactivityreport = wmsempactivityreport;
                                pord[0].fromtransfer = 0;
                                pord[0].transferitem = transfer.lineItem;
                                pord[0].transfer = transfer;
            
                                var { from } = { from: { pathname: "/shipitems" } };  
                                this.props.history.push(from, pord[0]);
                            }
                        })
                    } else {
                        //alert ("No packed items available to load.")
                    }                                           
                } 
            }); 
        }
    }
}
export default TransferListReadyToLoad;