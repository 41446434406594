import React, { forwardRef } from "react";
import { Button } from 'react-bootstrap';
import "./../App.css";
import { wmsService } from "../services/wmsService";
import { invoiceService } from "../services/invoiceService";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import { TramOutlined } from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class InvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: new Map(),
            scanitem: null,
            cycle: null,
            showScanner: false,
            reload: 0,
            selectedrows: undefined,
            warehouse: undefined,
            data: [],
            totalCount: 0,
            page: 0,
            columns: [
                {
                    field: 'invoiceId',
                    title: 'Invoice #',
                    editable: false,
                },
                {
                    field: 'clientName',
                    title: 'Customer',
                    editable: false,
                },
                {
                    field: 'invdate',
                    title: 'Date',
                    render: rowData => { if (rowData.invdate) { return new Date(rowData.invdate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) } },
                    editable: false,
                },
                {
                    field: 'amountInvoiced',
                    title: 'Amount',
                    editable: false,
                },
            ],
            /*
            editable: {            
              onRowUpdate:(updatedRow,oldRow)=>new Promise((resolve,reject)=>{
                const index=oldRow.tableData.id;
                const updatedRows=[...this.state.data]
                updatedRows[index]=updatedRow
                this.updateRow(updatedRow, oldRow.qty);                    
                resolve()                 
              }),                  
            },*/

        };
        this.goBack = this.goBack.bind(this);     
        this.updateRow = this.updateRow.bind(this);
    }

    componentDidMount() {
        //console.log('props.location.state', this.props.location.state);
        if (this.props.location.state) {
            this.setState({ cycle: this.props.location.state });
        }

        let user = JSON.parse(localStorage.getItem('user'));
        this.state.warehouse = user.config.inventoryLocation;
        // wmsService.getInboundActivityPage(this.state.warehouse , 0, 200).then(result => {
        //     if (result && result === 401) {             
        //         this.props.history.push("/login");  
        //     } else {S
        //         this.setState({data: result});
        //     }
        // });
    }

    updateRow(row, oldqty) {
        //console.log(row.qty, oldqty);
        let obj = {};
        obj.id = row.id;
        obj.itemid = row.itemid;
        obj.qty = row.qty;
        let user = JSON.parse(localStorage.getItem('user'));
        obj.warehouse = user.config.inventoryLocation;
        //find the total qty for the item in the warehouse
        wmsService.getTotalQty(user.config.inventoryLocation, row.item).then(iqty => {
            if (iqty && iqty === 401) {
                this.props.history.push("/login");
            } else if (iqty) {
                //console.log('iqty', iqty)
                if (!oldqty) {
                    oldqty = 0;
                }
                let newlyaddedqty = (row.qty - oldqty)
                if (iqty.length > 0) {
                    obj.adjustdmqty = iqty[0].totalqty + newlyaddedqty;
                } else {
                    obj.adjustdmqty = newlyaddedqty;
                }
                wmsService.updateWmsInventoryQty(obj).then(result => {
                    if (result && result === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.setState({ count: this.state.count + 1 });
                    }
                })
            }
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }  

    render() {
        return (
            <div>

                <MaterialTable key={this.state.count}
                    title="Invoices"
                    icons={tableIcons}
                    columns={this.state.columns}
                    //data={this.state.data}
                    data={query => (
                        new Promise((resolve, reject) => {
                            //console.log('query', query)
                            if (query.search && query.search.length > 0) {
                                invoiceService.searchInvByCustomer(query.page * query.pageSize, query.pageSize, query.search).then(result => {
                                    if (result && result === 401) {
                                        this.props.history.push("/login");
                                    } else {
                                        resolve({
                                            data: result.invoice,
                                            page: 0,
                                            totalCount: result.invoice.length,
                                        });
                                    }
                                })
                            } else {
                                invoiceService.getAllInv(query.page * query.pageSize, query.pageSize).then(result => {
                                    if (result && result === 401) {
                                        this.props.history.push("/login");
                                    } else {
                                        //console.log(result.invoice);
                                        resolve({
                                            data: result.invoice,
                                            page: 0,
                                            totalCount: result.invoice.length,
                                        });
                                    }
                                })
                            }
                        })
                    )}

                    options={{
                        search: true,
                        paging: true,
                        actionsColumnIndex: -1,
                        pageSize: 20,
                        exportButton: false,
                        debounceInterval: 1500,
                    }}
                    //editable={this.state.editable}   
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Customer name'
                        },
                        body: {
                            editTooltip: 'View'
                        }
                    }}
                    actions={[
                        {
                            icon: () => <ViewColumn />,
                            tooltip: "View",
                            onClick: (event, rowData) => {
                                if (!rowData.lineItem) {
                                    invoiceService.getInv(rowData.id).then(result => {
                                        if (result && result === 401) {
                                            this.props.history.push("/login");
                                        } else {
                                            //console.log(result);
                                            var { from } = this.props.location.state || { from: { pathname: "/InvoiceView" } };
                                            this.props.history.push(from, result);                                            
                                        }
                                    })
                                } else {
                                    var { from } = this.props.location.state || { from: { pathname: "/InvoiceView" } };
                                    this.props.history.push(from, rowData);
                                }
                            },
                        },
                        {
                            icon: () => <AddBox />,
                            tooltip: 'Create Invoice',
                            isFreeAction: true,
                            onClick: () => {
                                var { from } = this.props.location.state || { from: { pathname: "/invoicecreate" } };
                                this.props.history.push(from);
                            }
                        }
                    ]}
                    
                />
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Back</Button>
            </div>
        );
    }
}
export default InvoiceList;