import { authHeader } from './authHeader.js';
import Cookies from 'js-cookie'
import { wmsService } from './wmsService.js';

var config = require('./config.js');

export const userService = {
    login,
    logout
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'connection': 'keep-alive' },
        credentials: 'include',
        body: JSON.stringify({ username, password })
    };
    let user;
    //console.log('before fetching...');
    return fetch(config.app.url + `/api/login`, requestOptions)
        .then((response)=> { 
            //console.log(response);
            //console.log(JSON.stringify(response.headers));
            //console.log((document.cookie).substring(12));//connect.sid=
            //console.log('start...');
            //console.log(document.cookie['connect.sid'])
            //console.log(response.headers['Set-Cookie'])
            //console.log(response.headers['Connection'])
            //console.log(response.body);
            //console.log(response.headers["set-cookie"][0]);
            //console.log(response.headers['x-access-token']);
            
            Cookies.set('access_token', (document.cookie).substring(12))
            return response.json() 
        })
        .then((json)=> 
        { 
            console.log(json);
            let token =  json.data.token;
            user = {};    
            user.username = username;
            user.peopleid = json.data.peopleid;
            user.companyid = json.data.companyid;
            user.companyName = json.data.companyName;
            user.authdata = token;
            if (json.data.warehouselocations && !json.data.inventoryLocation) {
                if (json.data.warehouselocations.length == 1) {
                    json.data.inventoryLocation = json.data.warehouselocations[0];
                }
            }
            user.config = json.data;
            user.cachetouch = Math.floor(Date.now() / 1000);
            if (json.data.warehouse && typeof(json.data.warehouse) === 'string') {
                user.config.inventoryLocation = json.data.warehouse;
            } else if (json.data.wmswarehouse && json.data.wmswarehouse.warehouse) {
                user.config.inventoryLocation = json.data.wmswarehouse.warehouse;
            }
            localStorage.setItem('user', JSON.stringify(user));            
            return user;                           
            //console.log(user.authdata);
            
        })                
}

function logout() {
    // remove user from local storage to log user out
    //console.log('removing user in logout....')
    localStorage.removeItem('user');
}

