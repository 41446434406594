import React from 'react';
import "./../App.css";
import { Card, ListGroup, Badge, Form, Button} from 'react-bootstrap';
import { Divider } from '@material-ui/core';
import { utcToLocal } from "../services/utility"


class SearchItemFound extends React.Component {
    constructor(props) {
        super(props);
        console.log('ReceiveItems', props);
        //console.log('props.location.state', props.location.state);
        this.state = {    
            item: null,
            desc: null,
            searchitems: null,
            

            isputaway: false,
            wmsempactivityreport: undefined,
            showScanner: false,
        };
 
        this.goBack = this.goBack.bind(this);
        this.soIsRecievable = this.soIsRecievable.bind(this)
        this.pickSo = this.pickSo.bind(this)
    }

    componentDidMount() {         
        if (this.props.location.state && this.props.location.state.itemcode) {
            this.setState({itemcode: this.props.location.state.itemcode, 
                // desc: this.props.location.state.desc, 
                searchitems: this.props.location.state.searchitem})
        }
    }
    

    
    goBack =() => {
        //console.log(this.props);
        this.props.history.goBack();
    }

    soIsRecievable = (orderQty, rcvedQty, cancelledQty, status) => {
        if (status && (status == 7 || status == 14 || status == 22)) {
            if (orderQty && orderQty > 0) {
                if (!rcvedQty) {
                    rcvedQty = 0
                }
                if (!cancelledQty) {
                    cancelledQty = 0
                }
                if ((orderQty - cancelledQty) > rcvedQty) {
                    return true
                }
            } 
        }
        return false;
    }

    pickSo = (i) => {
        var { from } = { from: { pathname: "/picking/" + i } };  
        this.props.history.push(from, this.state);
    }

    render() {
        console.log('SearchItemFound..................', this.state);
        let ritms = (<ListGroup as="ol" numbered="true"></ListGroup>);
        let desc = ''
        if (this.state.searchitems && this.state.searchitems.length > 0) {
            let itms = [];
            for (let ii=0; ii<this.state.searchitems.length; ii++) {
                let i = this.state.searchitems[ii];
                if (i.description) {
                    desc = i.description
                }
                itms.push(<ListGroup.Item key={ii.toString()}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div>Purchase Order #: <span className="font-weight-bold">{i.po_num}</span></div>   
                        <div>PO Date:          <span className="font-weight-bold">{i.po_create_date ? utcToLocal(i.po_create_date) : ''}</span></div> 
                        <div>Vendor: <span className="font-weight-bold">{i.client_name}</span></div> 
                        <div>Qty Ordered: <span className="font-weight-bold">{i.qty}</span></div> 
                        <div>Qty Received: <span className="font-weight-bold">{i.qty_received ? i.qty_received : 0}</span></div> 
                        <div>Qty Returned: <span className="font-weight-bold">{i.qty_returned ? i.qty_returned : 0}</span></div> 
                        <Divider />
                        <div>Sales Order #: <span className="font-weight-bold">{i.so_create_date ? i.so_num : ''}</span></div> 
                        <div>SO Date: <span className="font-weight-bold">{i.so_create_date ? utcToLocal(i.so_create_date) : ''}</span></div>  
                        <div>Customer: <span className="font-weight-bold">{i.so_client_name}</span></div>  
                        <div>SO Qty:  <span className="font-weight-bold">{i.so_qty ? i.so_qty : 0}</span></div>  
                        <div>Qty Shipped: <span className="font-weight-bold">{i.so_qty_shipped ? i.so_qty_shipped : 0}</span></div>   
                        <div>Qty Cancelled: <span className="font-weight-bold">{i.so_qty_cancelled ? i.so_qty_cancelled : 0}</span></div>                  
                        {i.so_qty &&  this.soIsRecievable(i.so_qty, i.so_qty_shipped, i.so_qty_cancelled, i.status) &&
                            <Button style={{minWidth : "80px",  marginRight: "auto" }} variant="outline-secondary" size="sm" type="button" onClick={() => {this.pickSo(i.so_num)}}>
                            Receive Item
                            </Button>
                        }
                    </div>       
                                                     
                </ListGroup.Item>);
              }
              ritms = (<ListGroup as="ol" numbered="true">
                  {itms}
              </ListGroup>);
        }
        // this.setState({desc: desc})
        return (
            
            <div>                
                <Card>
                    <Card.Header>Receiving Goods                        
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text><div className="font-weight-bold">{this.state.itemcode}: {desc}</div></Card.Text>
                            {ritms}                            

                            <div style={{float: "left"}} >                                  
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Back</Button>                              
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default SearchItemFound;