import React from 'react';
import "./../App.css";
import {Box, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
//import Box from '@mui/material/Box';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import SubdirectoryArrowLeftOutlined from '@material-ui/icons/SubdirectoryArrowLeftOutlined';
import MoreOutlined from '@material-ui/icons/MoreOutlined';
import { Link } from 'react-router-dom'


class Inbound extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
        };

    }

    handleChange(e) {
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { location: { pathname } } = this.props
        return (
            
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>
                    <ListItem  button component={Link} to="/receive" selected={'/receive' === pathname}>
                        
                        <ListItemIcon>
                            <MoveToInbox />
                        </ListItemIcon>
                        <ListItemText primary="Receive" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/putaway" selected={'/putaway' === pathname}>
                        
                        <ListItemIcon>
                            <SubdirectoryArrowLeftOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Putaway" />
                        
                    </ListItem>
                    {/*}
                    <ListItem  button component={Link} to="/lblprnt" selected={'/lblprnt' === pathname}>                    
                        <ListItemIcon>
                            <MoreOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Print Label" />
                        
                    </ListItem> */}                   
                    </List>
                </nav>
            </Box>      
        );
    }
}
export default Inbound;