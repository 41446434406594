import React from 'react';
import "./../App.css";
import { Card, ListGroup, Form, Button} from 'react-bootstrap';
import { purchaseService } from "../services/purchaseService"

class ReceivablePoSelection extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {    
            purchaseorder: [],
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.selectItem = this.selectItem.bind(this);
    }

    componentDidMount() {         
        purchaseService.getReceiveReadyPos().then(pos => {
            //console.log(pos);
            if (pos && pos === 401) {
                this.props.history.push("/login");
            } else {
                this.setState({purchaseorder: pos})
            }
        });
    }
    

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

  
    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }

    selectItem = (e) => {
        //console.log('Enter item qty', e);
        let poselect = {};
        poselect.po = e;
        this.props.history.push("/receive", poselect);         
    }

 
    render() {
        //console.log('ReceivablePoSelection', this.state.purchaseorder);
        let ritms = (<ListGroup as="ol" numbered="true"></ListGroup>);
        //console.log(this.state.purchaseorder.purchases)
        if (this.state.purchaseorder && this.state.purchaseorder.purchases && this.state.purchaseorder.purchases.length > 0) {            
            let itms = [];
            for (let ii=0; ii<this.state.purchaseorder.purchases.length; ii++) {
                let i = this.state.purchaseorder.purchases[ii];
                //console.log(i.poNum, i.clientName);
                itms.push(<ListGroup.Item key={ii.toString()}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                    <div className="font-weight-bold">{i.poNum}</div>
                    {i.clientName}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button style={{minWidth : "80px",  marginRight: "auto" }} variant="outline-secondary" size="sm" type="button" onClick={() => {this.selectItem(i.poNum)}}>
                                Select
                            </Button>
                        &nbsp;&nbsp;</div>                        
                    </div>
                </ListGroup.Item>);
            }
            ritms = (<ListGroup as="ol" numbered="true">
                {itms}
             </ListGroup>);
        }
        //console.log(ritms);
        return (
            
            <div>                
                <Card>
                    <Card.Header>Purchase Order Lookup                        
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Card.Text><div className="font-weight-bold">Select one</div></Card.Text>                            
                            {ritms}                            
                            <div style={{float: "left"}} >                                                                
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Back</Button>                              
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
}
export default ReceivablePoSelection;