import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const customerService = {
    getCustomerList,
    getBatchCustomerList,
    getCustomer,
    addNewCustomer,
    updateCustomer,
    deleteCustomer,
    getAddressList,
    getAddress,
    updateAddress,
    addAddress,
    getVendorList,
    addNewVendor,
    getJobList,
    addJob,
    searchCustomer,
};

function searchCustomer(start, count, name) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/clientsearchbyname/' + start + '/batchcount/' + count + '/search/' + name , requestOptions).then(handleResponse);
}

function getJobList(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/jobs/` + id, requestOptions).then(handleResponse);
}

function addJob(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/job' , requestOptions).then(handleResponse);
}

function getVendorList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/vendor`, requestOptions).then(handleResponse);
}

function getCustomerList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/client`, requestOptions).then(handleResponse);
}

function getBatchCustomerList(start, count) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/client/` + start + '/batchcount/' + count, requestOptions).then(handleResponse);
}

function getAddressList(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/clientaddress/` + id, requestOptions).then(handleResponse);
}

function getAddress(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + `/api/address/` + id, requestOptions).then(handleResponse);
}

function updateAddress(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/clientaddress/' + resource.id , requestOptions).then(handleResponse);
}

function addAddress(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/clientaddress' , requestOptions).then(handleResponse);
}

function getCustomer(resourceid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/clientwithoutaddress/' + resourceid , requestOptions).then(handleResponse);
}

function addNewVendor(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)   
    };
    return fetch(config.app.url + '/api/vendor/' , requestOptions).then(handleResponse);
}

function addNewCustomer(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)   
    };
    return fetch(config.app.url + '/api/client/' , requestOptions).then(handleResponse);
}

function updateCustomer(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/client/' + resource.id , requestOptions).then(handleResponse);
}

function deleteCustomer(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/clientsoftdelete/' + id , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}