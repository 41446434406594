import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import util from "../services/utility";

class CountCycleItemBatchUom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            batch: null,
            newbatch: null,             
            uom: null, 
            batchoptions: ['Select one'],
            batchrequired: false,
            expirationdate: undefined,
            itemobj: null,   
            blind: false,                
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);    
        this.isEmptyOrSpaces = this.isEmptyOrSpaces.bind(this);    
        
    }

    componentDidMount() {  
        if (this.props.location.state) {
            //this.setState({itemobj: this.props.location.state});
            this.state.itemobj = this.props.location.state;
            this.setState({item: this.state.itemobj.item, blind: this.state.itemobj.blind});
        }
        let user = JSON.parse(localStorage.getItem('user')); 
        if (user.config.wmsconfig && user.config.wmsconfig.batchmust === true) {
            this.setState({batchrequired: true});
        }

        wmsService.getAvailableBatchNumbersForItem(this.state.itemobj.item).then(pord => {
            console.log(pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else if (pord && pord.length > 0) {
                for (let p of pord) {
                    this.state.batchoptions.push(p.batch);
                }
                this.setState({batchoptions: this.state.batchoptions});                            
            }
        });  
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;           

        this.setState({
          [name]: target.value
        });   
      }

    isEmptyOrSpaces(str){
        return str === null || str.match(/^ *$/) !== null;
    }

    handleSubmit(e) {
        e.preventDefault();        

        //Checking user must not select 'Select One' from the dropdown
        if (this.state.batch === this.state.batchoptions[0]) {
            this.state.batch = undefined;
        }
        if (this.state.batchrequired === true) {
            if (!this.state.batch && !this.state.newbatch ) {
                alert ("Batch information is required")
                return;
            } else if (!this.state.batch && this.isEmptyOrSpaces(this.state.newbatch) ) {
                alert ("Batch information is required")
                return;
            } 
        }

        if (this.state.expirationdate) {
            let validate = util.isValidDate(this.state.expirationdate);
            if (!validate) {
                alert('Please enter date in MM/DD/YYY format');
                return;
            }
        }

        let user = JSON.parse(localStorage.getItem('user')); 
        if (user.config.wmsconfig && user.config.wmsconfig.expdatemust === true) {
            if (!this.state.expirationdate) {
                alert ("Expiration date is required")
                return;
            } 
        }

        if (this.state.expirationdate) {
            this.state.itemobj.expirationdate = this.state.expirationdate;
        }

        if (this.state.newbatch && !this.isEmptyOrSpaces(this.state.newbatch)) {
            this.state.itemobj.batch = this.state.newbatch;
        } else if (this.state.batch) {
            this.state.itemobj.batch = this.state.batch;
        }
        
        if (this.state.uom) {
            this.state.itemobj.uom = this.state.uom;
        }
        //console.log(this.state.itemobj.batch);
        var { from } = { from: { pathname: "/countcyitmbin" } };  
        this.props.history.push(from, this.state.itemobj);
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    render() {
        if (!this.state.itemobj) {
            return (<div></div>);
        }

        const opt = [];
        for (let p of this.state.batchoptions) {
            //console.log(p);
            opt.push(<option>{p}</option>)
        }

        let user = JSON.parse(localStorage.getItem('user')); 
        let batchdis = true;
        if (user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === true) {
            batchdis = false;
        }
        let expddis = true;
        if (user.config.wmsconfig && user.config.wmsconfig.expdatenotsupport === true) {
            expddis = false;
        }

        return (
            
            <div>                
                <Card>
                    <Card.Header>Count By Item - Batch and Expiration Date                      
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                            <div className="font-weight-bold">Item: {this.state.itemobj.item}</div>                            
                      </Card.Text> 
                      <Form onSubmit={this.handleSubmit}>
                        {batchdis &&
                        <Form.Row>                   
                            <Form.Group as={Col} controlId="batch" 
                                onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Select Existing Batch</Form.Label>
                            <Form.Control as="select" defaultValue={this.state.batch}>
                                    {opt}
                            </Form.Control> 
                            </Form.Group>
                        </Form.Row> 
                        }
                        {batchdis &&
                        <Form.Row>
                          <Form.Group as={Col} controlId="newbatch" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Enter New Batch</Form.Label>
                            <Form.Control  placeholder="Enter new batch" defaultValue={this.state.newbatch} />                                                                
                           </Form.Group>
                        </Form.Row> 
                        }
                        {expddis &&
                        <Form.Row>
                          <Form.Group as={Col} controlId="expirationdate" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Enter Expiration Date</Form.Label>
                            <Form.Control  placeholder="MM/DD/YYYY" defaultValue={this.state.expirationdate} />                                                                
                           </Form.Group>
                        </Form.Row>
                        }
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default CountCycleItemBatchUom;