import React from 'react';
import "./../App.css";
import { Card, CardDeck, Modal, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"

class SettingWmsApplication extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            config: undefined,
            batchnotsupport: false,
            batchoptional: false,
            batchmust: false,
            expdatenotsupport: false,
            expdateoptional: false,
            expdatemust: false,
            outboundorderscan: false,
            picktoship: false,
            defaulttax: false,
            tax: undefined,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handleBatchChecked = this.handleBatchChecked.bind(this);
        this.handleExpirationDateChecked = this.handleExpirationDateChecked.bind(this);

    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log('user', user)
        wmsService.getWmsConfig(user.config.inventoryLocation).then(witm => {
            if (witm && witm === 401) {
                this.props.history.push("/login");
            } else if (witm && witm.length > 0) {
                this.state.config = witm[0];
                if (this.state.config.configuration) {
                    this.state.config.configuration = JSON.parse(this.state.config.configuration);
                    console.log(this.state.config);
                    if (this.state.config.configuration.batchnotsupport) {
                        this.setState({ batchnotsupport: this.state.config.configuration.batchnotsupport });
                    }
                    if (this.state.config.configuration.batchoptional) {
                        this.setState({ batchoptional: this.state.config.configuration.batchoptional });
                    }
                    if (this.state.config.configuration.batchmust) {
                        this.setState({ batchmust: this.state.config.configuration.batchmust });
                    }

                    if (this.state.config.configuration.expdatenotsupport) {
                        this.setState({ expdatenotsupport: this.state.config.configuration.expdatenotsupport });
                    }
                    if (this.state.config.configuration.expdateoptional) {
                        this.setState({ expdateoptional: this.state.config.configuration.expdateoptional });
                    }
                    if (this.state.config.configuration.expdatemust) {
                        this.setState({ expdatemust: this.state.config.configuration.expdatemust });
                    }
                    if (this.state.config.configuration.outboundorderscan) {
                        this.setState({ outboundorderscan: this.state.config.configuration.outboundorderscan });
                    }
                    if (this.state.config.configuration.picktoship) {
                        this.setState({ picktoship: this.state.config.configuration.picktoship });
                    }                    
                    if (this.state.config.configuration.defaulttax) {
                        this.setState({ defaulttax: this.state.config.configuration.defaulttax });
                    }
                    if (this.state.config.configuration.tax) {
                        this.setState({ tax: this.state.config.configuration.tax });
                    }

                }
                //console.log(this.state);
            }
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.id;
        this.setState({
            [name]: target.value
        });
    }

    handleRadioChange = (event) => {
        const target = event.target;
        const name = target.id;
        this.setState({
            [name]: target.checked
        });
        console.log(name, target.checked)
    }

    handleBatchChecked = (e) => {
        this.state.batchnotsupport = false;
        this.state.batchoptional = false;
        this.state.batchmust = false
        this.setState({ [e.target.id]: e.target.checked });
        console.log('batch', e.target.id, e.target.checked, this.state.batchnotsupport, this.state.batchoptional, this.state.batchmust);
    }

    handleExpirationDateChecked = (e) => {
        this.state.expdatenotsupport = false;
        this.state.expdateoptional = false;
        this.state.expdatemust = false
        this.setState({ [e.target.id]: e.target.checked });
        console.log('expiration date', this.state.expdatenotsupport, this.state.expdateoptional, this.state.expdatemust);
    }


    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        if (!this.state.config) {
            this.state.config = {};
        }
        this.state.config.warehouse = user.config.inventoryLocation;
        if (!this.state.config.configuration) {
            this.state.config.configuration = {};
        }
        this.state.config.configuration.batchnotsupport = this.state.batchnotsupport;
        this.state.config.configuration.batchoptional = this.state.batchoptional;
        this.state.config.configuration.batchmust = this.state.batchmust;

        this.state.config.configuration.expdatenotsupport = this.state.expdatenotsupport;
        this.state.config.configuration.expdateoptional = this.state.expdateoptional;
        this.state.config.configuration.expdatemust = this.state.expdatemust;

        this.state.config.configuration.outboundorderscan = this.state.outboundorderscan;
        this.state.config.configuration.picktoship = this.state.picktoship;
        
        // this.state.config.configuration.defaulttax = this.state.defaulttax;

        // if (this.state.config.configuration.defaulttax === false) {
        //     this.state.tax = null;
        // }
        this.state.config.configuration.tax = this.state.tax;

        wmsService.updateWmsConfig(this.state.config).then(pord => {
            console.log(pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                wmsService.getWmsConfig(user.config.inventoryLocation).then(witm => {
                    if (witm && witm === 401) {
                        this.props.history.push("/login");
                    } else if (witm && witm.length > 0) {
                        this.state.config = witm[0];
                        if (this.state.config.configuration) {
                            this.state.config.configuration = JSON.parse(this.state.config.configuration);
                            user.config.wmsconfig = this.state.config.configuration;
                            localStorage.setItem('user', JSON.stringify(user));
                            console.log(this.state.config);
                            if (this.state.config.configuration.batchnotsupport) {
                                this.setState({ batchnotsupport: this.state.config.configuration.batchnotsupport });
                            }
                            if (this.state.config.configuration.batchoptional) {
                                this.setState({ batchoptional: this.state.config.configuration.batchoptional });
                            }
                            if (this.state.config.configuration.batchmust) {
                                this.setState({ batchmust: this.state.config.configuration.batchmust });
                            }

                            if (this.state.config.configuration.expdatenotsupport) {
                                this.setState({ expdatenotsupport: this.state.config.configuration.expdatenotsupport });
                            }
                            if (this.state.config.configuration.expdateoptional) {
                                this.setState({ expdateoptional: this.state.config.configuration.expdateoptional });
                            }
                            if (this.state.config.configuration.expdatemust) {
                                this.setState({ expdatemust: this.state.config.configuration.expdatemust });
                            }
                            if (this.state.config.configuration.tax) {
                                this.setState({ tax: this.state.config.configuration.tax });
                            }
                        }
                        console.log(this.state);
                        console.log(user);
                    }
                    alert('Saved');
                });
            }
        });
    }

    goBack = () => {
        var { from } = { from: { pathname: "/setting" } };
        this.props.history.push(from, this.state);
    }

    render() {
        let user = JSON.parse(localStorage.getItem('user'));
        let taxes = [];
        taxes.push(<option key={0}>Select...</option>);
        if (user.config.tax && user.config.tax.length > 0) {
            for (let i = 0; i < user.config.tax.length; i++) {   
                if (this.state.tax && this.state.tax === user.config.tax[i].name){
                    taxes.push(<option selected key={i}>{user.config.tax[i].name}</option>);
                } else {         
                    taxes.push(<option key={i}>{user.config.tax[i].name}</option>);
                }
                i++;                  
            }
        }        

        return (

            <div>
                <Card>
                    <Card.Header>Application Configurating
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <div key="batch">
                                <div className="font-weight-bold">Item Batch</div>
                                <Form.Group name="batch">
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="batchnotsupport" onChange={this.handleBatchChecked}>
                                            <Form.Check type="radio" name="batch" id="batchnotsupport" label="Batch is not supported"
                                                checked={this.state.batchnotsupport} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="batchoptional" onChange={this.handleBatchChecked}>
                                            <Form.Check type="radio" name="batch" id="batchoptional" label="Batch is optional"
                                                checked={this.state.batchoptional} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="batchmust" onChange={this.handleBatchChecked}>
                                            <Form.Check type="radio" name="batch" id="batchmust" label="Batch is must for all"
                                                checked={this.state.batchmust} />
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </div>
                            <div key="batchnotsupport">
                                <div className="font-weight-bold">Item Expiration Date</div>
                                <Form.Group name="expdate" >
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="expdatenotsupport" onChange={this.handleExpirationDateChecked}>
                                            <Form.Check type="radio" name="expdate" id="expdatenotsupport" label="Expiration date is not supported"
                                                checked={this.state.expdatenotsupport} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="expdateoptional" onChange={this.handleExpirationDateChecked}>
                                            <Form.Check type="radio" name="expdate" id="expdateoptional" label="Expiration date is optional"
                                                checked={this.state.expdateoptional} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="expdatemust" onChange={this.handleExpirationDateChecked}>
                                            <Form.Check type="radio" name="expdate" id="expdatemust" label="Expiration date is must for all"
                                                checked={this.state.expdatemust} />
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </div>
                            <div className="font-weight-bold">Outbound</div>
                            <Form.Group name="outbound" >
                                <Form.Row>
                                    <Form.Group as={Col} controlId="outboundorderscan" onChange={this.handleRadioChange}>
                                        <Form.Check type="checkbox" name="outbound" id="outboundorderscan" label="Scan the sales order"
                                            checked={this.state.outboundorderscan} />                                        
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="picktoship" onChange={this.handleRadioChange}>
                                        <Form.Check type="checkbox" name="picktoship" id="picktoship" label="Go to ship from picking items"
                                            checked={this.state.picktoship} />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>

                            <div className="font-weight-bold">Invoice</div>
                            <Form.Group name="outbound" >
                                {/* <Form.Row>
                                    <Form.Group as={Col} controlId="defaulttax" onChange={this.handleRadioChange}>
                                        <Form.Check type="checkbox" name="outbound" id="defaulttax" label="Use default tax for all invoice item"
                                            checked={this.state.defaulttax} />
                                    </Form.Group>
                                </Form.Row> */}
                                
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="tax" onChange={this.handleInputChange}>
                                            <Form.Label style={{ fontSize: "14px" }}>Select Default Tax</Form.Label>
                                            <Form.Control as="select" defaultValue={this.state.tax}>
                                                {taxes}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                

                            </Form.Group>

                            <div style={{ float: "left" }} >
                                <Button variant="outline-primary mr-3" size="sm" onClick={() => { this.goBack() }}>Back</Button>
                                <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

        );
    }
}
export default SettingWmsApplication;