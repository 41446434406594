import React from 'react';
import "./../App.css";
import { Card, ListGroup, Badge, Form, Button} from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
//import { ContactSupportOutlined } from '@material-ui/icons';
import Scanner from "./Scanner";

class ReceiveItems extends React.Component {
    constructor(props) {
        super(props);
        console.log('ReceiveItems', props);
        //console.log('props.location.state', props.location.state);
        this.state = {    
            lp: null,
            po: null,
            purchaseorder: null,
            items: null,
            lastLocation: null,
            wmsItems: [],
            reclineitem: [],
            isputaway: false,
            wmsempactivityreport: undefined,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.enterItem = this.enterItem.bind(this);
        this.deleteReceivedItem = this.deleteReceivedItem.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
        this.itemsToPost = this.itemsToPost.bind(this);
    }

    componentDidMount() {         
        if (this.props.location.state && this.props.location.state.lp) { //} && this.props.location.state.po) {
            //this.state.po = this.props.location.state.po;
            this.state.lp = this.props.location.state.lp;
            this.state.wmsempactivityreport = this.props.location.state.wmsempactivityreport;
            this.state.purchaseorder = this.props.location.state.purchaseorder;

            // Check whether there are already items to be putaway...

            let user = JSON.parse(localStorage.getItem('user'));
            
            if (user.config.inventoryLocation) {
                //no operation
            } else {
                alert("This user is not assigned to a right warehouse location. Set this using Setting menu!");
                return;
            }
            wmsService.getPo(this.state.purchaseorder).then(pord => {
                if (pord && pord === 401) {
                    this.props.history.push("/login");
                } else {
                    this.state.po = pord;                
                    this.getActiveWmsByLicense();                
                }
            });            
        }
    }
    
    /*
    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate...');
        if (prevProps.isFocused !== this.props.isFocused) {
            this.getActiveWmsByLicense(); 
        }
    }*/

    getActiveWmsByLicense() {
        console.log('getActiveWmsByLicense...');
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getActiveWmsByLicense(this.props.location.state.lp, user.config.inventoryLocation).then(witm => {
            if (witm && witm === 401) {
                this.props.history.push("/login");
            } else if (witm) {
                this.state.isputaway = true;
                if (this.state.items) {
                    console.log('ITEMS already set...');
                } else {
                    this.getReceivingLineItems();
                }    
            }
        });
    }

    getReceivingLineItems() { 
        console.log('getReceivingLineItems...');
        wmsService.getWmsActivityGroupByItem(this.state.purchaseorder, 1, this.state.po.id).then(witm => {
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else if (witm && witm.length > 0) {
                console.log('got date from getWmsActivityByOrderAndType...', witm);
                this.state.wmsItems = witm;
                this.setReceivingLineItems();                        
            } else {
                this.setReceivingLineItems();
            }
        });
    }

    setReceivingLineItems() {        
        console.log('setReceivingLineItems', this.state);
        let tmpItem = [];
        
        if (this.state.wmsItems && this.state.wmsItems.length > 0) {
            if (this.state.po.lineItem && this.state.po.lineItem.length > 0) {
                for (const x of this.state.po.lineItem) {
                    let witm = this.findItemInWms(x.itemId);
                    console.log('witm', witm, x.itemId);
                    if (witm) {
                        let itm = {};
                        itm.id = witm.id;   
                        itm.srcorder = witm.srcorder; 
                        itm.srcorderid = witm.srcorderid;  
                        itm.ordertype = witm.ordertype;   
                        itm.licenseplate = witm.licenseplate;   
                        itm.updateddate = witm.updateddate;   
                        itm.status = witm.status;   
                        itm.itemid = witm.itemid;   
                        itm.item = witm.item;   
                        itm.picked = witm.rcved; 
                        itm.rcved = witm.rcved;   
                        itm.rcvedposted = witm.rcvedposted;
                        itm.uom = witm.uom;
                        itm.description = witm.description;
                        let pitm = this.findItemInPo(itm.itemid);
                        if (pitm) {
                            itm.qty = x.qty;
                            itm.description = x.description;
                            if (x.qtyReceived) {
                                itm.rcvedposted = x.qtyReceived;
                                if (itm.rcved <  x.qtyReceived) { // if PO received qty more than WMS received qty then...
                                    itm.rcved = x.qtyReceived;
                                }
                            }
                        } else {
                            itm.qty = 0;
                        }  
                        tmpItem.push(itm);
                    } else {
                        let itm = {};
                        itm.srcorder = this.state.purchaseorder;  
                        itm.srcorderid =  this.state.po.id;
                        itm.ordertype = 1;   
                        itm.licenseplate = this.state.lp;       
                        itm.itemid = x.itemId;   
                        itm.item = x.item;  
                        itm.qty = x.qty;
                        itm.uom = x.mou;   
                        itm.picked = 0; 
                        itm.rcved = 0;
                        itm.rcvedposted = 0;
                        itm.description = x.description;
                        if (x.qtyReceived) {
                            itm.rcvedposted = x.qtyReceived;
                            if (itm.rcved <  x.qtyReceived) { // if PO received qty more than WMS received qty then...
                                itm.rcved = x.qtyReceived;
                            }
                        }
                        
                        itm.description = x.description;
                        tmpItem.push(itm);
                    }
                }
                /*
                for (const x of this.state.wmsItems) {
                    let itm = {};
                    itm.id = x.id;   
                    itm.srcorder = x.srcorder; 
                    itm.srcorderid = x.srcorderid;  
                    itm.ordertype = x.ordertype;   
                    itm.licenseplate = x.licenseplate;   
                    itm.updateddate = x.updateddate;   
                    itm.status = x.status;   
                    itm.itemid = x.itemid;   
                    itm.item = x.item;   
                    itm.picked = x.rcved; 
                    itm.rcved = x.rcved;   
                    itm.rcvedposted = x.rcvedposted;
                    itm.uom = x.uom;
                    itm.description = x.description;
                    let pitm = this.findItemInPo(itm.itemid);
                    if (pitm) {
                        itm.qty = pitm.qty;
                        itm.description = pitm.description;
                        if (pitm.qtyReceived) {
                            itm.rcvedposted = pitm.qtyReceived;
                            if (itm.rcved <  pitm.qtyReceived) { // if PO received qty more than WMS received qty then...
                                itm.rcved = pitm.qtyReceived;
                            }
                        }
                    } else {
                        itm.qty = 0;
                    }  
                    tmpItem.push(itm);                
                }
                this.setState({reclineitem: tmpItem});
                */
                this.setState({reclineitem: tmpItem});
            } 
        } else if (this.state.po.lineItem && this.state.po.lineItem.length > 0) {
            console.log('Here..... creating rcv items from PO items');
            for (const x of this.state.po.lineItem) {
                let itm = {};
                itm.srcorder = this.state.purchaseorder;  
                itm.srcorderid =  this.state.po.id;
                itm.ordertype = 1;   
                itm.licenseplate = this.state.lp;       
                itm.itemid = x.itemId;   
                itm.item = x.item;  
                itm.qty = x.qty;
                itm.uom = x.mou;   
                itm.picked = 0; 
                itm.rcved = 0;
                itm.rcvedposted = 0;
                itm.description = x.description;
                if (x.qtyReceived) {
                    itm.rcvedposted = x.qtyReceived;
                    if (itm.rcved <  x.qtyReceived) { // if PO received qty more than WMS received qty then...
                        itm.rcved = x.qtyReceived;
                    }
                }
                
                itm.description = x.description;
                tmpItem.push(itm);                
            }
            //console.log('tmpItem', tmpItem);
            this.setState({reclineitem: tmpItem});
        }
    }

    findItemInPo (itemId) {
        if (this.state.po.lineItem && this.state.po.lineItem.length > 0) {
            for (const itm of this.state.po.lineItem) {
                if (itm.itemId == itemId) {
                    return itm;
                }
            }
        }
        return null;
    }

    findItemInWms (itemId) {
        if (this.state.wmsItems && this.state.wmsItems.length > 0) {
            for (const itm of this.state.wmsItems) {
                if (itm.itemid == itemId) {
                    return itm;
                }
            }
        }
        return null;
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    itemsToPost() {
        let lineItems = [];
        console.log(this.state.reclineitem);
        for (const itm of this.state.reclineitem) {
            //if (itm.rcved > 0 && itm.rcved - itm.rcvedposted > 0) {
            if (itm.picked > 0) {   
                let pitm = this.findItemInPo(itm.itemid);
                let poqtyallowedtorcv = pitm.qty;
                if (pitm.qtyReceived) {
                    poqtyallowedtorcv = poqtyallowedtorcv - pitm.qtyReceived;
                }
                if (pitm.qtyCancelled) {
                    poqtyallowedtorcv = poqtyallowedtorcv - pitm.qtyCancelled;
                }
                if (pitm.qtyReturned) {
                    poqtyallowedtorcv = poqtyallowedtorcv - pitm.qtyReturned;
                }
                //if (t.getNewQtyReceived() > (litm.getQty() - (litm.getQtyReceived() != null ? litm.getQtyReceived() : 0) - (litm.getQtyCancelled() != null ? litm.getQtyCancelled() : 0) + (litm.getQtyReturned() != null ? litm.getQtyReturned() : 0 ))) {
                if (itm.picked > poqtyallowedtorcv) {
                    //alert('Purchase Order: You cannot post goods receipt for more qty then ordered for ' + pitm.item);
                    return false;
                }
                let poi = JSON.parse(JSON.stringify(pitm)); 
                //poi.newQtyReceived = itm.rcved - itm.rcvedposted;
                poi.newQtyReceived = itm.picked;
                lineItems.push(poi);
            }
        }
        if (lineItems.length == 0) {
            return false;
        }
        return true;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.lp) {
            return;
        }
        //console.log(this.state.lp);
        let porder = JSON.parse(JSON.stringify(this.state.po)); 
        let user = JSON.parse(localStorage.getItem('user')); 
        porder.deliveryLocation = user.config.inventoryLocation;        
        let lineItems = [];
        //console.log(this.state.reclineitem);
        for (const itm of this.state.reclineitem) {
            //if (itm.rcved > 0 && itm.rcved - itm.rcvedposted > 0) {
            if (itm.picked > 0) {   
                let pitm = this.findItemInPo(itm.itemid);
                let poqtyallowedtorcv = pitm.qty;
                if (pitm.qtyReceived) {
                    poqtyallowedtorcv = poqtyallowedtorcv - pitm.qtyReceived;
                }
                if (pitm.qtyCancelled) {
                    poqtyallowedtorcv = poqtyallowedtorcv - pitm.qtyCancelled;
                }
                if (pitm.qtyReturned) {
                    poqtyallowedtorcv = poqtyallowedtorcv + pitm.qtyReturned;
                }
                //if (t.getNewQtyReceived() > (litm.getQty() - (litm.getQtyReceived() != null ? litm.getQtyReceived() : 0) - (litm.getQtyCancelled() != null ? litm.getQtyCancelled() : 0) + (litm.getQtyReturned() != null ? litm.getQtyReturned() : 0 ))) {
                if (itm.picked > poqtyallowedtorcv) {
                    alert('Purchase Order: You cannot post goods receipt for more qty then ordered for ' + pitm.item);
                    return;
                }
                delete pitm.scheduledReceiveDate;
                let poi = JSON.parse(JSON.stringify(pitm)); 
                //poi.newQtyReceived = itm.rcved - itm.rcvedposted;
                poi.newQtyReceived = itm.picked;
                lineItems.push(poi);
            }
        }
        if (lineItems.length == 0) {
            alert ('No new receive items to post');
        } else {
            porder.lineItem = lineItems;
            //console.log(porder);        
            wmsService.postGoodReceipt(porder, this.state.lp).then(witm => {
                if (witm && witm === 401) {
                    this.props.history.push("/login");
                } else {                    
                    //console.log(witm)
                    if (witm.status == 'failed') {
                        alert('The goods receipt post oprtation failed!');
                    } else {
                        //this.props.history.goBack();
                        wmsService.getPo(this.state.purchaseorder).then(pord => {
                            //console.log(pord);
                            if (pord && pord === 401) {
                                this.props.history.push("/login");
                            } else {                                
                                this.props.location.state.po = pord;
                                //this.setState({po: pord})
                                this.putaway();                                 
                            }
                        });
                    }
                }
            });
        }
    }
    
    goBack =() => {
        //console.log(this.props);
        this.props.history.goBack();
    }

    enterItem = (e) => {
        //console.log('Enter item qty', e);
        //receiveitmqty
        //var { from } = { from: { pathname: "/receiveitmqty" } }; 
        if (!e.itemid) {
            alert("This item do not have item id. You can't receive this item.");
        } else if (Number(e.qty) > Number(e.rcved)) {
            e.status = 1;
            e.ordertype = 1;
            e.licenseplate = this.state.lp; 
            e.wmsempactivityreport = this.state.wmsempactivityreport;
            delete e.picked;
            this.props.history.push("/receiveitmqty", e);  
        }
    }

    deleteReceivedItem = (e) => {
        //console.log('deleteReceivedItem....', e);
        wmsService.deleteWmsActivities(e.item, e.itemid, this.state.purchaseorder, this.state.po.id).then(witm => {
            if (witm && witm === 401) {
                this.props.history.push("/login");
            } else {
                this.state.wmsItems = [];
                this.setState({reclineitem: []});
                this.getReceivingLineItems();
            }
        });
    }

    putaway = (e) => {
        
        //console.log(this.state.lp);
        // Check whether we have received items in this pallet. If not then alert, otherwise move to next screen
        let user = JSON.parse(localStorage.getItem('user'));                 
        wmsService.getActiveWmsByLicense(this.state.lp, user.config.inventoryLocation).then(witm => {
            if (witm && witm === 401) {
                this.props.history.push("/login");
            } else if (witm && witm.length > 0) {
                //console.log('got date from getActiveWmsByPallete...', witm);
                
                let wmsempactivityreport = {};
                wmsempactivityreport.invlocation = user.config.inventoryLocation;
                wmsempactivityreport.wmsorder = witm[0].srcorder;
                wmsempactivityreport.employee = user.username
                wmsempactivityreport.type = 5;
                wmsempactivityreport.starttime = (new Date()).toISOString();
                this.state.wmsempactivityreport = wmsempactivityreport;

                if (witm.length == 1) {
                    this.state.wmsItems = witm;
                    this.state.pallet = this.state.lp;
                    this.state.wmsitm = this.state.wmsItems;
                    var { from } = { from: { pathname: "/assingtobin" } };  
                } else {
                    this.state.wmsitm = witm;
                    var { from } = { from: { pathname: "/palletitems" } };  
                }
                this.props.history.push(from, this.state);                      
            } else {
                alert('License plate ' + this.state.lp + ' has no items to putaway!');
            }
        });
    }

    render() {
        console.log('ReceiveItems..................', this.state);
        let ritms = (<ListGroup as="ol" numbered="true"></ListGroup>);
        if (this.state.reclineitem && this.state.reclineitem.length > 0) {
            let itms = [];
            for (let ii=0; ii<this.state.reclineitem.length; ii++) {
                let i = this.state.reclineitem[ii];
                let qty = (i.qty - i.rcvedposted).toString();
                if (i.qty < 100) {
                    if (i.qty < 10) {
                        qty = '  ' + qty;
                    } else {
                        qty = ' ' + qty;
                    }
                }
                // let rcved = (i.rcved - i.rcvedposted).toString();
                // if (i.rcved < 100) {
                //     if (i.rcved < 10) {
                //         rcved = '  ' + rcved;
                //     } else {
                //         rcved = ' ' + rcved;
                //     }
                // }

                let rcved = i.picked.toString();
                if (rcved < 100) {
                    if (rcved < 10) {
                        rcved = '  ' + rcved;
                    } else {
                        rcved = ' ' + rcved;
                    }
                }

                qty = rcved + "/" + qty;
                itms.push(<ListGroup.Item key={ii.toString()}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                    <div className="font-weight-bold">{i.item}</div>
                    {i.description}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Button style={{minWidth : "80px",  marginRight: "auto" }} variant="outline-secondary" size="sm" type="button" onClick={() => {this.enterItem(i)}}>
                                {qty}
                            </Button>
                        &nbsp;&nbsp;</div>
                        {i.picked > 0 &&
                            <Button style={{ marginRight: "auto" }}variant="outline-secondary" size="sm" type="button" onClick={() => {this.deleteReceivedItem(i)}}>delete rcved</Button>
                        }
                    </div>
                </ListGroup.Item>);
              }
              ritms = (<ListGroup as="ol" numbered="true">
                  {itms}
              </ListGroup>);
        }
        console.log('POST', this.state.isputaway, this.itemsToPost());
        return (
            
            <div>                
                <Card>
                    <Card.Header>Receiving Goods                        
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text><div className="font-weight-bold">Purchase Order: {this.state.purchaseorder}</div></Card.Text>
                            <Card.Text><div className="font-weight-bold">License Plate: {this.state.lp}</div></Card.Text>
                            {ritms}                            

                            <div style={{float: "left"}} >    
                                {this.itemsToPost() &&                                    
                                    <Button variant="outline-primary mr-3 mt-3" size="sm" type="submit">Update PO</Button>  
                                }
                                {this.state.isputaway && this.itemsToPost() == false &&
                                    <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.putaway()}}>Putaway</Button>  
                                }                            
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.scan()}}>Scan Item</Button>                              
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Back</Button>                              
                            </div>
                        </Form>
                        <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        let e = {};
        let found = false;
        if (this.state.reclineitem && this.state.reclineitem.length > 0) {            
            for (let ii=0; ii<this.state.reclineitem.length; ii++) {
                let i = this.state.reclineitem[ii];
                if (i.item === decodedText) {
                    e = i;
                    found = true; 
                    break;
                }
            }
            if (!found) {
                alert('No item in the order matched with the scanned item!');
                return;
            }
        }

        if (Number(e.qty) > Number(e.rcved)) {
            //console.log('e',e);
            e.status = 1;
            e.ordertype = 1;
            e.licenseplate = this.state.lp; 
            e.wmsempactivityreport = this.state.wmsempactivityreport;
            delete e.picked;
            this.props.history.push("/receiveitmqty", e);  
        }

        this.setState({showScanner: false, purchaseorder: decodedText}); 
    } 
}
export default ReceiveItems;