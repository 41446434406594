import React from 'react';
import "./../App.css";
import { Card, Button, InputGroup, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class SearchItem extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props)
        this.state = {    
            itemcode: null,        
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
    }

    componentDidMount() {
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {        
        e.preventDefault();
        wmsService.searchItemInPoAndSo(this.state.itemcode).then(pord => {
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                if (pord.length > 0) {
                    console.log(pord)
                    this.setState({searchitem: pord});
                    this.props.history.push("/searchitemfound", this.state);
                } else {
                    alert('Item code ' + this.state.itemcode + 'is not found');
                    return;
                }
            }
        });        
    }

    scan =() => {
        console.log('Scan is called');
      }

    render() {
        return (
            
            <div>                
                <Card>
                    <Card.Header>Search Item                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="itemcode" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Item Code</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control required={true} placeholder="Scan or enter item code" defaultValue={this.state.itemcode} autoFocus />                                
                                <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.scan()}}>
                                    Scan
                                </Button>
                            </InputGroup>
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Search</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, itemcode: decodedText}); 
    } 
}
export default SearchItem;