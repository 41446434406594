import {Button} from 'react-bootstrap';

import React, { Component } from 'react';

export default class WarehouseExample extends Component {
  render() {
    return (
        <div>
            <div className="row">        
                    <img src="/bin-numbering.png" width="80%" style={{ alignSelf: 'center' }}/>              
            </div>
            <div className="d-grid">
                <Button variant="primary" size="sm" onClick={() => {this.props.history.goBack()}}>Back</Button>                                      
            </div>

      </div>
    );
  }
} 