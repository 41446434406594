import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import { ControlCameraOutlined } from '@material-ui/icons';

class SettingSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            warehouses: undefined,
            selected: undefined,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);                
    }

    componentDidMount() { 
        wmsService.getWarehouses().then(witm => {
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else if (witm && witm.location && witm.location.length > 0) {
                console.log(witm.location);
                this.setState({warehouses: witm.location});                
            }             
        });
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.selected) {
            alert ("Please select a warehouse.")
        } else {
            wmsService.getZones(this.state.selected).then(zz => {
                if (!zz || zz.length === 0) {
                    alert ('You must define zones before creating storage section');
                } else {                                   
                    for (let w of this.state.warehouses) {
                        if (w.locationName === this.state.selected) {
                            //console.log(w);
                            w.zones = zz;  
                            var { from } =  { from: { pathname: "/ssectionedit" } };  
                            this.props.history.push(from, w);
                            break;
                        }
                    }

                }                
            });            
        }
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    render() {        
        if (!this.state.warehouses) {
            return (<div></div>);
        }

        let wh = [];
        wh.push(<option key={0}>Select...</option>);
        if (this.state.warehouses && this.state.warehouses.length > 0) {
            let i =1;
            for (let x of this.state.warehouses) {
                wh.push(<option key={i}>{x.locationName}</option>);
                i++;
            }            
        }

        return (
            
            <div>                
                <Card>
                    <Card.Header>Storage Section Setting                         
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                      <Form.Row>
                            <Form.Group as={Col} controlId="selected" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Warehouse</Form.Label>
                                <Form.Control required={true} as="select" defaultValue={this.state.selected} >
                                        {wh}                            
                                </Form.Control>            
                            </Form.Group>
                        </Form.Row>
                        
                        <div style={{float: "left"}} >                                                               
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                                                      
                  </div>
                      </Form>                      
                    </Card.Body>
                </Card>
            </div>
            
        );
    } 
}
export default SettingSection;