import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import util from "../services/utility";
import { BorderBottom } from '@material-ui/icons';

class ReceiveEnterItemAndQuantity extends React.Component {
    constructor(props) {
        super(props);
        //console.log('ReceiveEnterItemAndQuantity', props);
        this.state = {    
            item: {},   
            qty: 0,
            batch: undefined, 
            newbatch: undefined,
            batchoptions: [{batch: 'Select one', expirationdate: undefined}],
            batchrequired: false,
            expirationdate: undefined,                
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.isEmptyOrSpaces = this.isEmptyOrSpaces.bind(this);    
    }

    componentDidMount() { 
        if (this.props.location.state) {
            this.state.item = this.props.location.state;
            this.setState({item: this.props.location.state});
        }    
                
        if (this.state.item && this.state.item.item) {
            wmsService.getAvailableBatchNumbersForItem(this.state.item.item).then(pord => {
                //console.log(pord);
                if (pord && pord === 401) {
                    this.props.history.push("/login");
                } else if (pord && pord.length > 0) {
                    for (let p of pord) {
                        this.state.batchoptions.push(p);
                    }
                    this.setState({batchoptions: this.state.batchoptions});
                }
            }); 
        }

        let user = JSON.parse(localStorage.getItem('user')); 
        //console.log('user', user)
        if (user.config.wmsconfig && user.config.wmsconfig.batchmust === true) {
            this.setState({batchrequired: true});
        }
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
    }

    isEmptyOrSpaces(str){
        return str === null || str.match(/^ *$/) !== null;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.item && this.state.item.item) {
            //console.log(this.state.qty);
            if (!this.state.qty || Number(this.state.qty) === 0) {
                alert("Please enter quantity");
                return;
            }

            //Checking user must not select 'Select One' from the dropdown
            if (this.state.batch === this.state.batchoptions[0].batch) {
                this.state.batch = undefined;
            }
            if (this.state.batchrequired === true) {
                if (!this.state.batch && !this.state.newbatch ) {
                    alert ("Batch information is required")
                    return;
                } else if (!this.state.batch && this.isEmptyOrSpaces(this.state.newbatch) ) {
                    alert ("Batch information is required")
                    return;
                } 
            }

            let user = JSON.parse(localStorage.getItem('user'));            

            let qty = Number(this.state.qty);
            if ((Number(this.state.item.qty) < qty + Number(this.state.item.rcved))) {
                alert('You cannot receive more than ordered');
            }
            else if (qty > 0) {    
                 
                this.state.item.invlocation = user.config.inventoryLocation;
                this.state.item.userwhoupdated = user.username;
                this.state.item.rcved = Number(this.state.qty);

                if (this.state.newbatch && !this.isEmptyOrSpaces(this.state.newbatch)) {
                    this.state.item.batch = this.state.newbatch;
                } else if (this.state.batch) {
                    let ed = this.state.batch.split(' - ');
                    if (ed[0] === 'NA') {
                        ed[0] = '0';
                    }
                    this.state.item.batch = ed[0];
                    for (let b of this.state.batchoptions) {                        
                        //console.log(ed.length, b.batch, ed[0], ed, ed[ed.length-1]);

                        if (ed.length > 1 && b.batch === ed[0]) {
                            this.state.expirationdate = ed[ed.length-1];
                            break;
                        }
                    }
                }
                //console.log('this.state.expirationdate',this.state.expirationdate);
                if (user.config.wmsconfig && user.config.wmsconfig.expdatemust === true) {
                    if (!this.state.expirationdate) {
                        alert ("Expiration date is required")
                        return;
                    } 
                }

                if (this.state.expirationdate) {
                    if (!util.isValidDate(this.state.expirationdate)) {
                        alert('Please enter date in MM/DD/YYY format');
                        return;
                    } else {
                        this.state.item.expirationdate = this.state.expirationdate;
                    }
                }
                
                //this.state.item.uom = this.state.item.mou;
                this.state.item.updateddate = new Date(); //String(Math.floor(Date.now() / 1000));
                delete this.state.item.qty;
                //delete this.state.item.mou;
                delete this.state.item.id; //every entry will be new record in the database...
                delete this.state.item.rcvedposted;

                //console.log('ITEM', this.state.item);
                wmsService.addOrUpdateWmsActivity(this.state.item).then(witm => {
                    if (witm && witm === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.props.history.goBack();
                    }
                });            
            }
        }
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    scan =() => {
        //console.log('Scan is called');
      }

    render() {
        console.log('ITEM', this.state.item);                        
        const opt = [];
        for (let p of this.state.batchoptions) {
            //console.log(p);
            let expd = undefined;
            if (p.expirationdate) {
                let date = new Date(p.expirationdate);
                expd = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
            }
            let k = (p.batch === '0' ? 'NA' : p.batch);
            if (expd) {
                k = k + ' - ' + expd;
            }
            opt.push(<option value={k.batch}>{k}</option>)            
        }
        //console.log(opt);;
        let user = JSON.parse(localStorage.getItem('user')); 
        return (
            
            <div>                
                <Card>
                    <Card.Header>Receiving Goods                        
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Item Code: {this.state.item.item}</Form.Label>

                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="qty" 
                                    onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Quantity Receiving</Form.Label>
                                <Form.Control required={true} placeholder="Enter quantity receiving" defaultValue={this.state.qty} />
                                </Form.Group>
                            </Form.Row> 
                            {(user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false ) &&
                            <Form.Row>                   
                                <Form.Group as={Col} controlId="batch" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Select Existing Batch</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.batch}>{opt}</Form.Control> 
                                </Form.Group>
                            </Form.Row> 
                            }
                            {(user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false ) &&
                            <Form.Row>
                                <Form.Group as={Col} controlId="newbatch" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Enter New Batch (optional)</Form.Label>
                                    <Form.Control  placeholder="Enter new batch" defaultValue={this.state.newbatch} />                                                                
                                </Form.Group>
                            </Form.Row> 
                            }
                            {(user.config.wmsconfig && user.config.wmsconfig.expdatenotsupport === false) &&
                            <Form.Row>
                            <Form.Group as={Col} controlId="expirationdate" 
                                onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Expiraction date (optional - only for new batch)</Form.Label>
                            <Form.Control required={false} placeholder="DD/MM/YY" defaultValue={this.state.expirationdate} />
                            </Form.Group>
                        </Form.Row> 
                            }
                            <div style={{float: "left"}} >                                       
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>                                                         
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Back</Button>                              
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default ReceiveEnterItemAndQuantity;