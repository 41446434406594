import { authHeader } from './authHeader.js';
import {userService} from './userService.js'

var config = require('./config.js');

export const invoiceService = {
    getAllInv,
    searchInvByCustomer,
    getInv,
    addNewInv,
    updateInv,
    //deleteInv,
    getInvLineItem,
    addOrUpdateInvLineItem,
    deleteInvItem,
    createInvoice,
};

function createInvoice(invoice) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(invoice)   
    };
    return fetch(config.app.url + '/api/invoice', requestOptions).then(handleResponse);
}

function getAllInv(batchStart, batchCount) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/invoice/' + batchStart + '/batchcount/' + batchCount, requestOptions).then(handleResponse);
}

function searchInvByCustomer(batchStart, batchCount, search) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/invoice/' + batchStart + '/batchcount/' + batchCount + '/search/' + search, requestOptions).then(handleResponse);
}

function getInv(invid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/invoice/' + invid , requestOptions).then(handleResponse);
}

function addNewInv(so, serviceid) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(so)   
    };
    return fetch(config.app.url + '/api/invoice/' + serviceid, requestOptions).then(handleResponse);
}

function updateInv(resource) {
    //console.log(resource);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',
        body: JSON.stringify(resource)     
    };
    return fetch(config.app.url + '/api/invoice/' + resource.id , requestOptions).then(handleResponse);
}

// function deleteInv(id) {
//     const requestOptions = {
//         method: 'DELETE',
//         headers: authHeader(),
//         credentials: 'include',
//     };
//     return fetch(config.app.url + '/api/invoice/' + id , requestOptions).then(handleResponse);
// }

function getInvLineItem(id, invid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        credentials: 'include',    
    };
    return fetch(config.app.url + '/api/invoiceitem/' + id + '/invoice/' + invid, requestOptions).then(handleResponse);
}

function addOrUpdateInvLineItem(invid, item) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        credentials: 'include',  
        body: JSON.stringify(item)  
    };
    return fetch(config.app.url + '/api/invoiceitem/' + invid, requestOptions).then(handleResponse);
}

function deleteInvItem(id, invid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        credentials: 'include',
    };
    return fetch(config.app.url + '/api/invoiceitem/' + id + '/invoice/' + invid , requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 401) {
        return 401;
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log(data.data);
        return data.data;
    });
}

