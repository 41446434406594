import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";
import { TransferWithinAStationTwoTone } from '@material-ui/icons';
import { withRouter } from "react-router-dom";

class PickingItemAndQuantity extends React.Component {
    constructor(props) {
        super(props);
        console.log('PickingItemAndQuantity', props);
        this.state = {    
            item: {},   
            qty: undefined,
            itemcode: undefined,
            bin: undefined,
            batch: undefined, 
            batchopt: undefined,
            expirationdate: undefined,
            binInput: false,
            itemInput: true,
            qtyInput: true,
            qrcodescan: false,
            resetkey: 1,
            showScanner: false,
            timer: null,
            batchoptions: [{batch: 'Select one', expirationdate: undefined}],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChangeBin = this.handleInputChangeBin.bind(this);
        this.goBack = this.goBack.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.scanAny = this.scanAny.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.next = this.next.bind(this);
        this.goNext = this.goNext.bind(this);
    }

    componentDidMount() { 
        if (this.props.location.state) {
            this.setState({item: this.props.location.state});            
        }        
    }

    handleAndVerifyItemCode = (event) => {   
        const target = event.target;
        const name = target.id;           
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({timer: null});
        }
        this.setState({timer:
            setTimeout(() => {
                this.setState({itemcode: target.value});
                console.log('timer', this.state.itemcode, target.value);
                this.handleNext();     
                event.preventDefault();
            }, 3000)}
        );  
        
        if (event.key === 'Enter' || event.key === "Tab") {            
            event.preventDefault();        
        }        
    }

    handleAndVerifyQty = (event) => {           
        const target = event.target;
        const name = target.id;   
        
        this.setState({timer:
            setTimeout(() => {
                this.setState({qty: target.value});
                console.log('timer', this.state.qty, target.value);
                this.handleNext();     
                event.preventDefault();
            }, 1000)}
        ); 

        // if (event.key === 'Enter' || event.key === "Tab") {            
        //     this.handleNext();       
        //     event.preventDefault();   
        // }
        console.log('handleAndVerifyQty', this.state.qty);   
    }

    handleInputChangeBin = (event) => {   
        const target = event.target;
        const name = target.id;   
        
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({timer: null});
        }
        this.setState({timer:
            setTimeout(() => {
                this.setState({bin: target.value});
                console.log('timer', this.state.bin, target.value);
                this.handleNext(event);     
                event.preventDefault();
            }, 2000)}
        ); 
        
        if (event.key === 'Enter' || event.key === "Tab" ) {  
            event.preventDefault();  
        } 
      }

    handleNext() {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(this.state.binInput, this.state.itemInput, this.state.qtyInput);
        if (!this.state.binInput) { //disabled is false - meaning it is active
            
            if (!this.state.bin) {
                //alert("Your input is not correct. Please enter or scan correct bin")
                //this.setState({blur: this.state.blur}); // just a reloading react component technicue
            } else {   
                console.log('user',user);
                console.log('user.config.wmsconfig',user.config.wmsconfig);
                if (user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false) { 
                    wmsService.getPickingBatch(this.state.bin, this.state.item.item, user.config.inventoryLocation).then(witm => {
                        console.log('batch', witm);
                        if (witm && witm === 401) {
                            this.props.history.push("/login");
                        } else {
                            this.setState({batchoptions: witm});
                        }
                    });                             
                }
                //this.setState({binInput: true, itemInput: false});
            }
        } else if (!this.state.itemInput) {
            console.log(this.state.itemcode, this.state.item);
            if (!this.state.itemcode || this.state.itemcode !== this.state.item.item) {
                //alert("Your input is not correct. Please enter or scan correct item")
                this.setState({itemcode: ''});
                this.setState({resetkey: this.state.resetkey+1})
            } else {                
                this.setState({binInput: true, itemInput: true, qtyInput: false});
            }
        } else {
            if (!this.state.qty || this.state.qty <= 0 || this.state.qty > this.state.item.receivableQty) {
                alert("Your input is not correct. Please enter correct quantity")
            } else {                
                this.setState({binInput: true, itemInput: true, qtyInput: true});
            }
        }
    }

    goNext(e) {
        //alert('goNext...1')
        
        //var { from } = { pathname: "/pickitmqtybatchitm" };  

        var { from } = { from: { pathname: "/pickitmqtybatchitm" } };  
        console.log('goNext...:', from, this.state)
        //this.props.history.push(from, this.state);
        
        this.props.history.push("/pickitmqtybatchitm", this.state);
        //this.props.history.replace("/pickitmqtybatchitm", this.state);
    }

    next(e) {        
        if (!this.state.binInput) { //disabled is false - meaning it is active
            this.setState({binInput: true, itemInput: false});
        } else if (!this.state.itemInput) {
            this.setState({binInput: true, itemInput: true, qtyInput: false});
        }
    }    

    handleSubmit(e) {
        e.preventDefault();
        console.log("this.state", this.state);
        if (!this.state.itemcode || !this.state.bin ) {
            alert('Please enter values in all the input fields');
            this.resetInput();
            return;
        } else {
            if (this.state.itemcode !== this.state.item.item) {                
                alert ('Please enter or scan a correct item code.')
                this.resetInput();
                return;
            } else if (!this.state.qty && 
                      ( Number(this.state.qty) <= 0 ||
                       Number(this.state.qty) > Number(this.state.item.receivableQty)))  {                
                alert ('You entered 0 or higher than the ordered quantity.')
                return;
            } else {
                let user = JSON.parse(localStorage.getItem('user')); 
                //Checking user must not select 'Select One' from the dropdown
                if (this.state.batchopt === this.state.batchoptions[0].batch) {
                    this.state.batchopt = undefined;
                }
                if (user.config.wmsconfig && user.config.wmsconfig.batchmust === true) {
                    if (!this.state.batchopt) {
                        alert ("Batch information is required")
                        return;
                    }                    
                }

                if (this.state.batchopt) {
                    let ed = this.state.batchopt.split(' - ');
                    if (ed[0] === 'NA') {
                        ed[0] = '0';
                    }
                    this.state.batch = ed[0];
                    for (let b of this.state.batchoptions) {                        
                        console.log(ed.length, b.batch, ed[0], ed, ed[ed.length-1]);

                        if (ed.length > 1 && b.batch === ed[0]) {
                            this.state.expirationdate = ed[ed.length-1];
                            break;
                        }
                    }
                }

                let o = {};
                o.bin = this.state.bin;

                if (this.state.item.bins) {
                    for (let b of this.state.item.bins) {
                        if (this.state.bin === b) {
                            o.bintype = 1;  // 0 = No bin was recommended by WMS, so user assigned one, 1 = user choose one of the bin that is recommended by WMS, 2 = user choose different than WMS recommended        
                            break;
                        }
                    }
                    if (!o.bintype) {
                        o.bintype = 2;  // 0 = No bin was recommended by WMS, so user assigned one, 1 = user choose one of the bin that is recommended by WMS, 2 = user choose different than WMS recommended        
                    }
                } else {
                    o.bintype = 0;  // 0 = No bin was recommended by WMS, so user assigned one, 1 = user choose bin that is recommended by WMS, 2 = user choose different than WMS recommended
                }                
                
                o.licenseplate = this.state.item.licenseplate;
                o.sorder = this.state.item.sorder;
                o.sorderid = this.state.item.orderId;
                o.itemid = this.state.item.itemId;
                o.item = this.state.item.item;
                o.batch = this.state.batch;
                o.expirationdate = this.state.expirationdate;
                o.qty = this.state.qty;
                o.userwhoupdated = user.username;
                o.warehouse = user.config.inventoryLocation;
                o.wmsempactivityreport = this.state.item.wmsempactivityreport;
                console.log('o', o);
                //alert ('Got it!');
                
                // Two paths: #1: bin is matched, #2: bin does not match (user scans different bin number))
                // #1: find the records for that bin for items. Deduct the qty.
                // If there are multiple records then start deducting items from the qty which
                // is just above the ordered qty. If there are no such larger bucket then use 
                // multiple records and adjust qty for those.
                // If batch # number is available and if batch is used for fulfillment then,
                // We need to put batch number in the good issued deocument.
                // #2: check whether user scanned bin has required items in the WMS. If it has
                // then adjust those items. If there are no such items then flag error.
                // If bin was "NOT FOUND" then check why system did not pick it up and if possible
                // update the record so that next time this bin can be listed.

                wmsService.pick(o).then(witm => {
                    console.log('witm', witm);
                    if (witm && witm === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.props.history.goBack();
                    }
                });
            }
        }        
    }
    
    goBack =() => {   
        //alert('goBack...');     
        this.props.history.goBack();
    }

    scanAny = () => {
        this.setState({showScanner: true});
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        //console.log('Scanned Result', decodedText, decodedResult);                
        if (this.state.binInput === false) { //disabled is false - user scan bin number
            this.setState({showScanner: false, bin: decodedText, binInput: true, itemInput: false, qtyInput: true});
        } else if (this.state.itemInput === false) { //disabled is false -  user scan item code
            if (decodedText !== this.state.item.item) {
                alert ('Please enter or scan a correct item code.');                 
            } else {
                this.setState({showScanner: false, itemcode: decodedText, binInput: true, itemInput: true, qtyInput: false});
            }
        }   
    }

    resetInput = () => {        
        this.setState({binInput: false, itemInput: true, qtyInput: true,
            qty: undefined, itemcode: '', bin: ''});
        this.setState({resetkey: this.state.resetkey+1})
    }

    closeScanner() {
        this.setState({showScanner: false})
    }

    render() {
        //console.log('qty',this.state.qty, this.state.itemcode);
        let bins = 'Not found';
        if (this.state.item && this.state.item.bins) {
            for (let b of this.state.item.bins) {
                if (bins.length > 0) {
                    if (bins === 'Not found') {
                        bins = '';
                    } else {
                        bins = bins + ' | '
                    }
                }
                bins = bins + b;
            }
        }

        let opt = [];
        for (let p of this.state.batchoptions) {
            //console.log(p);
            let expd = undefined;
            if (p.expirationdate) {
                let date = new Date(p.expirationdate);
                expd = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
            }
            let k = (p.batch === '0' ? 'NA' : p.batch);
            if (expd) {
                k = k + ' - ' + expd;
            }
            opt.push(<option value={k.batch}>{k}</option>)            
        }
        let user = JSON.parse(localStorage.getItem('user'));
        return (
            
            <div key={this.state.resetkey}>                
                <Card>
                    <Card.Header>Picking Items from Bin                        
                    </Card.Header>
                    <Card.Body>
                        {/* <Form onSubmit={this.handleSubmit}> */}
                        <Form onSubmit={this.goNext}>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Bin: {bins}</Form.Label>
                            </Form.Row>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Item Code: {this.state.item.item}</Form.Label>
                            </Form.Row>
                            
                            {(!this.state.binInput || this.state.itemInput || this.state.qtyInput ) &&
                            <Form.Row>
                                <Form.Group as={Col} controlId="bin" 
                                    onKeyDown={this.handleInputChangeBin}>
                                <Form.Label style={{fontSize: "14px"}}>Enter or Scan Bin</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control autoFocus={this.state.binInput} disabled={this.state.binInput} required={true} autoComplete="off" placeholder="Enter or scan bin" defaultValue={this.state.bin} autoFocus  />
                                </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            }
                            {(!this.state.itemInput || this.state.itemcode ) &&
                            <Form.Row>
                                <Form.Group as={Col} controlId="itemcode" 
                                    onKeyDown={this.handleAndVerifyItemCode}>
                                <Form.Label style={{fontSize: "14px"}}>Enter or Scan Item</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control  autoFocus={this.state.itemInput} disabled={this.state.itemInput} required={true} autoComplete="off" placeholder="Enter or scan Item" defaultValue={this.state.itemcode} autoFocus />
                                    {/* <Button disabled={this.state.itemInput} variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.scanAny()}}>
                                        Scan
                                    </Button> */}
                                </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            }
                            {(!this.state.itemInput || this.state.itemcode ) && (user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false ) &&
                            <Form.Row>                   
                                <Form.Group as={Col} controlId="batchopt" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Select Batch</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.batchopt}>{opt}</Form.Control> 
                                </Form.Group>
                            </Form.Row> 
                            }
                            {(!this.state.qtyInput || this.state.qty) &&
                            <Form.Row>
                                <Form.Group as={Col} controlId="qty" onKeyDown={this.handleAndVerifyQty}>
                                <Form.Label style={{fontSize: "14px"}}>Quantity Picking</Form.Label>
                                <Form.Control  disabled={this.state.qtyInput} required={true} autoComplete="off" placeholder="Enter quantity receiving" defaultValue={this.state.qty} autoFocus />
                                </Form.Group>
                            </Form.Row> 
                            }
                            <div style={{float: "left"}} >     
                            {/* {(!this.state.qtyInput || this.state.qty) &&                                  
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Save</Button>  
                            } */}
                            
                            <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>  
                            
                            {(this.state.binInput === false || this.state.itemInput === false) &&                                  
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.next()}}>Nexttt</Button>  
                            }
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.scanAny()}}>Scan</Button>                                                       
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.resetInput()}}>Reset</Button>                              
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>
                            
                            <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default withRouter(PickingItemAndQuantity);