import React from "react";
import { customerService } from '../services/customerService';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button, Form, Col} from 'react-bootstrap';
import { Redirect } from "react-router-dom";
var config = require('../services/config');

class CustomerCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      name: undefined,
      sales_tax_id: undefined,
      currency: undefined,      
      country: undefined,      
      language: undefined,
      type: undefined,
      companyid: undefined,  
      
      ////
      addrid: undefined,
      fname: undefined, 
      lname: undefined, 
      email: undefined, 
      phone: undefined, 
      addr1: undefined, 
      addr2: undefined, 
      city: undefined, 
      state: undefined, 
      zip: undefined, 
      is_a_contact: false,
      ////
      client_link: undefined,

      countryopt: [],
      backToServiceCreate: false,
      isvendorcreate: undefined,

    };

    this.handleAddCustomerSubmit = this.handleAddCustomerSubmit.bind(this);   
    this.handleInputChange = this.handleInputChange.bind(this);  
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleInputChange = (event) => {    
    const target = event.target;
    const name = target.id;    
    this.setState({
      [name]: target.value
    });    
  }
  
  componentDidMount() {
    console.log(this.props.match);
    console.log('path', this.props.match.path);
    
    if (this.props.match.path === "/vendorcreate") {
      this.state.isvendorcreate = true;
    }
    let tmp = [];
    let user = JSON.parse(localStorage.getItem('user'));    
    for (let i=0; i<config.currencies.length; i++) {
      console.log(user.config.currency, config.currencies[i].value, config.currencies[i].label);
      if (user.config.currency === config.currencies[i].value) {
        console.log('selected...')
        tmp.push(<option selected value={config.currencies[i].value}>{config.currencies[i].label}</option>);
      } else {
        tmp.push(<option value={config.currencies[i].value}>{config.currencies[i].label}</option>);
      }
    }
    this.setState({countryopt: tmp});
  }

  handleAddCustomerSubmit = (e) => {
    e.preventDefault();
    //add a bew customer
    
    let user = JSON.parse(localStorage.getItem('user'));
    let customer = {};
    customer.name = this.state.name;
    customer.type = 1;
    if (this.state.isvendorcreate && this.state.isvendorcreate === true) {
      customer.type = 2;
    }
    customer.country = this.state.country;
    customer.currency = this.state.currency;
    customer.language = 'en';
    customer.companyid = user.config.companyId;
    if (!customer.country) {
      customer.country = user.config.country;
    }
    //alert(localStorage.getItem('user'));
    if (!customer.currency) {
      customer.currency = user.config.currency;
    }

    customer.client_link = this.state.client_link;

    customer.contact =
    {
        "fname": this.state.fname,
        "lname": this.state.lname,
        "addr1": this.state.addr1,
        "addr2": this.state.addr2,
        "city": this.state.city,
        "state": this.state.state,
        "zip": this.state.zip,
        "email": this.state.email,
        "phone": this.state.phone,
        "is_a_contact": this.state.is_a_contact
    }
    
    if (this.state.isvendorcreate && this.state.isvendorcreate === true) {
        customerService.addNewVendor(customer).then(cust => {
          if (cust && cust === 401) {
            this.props.history.push("/login");
          } else {            
            this.props.history.goBack();            
          }
        });
    } else {
      customerService.addNewCustomer(customer).then(cust => {
        if (cust && cust === 401) {
          this.props.history.push("/login");
        } else {          
            // //this does not work for form submit in React
            //this.setState({backToServiceCreate: true});
            this.props.history.goBack(); 
        }
      });
    }
    
  };

  handleCancel = () => {
    this.props.history.goBack();
  }

  handleContactCheckBox = () => {
    this.setState({is_a_contact: !this.state.is_a_contact});
  }
 

  render() {
    if (this.state.backToServiceCreate) {
      return <Redirect to='/serviceedit' />
    }

    if (!this.state.email) {
      this.state.email = "a@a.com";
    }

    return (   
      <div className="content-screen" style={{padding: '10px'}}>
            <div style={{width: '73rem'}}>
            <Form onSubmit={this.handleAddCustomerSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="name" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px", color: 'red'}}>Customer Name</Form.Label>
                      <Form.Control required="true" placeholder="Enter customer name" defaultValue={this.state.name} />
                    </Form.Group>                  

                    <Form.Group as={Col} controlId="currency" onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Currency</Form.Label>
                      <Form.Control as="select" defaultValue={this.state.currency}>
                            {this.state.countryopt}
                            
                      </Form.Control>            
                    </Form.Group>

                    <Form.Group as={Col} controlId="sales_tax_id" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Tax Id</Form.Label>
                      <Form.Control placeholder="Enter tax id" defaultValue={this.state.sales_tax_id} />
                    </Form.Group>
                  </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="fname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px", color: 'red'}}>First Name</Form.Label>
                      <Form.Control required="true" placeholder="Enter first name" defaultValue={this.state.fname} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="lname" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Last Name</Form.Label>
                      <Form.Control placeholder="Enter last name" defaultValue={this.state.lname} />
                    </Form.Group>
                    <Form.Group as={Col} id="is_a_contact" 
                        onChange={this.handleContactCheckBox}>
                      <Form.Label style={{fontSize: "14px"}}>Contact</Form.Label>
                      <Form.Check type="checkbox" checked={this.state.is_a_contact}/>                      
                    </Form.Group>
                  </Form.Row> 
                  <Form.Row>
                    <Form.Group as={Col} controlId="email" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px", color: 'red'}}>Email</Form.Label>
                      <Form.Control required="true" type="email" placeholder="Enter email" defaultValue={this.state.email} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="phone" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Phone</Form.Label>
                      <Form.Control type="phone" placeholder="Enter phone number" defaultValue={this.state.phone} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="client_link" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Customer Connection</Form.Label>
                      <Form.Control placeholder="Enter client connection" defaultValue={this.state.client_link} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="addr1" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address</Form.Label>
                      <Form.Control placeholder="Enter street address" defaultValue={this.state.addr1} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="addr2" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Address Cont'</Form.Label>
                      <Form.Control type="phone" placeholder="Enter address" defaultValue={this.state.addr2} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="city" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>City</Form.Label>
                      <Form.Control placeholder="Enter city" defaultValue={this.state.city} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="state" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>State</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.state} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="zip" 
                        onChange={this.handleInputChange}>
                      <Form.Label style={{fontSize: "14px"}}>Zip</Form.Label>
                      <Form.Control type="phone" placeholder="Enter state" defaultValue={this.state.zip} />
                    </Form.Group>
                  </Form.Row>
                  <div style={{float: "left"}} >    
                    <Button type="submit" variant="outline-primary mr-3">
                      Save
                    </Button>                
                    <Button variant="outline-primary mr-3" onClick={this.handleCancel.bind(this)}>
                      Cancel
                    </Button>
                    
                  </div>
              </Form>
              </div>
       
      </div>
    );
  }
}

export default CustomerCreate;