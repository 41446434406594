
var moment = require('moment')

function getInvoiceOrQuoteStatus (key) {
    const status = {'1': 'draft', '2': 'partial', '3': 'sent', '6': 'paid', '9': 'closed', '10': 'credit', '26': 'won', '27': 'advanced'};    
    //alert(key);
    let r = status[key];
    if (r) {
        return status[key];
    } else {
        return 'other'
    }
}

function getSalesOrderStatus (key) {
  const status = {'1': 'draft', '7': 'booked', '8': 'cancelled', '9': 'closed', '14': 'approved'};    
  //alert(key);
  let r = status[key];
  if (r) {
      return status[key];
  } else {
      return 'in processe'
  }
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      //console.log(e)
    }
  };

  function getPgDateFormat (dt) {
    let d = dt.getDate();
    let m = dt.getMonth();
    let y = dt.getFullYear();
    return y + '-' + (m+1) + '-' + d;
  }

  function utcToLocal(d) {
    var date = moment.utc(d).format();
    console.log(date, "- now in UTC"); 
    var local = moment.utc(date).local().format('ll');
    console.log(local, "- now in local");
    return local; 
  }

  function utcToLocalDateTime(d) {
    var date = moment.utc(d).format();
    console.log(date, "- now in UTC"); 
    var local = moment.utc(date).local().format('MMM DD, YYYY HH:mm');
    console.log(local, "- now in local");
    return local; 
  }

  function formatSchTime(t) {
    if (t) {
      t = '' + t;
      if (t.includes ('.')) {
        t = t.replace('.', ':');
        if (t.length = 3) {
          t = '0' + t + '0';
        } else if (t.length = 4){
          if (t.indexOf(':') === 1) {
            t = '0' + t;  
          } else {
            t = t + '0';
          }          
        } 
      } else {
        if (t.length > 1) {
          t = t + ':00';
        } else {
          t = '0' + t + ':00';
        }
      }
      return t;
    }
    return '00:00';
  }
 
function wrapText(text, maxChar) {
  if (text && text.length > maxChar) {
    text = text.substring(0, maxChar) + '...';
  }
  return text;
}  

// function getPriority(p) {
//   if (Number(p) === 1) {
//     return 'High';
//   } else if (Number(p) === 2) {
//     return 'Medium';
//   } else {
//     return 'Low'
//   }
// }

function getPriorityName(key) {
  if (key) {
    let config = JSON.parse(localStorage.getItem('config'));
    let priority = JSON.parse(config.serviceorderpriotities);
    return priority[key];             
  }
  return 'NA';
}


function getStatus(s) {
  if (Number(s) === 1) {
    return 'New';
  } else if (Number(s) === 2) {
    return 'Assigned';
  } else if (Number(s) === 3) {
    return 'Re-opened'
  } else if (Number(s) === 4) {
    return 'Ready'
  } else if (Number(s) === 4) {
    return 'Completed';
  } else {
    return 'Closed';
  }
}

function truncate(input, length) {
  if (input) {
    if (input.length > length)
      return input.substring(0,length) + '...';
    else
      return input;
  }
  return input;
};

//Returns true if date is in DD/MM/YYYY format and date is correct otherwise returns false.
function isValidDate(dateString)
{
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}

module.exports = {
    getSalesOrderStatus: getSalesOrderStatus,
    getInvoiceOrQuoteStatus: getInvoiceOrQuoteStatus,
    formatMoney: formatMoney,
    getPgDateFormat: getPgDateFormat,
    utcToLocal: utcToLocal,
    formatSchTime: formatSchTime,  
    utcToLocalDateTime: utcToLocalDateTime,
    wrapText: wrapText,
    //getPriority: getPriority,
    getStatus: getStatus,
    getPriorityName: getPriorityName,  
    truncate: truncate,
    isValidDate: isValidDate,
  };