import React from 'react';
import "./../App.css";
import {Box, List, ListItem, ListItemIcon, ListItemText, MenuItem, Collapse} from '@material-ui/core';
//import Box from '@mui/material/Box';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
//import Divider from '@mui/material/Divider';
//import InboxIcon from '@mui/icons-material/Inbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import Outbound from '@material-ui/icons/CallMissedOutgoing';
import Return from '@material-ui/icons/AssignmentReturnOutlined';
import Transfer from '@material-ui/icons/TransferWithinAStationOutlined';
import Invoice from '@material-ui/icons/ReceiptOutlined';
import Internal from '@material-ui/icons/AllInclusiveOutlined';
import SettingIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import Filter from '@material-ui/icons/Filter1';
import Report from '@material-ui/icons/Assessment';
import { compose } from 'recompose'
import { Link, withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'


const drawerWidth = 230

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'scroll',
    position: 'relative',
    display: 'flex',
    width: '100%',
    //backgroundColor: '#354052',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#425c84',
    height: '50px',  
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        overflow: "scroll",
        position: 'relative',
        height: "100%",  
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    marginTop: '-10px',
    overflowY: 'scroll',
    overflowX: 'scroll',
    height: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
})

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            settingOpen: false,
          }

    }
    
    handleClick = () => {
        this.setState({settingOpen: !this.state.settingOpen});
    };

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { classes, location: { pathname }, children, writers } = this.props
        return (
            
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>
                    <ListItem  button component={Link} to="/inbound" selected={'/inbound' === pathname}>
                        
                        <ListItemIcon>
                            <MoveToInbox />
                        </ListItemIcon>
                        <ListItemText primary="Inbound" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/outbound" selected={'/outbound' === pathname}>
                        
                        <ListItemIcon>
                            <Outbound />
                        </ListItemIcon>
                        <ListItemText primary="Outbound" />
                        
                    </ListItem>
                    {/*}
                    <ListItem  button>
                        
                        <ListItemIcon>
                            <Return />
                        </ListItemIcon>
                        <ListItemText primary="Return" />
                        
                    </ListItem>
                    */}
                    <ListItem  button component={Link} to="/transfermenu" selected={'/transfermenu' === pathname}>
                        
                        <ListItemIcon>
                            <Transfer />
                        </ListItemIcon>
                        <ListItemText primary="Transfer" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/searchitem" selected={'/searchitem' === pathname}>
                        
                        <ListItemIcon>
                            <SearchIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Search" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/invoiceList" selected={'/invoiceList' === pathname}>
                        
                        <ListItemIcon>
                            <Invoice />
                        </ListItemIcon>
                        <ListItemText primary="Invoice" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/count" selected={'/count' === pathname}>
                        
                        <ListItemIcon>
                            <Filter />
                        </ListItemIcon>
                        <ListItemText primary="Counting" />
                        
                    </ListItem>
                    <ListItem  button component={Link} to="/oprtn" selected={'/oprtn' === pathname}>
                        
                        <ListItemIcon>
                            <Internal />
                        </ListItemIcon>
                        <ListItemText primary="Operation" />
                        
                    </ListItem>
                    {/*
                    <ListItem  button>
                        
                        <ListItemIcon>
                            <Report />
                        </ListItemIcon>
                        <ListItemText primary="Report" />
                        
                    </ListItem>
                    */}
                    <ListItem  button component={Link} to="/setting" selected={'/setting' === pathname}>
                        
                        <ListItemIcon>
                            <SettingIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Setting" />
                        
                    </ListItem>
                    
                    
                    {/* <MenuItem button onClick={this.handleClick}>
                        <ListItemIcon>
                        <SettingIcon fontSize="small" />
                        </ListItemIcon>
                        Setting&nbsp;&nbsp;&nbsp;&nbsp;
                        {this.state.settingOpen ? <ExpandLess /> : <ExpandMore />}
                    </MenuItem>
                    <Collapse in={this.state.settingOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>                                        

                            <MenuItem component={Link} to="/recurrancelist" selected={'/recurrancelist' === pathname}className={classes.nested}>
                                <ListItemIcon>
                                <Internal />
                                </ListItemIcon>
                                Recurrance Order
                            </MenuItem>
                        </List>
                    </Collapse> */}
                    
                </List>
              </nav>
            </Box>      
        );
    }
}
//export default Home;
export default compose(
    withRouter,
    withStyles(styles)
  )(Home)