import React from 'react';
import "./../App.css";
import { Card, Button, InputGroup, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class Packing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            sorder: null,    
            lp: undefined,    
            lineItem: undefined,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user')); 
        wmsService.getPickedOrder(this.state.sorder, user.config.inventoryLocation).then(pord => {            
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                console.log('pickedorder', pord);
                if (pord.length > 0) {
                    this.state.lp = pord[0].licenseplate;
                    this.state.lineItem = pord;
                    let unique = [...new Set(pord.map(item => item.licenseplate))];
                    console.log(unique, unique.length);
                    let wmsempactivityreport = {};
                    wmsempactivityreport.invlocation = user.config.inventoryLocation;
                    wmsempactivityreport.wmsorder = this.state.sorder;
                    wmsempactivityreport.employee = user.username
                    wmsempactivityreport.type = 2;
                    wmsempactivityreport.starttime = (new Date()).toISOString();
                    this.state.wmsempactivityreport = wmsempactivityreport;
                    //console.log('wmsempactivityreport', wmsempactivityreport);
                    if (unique.length > 1) {
                        // Ask user to select a license plate
                        this.state.licenseplates = unique;
                        var { from } = this.props.location.state || { from: { pathname: "/packinglp" } };  
                        this.props.history.push(from, this.state);
                    } else {
                        var { from } = this.props.location.state || { from: { pathname: "/packitems" } };  
                        this.props.history.push(from, this.state);
                    }
                } else {
                    alert('Order or picked items for the order are not found. You should PICK first and then PACK the order.');
                    return;
                }
            }
        });        
    }
        
    goBack =() => {
        var { from } = { from: { pathname: "/outbound" } };  
        this.props.history.push(from, this.state);
    }

    render() {
        return (
            
            <div>                
                <Card>
                    <Card.Header>Packing Goods                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="sorder" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Order #</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control required={true} placeholder="Scan or enter order number" defaultValue={this.state.sorder} />                                
                                <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.scan()}}>
                                    Scan
                                </Button>
                            </InputGroup>
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        this.setState({showScanner: false, sorder: decodedText}); 
    } 
}
export default Packing;