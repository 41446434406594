import React from 'react';
import "./../App.css";
import { Card, Button, ListGroup, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class SettingWarehouse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            warehouses: null,        
        };        
        this.createNewWarehouse = this.createNewWarehouse.bind(this);
        this.goBack = this.goBack.bind(this);    
        this.editWarehouse = this.editWarehouse.bind(this);      
            
    }

    componentDidMount() { 
        wmsService.getWarehouses().then(witm => {
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else if (witm && witm.location && witm.location.length > 0) {
                console.log(witm.location);
                this.setState({warehouses: witm.location});                
            }             
        });
        
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    editWarehouse(wh){        
        var { from } =  { from: { pathname: "/swarehouseedit" } };  
        this.props.history.push(from, wh);
    }

    createNewWarehouse(){
        var { from } =  { from: { pathname: "/swarehouseedit" } };  
        this.props.history.push(from);
    }

    render() {        
        
        if (!this.state.warehouses) {
            return (<div></div>);
        }

        let list = [];
        if (this.state.warehouses.length > 0) {
            for (let w of this.state.warehouses) {
                list.push(<ListGroup.Item action onClick={() => {this.editWarehouse(w)}}>{w.locationName}</ListGroup.Item>);
            }
        }
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Setting Warehouse: Configure existing or add new warehouse
                    </Card.Header>
                    <Card.Body>
                      
                        <ListGroup >
                            {list}
                        </ListGroup>
                        <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                              
                        <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => {this.createNewWarehouse()}}>Add New Warehouse</Button>                              

                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default SettingWarehouse;