import React from 'react';
import "./../App.css";
import { Card, CardDeck, Modal, Button, InputGroup, FormControl, Form, Col } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class CountCycleBin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            bin: null, 
            items: null,   
            blind: false,                
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);
    }

    componentDidMount() {  
        if (this.props.location.pathname === "/countcyblnd") {
            this.state.blind = true;
        }
        console.log(this.props.location.pathname);
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('user')); 
        wmsService.getBinItems(user.config.inventoryLocation, this.state.bin).then(pord => {
            console.log(pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                this.setState({items: pord});
                var { from } = this.props.location.state || { from: { pathname: "/countcycle" } };  
                this.props.history.push(from, this.state);                
            }
        });        
    }
    
    goBack =() => {
        var { from } = { from: { pathname: "/count" } };  
        this.props.history.push(from, this.state);
    }

    scan =() => {
        console.log('Scan is called');
      }

    render() {
        return (
            
            <div>                
                <Card>
                    <Card.Header>Cycle Count By Bin                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="bin" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Bin #</Form.Label>
                            <Form.Control required={true} placeholder="Scan or enter bin number" defaultValue={this.state.bin} />                                                                
                           </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>                              
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, bin: decodedText}); 
    } 
}
export default CountCycleBin;