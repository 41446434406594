import React, { forwardRef } from "react";
import { Button } from 'react-bootstrap';
import "./../App.css";
import { wmsService } from "../services/wmsService";
import { invoiceService } from "../services/invoiceService";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import ScannerIcon from '@material-ui/icons/GradientOutlined';
import { ControlPointDuplicateOutlined, TramOutlined } from "@material-ui/icons";

import Scanner from "./Scanner";
import { Divider, Grid, Typography, List, ListItem, ListItemText } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    //Edit: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ScannerIcon: forwardRef((props, ref) => <ScannerIcon {...props} ref={ref} />),
};


class InvoiceView extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            config: undefined,
            count: 0,
            scanitem: null,
            cycle: null,
            showScanner: false,
            reload: 0,
            selectedrows: undefined,
            warehouse: undefined,
            data: [],
            totalCount: 0,
            page: 0,
            columns: [
                {
                    field: 'description',
                    title: 'Item',
                    editable: false,
                },
                {
                    field: 'qty',
                    title: 'Qty',
                    //editable: true,
                },
                {
                    field: 'unitPrice',
                    title: 'Price',
                    render: rowData => { if (rowData.unitPrice) { return Number(rowData.unitPrice).toFixed(2) } },
                    //editable: true,
                },
                {
                    field: 'discount',
                    title: 'Discount %',
                    //editable: true,
                },
                {
                    field: 'tax01',
                    title: 'Tax',
                    lookup: this.getTaxes(),
                    //editable: true,
                },
            ],

            showScanner: false,
            subTotal: 0,
            totdiscount: 0,
            tottax: 0,
        };
        this.goBack = this.goBack.bind(this);
        this.calcInvoice = this.calcInvoice.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);
        this.scan = this.scan.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
        this.addLineItem = this.addLineItem.bind(this);
        this.addItemInInvoice = this.addItemInInvoice.bind(this);
        this.getTaxes = this.getTaxes.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.updateInvoice = this.updateInvoice.bind(this);
    }

    componentDidMount() {
        //console.log('props.location.state', this.props.location.state);
        if (this.props.location.state) {
            this.state.data = this.props.location.state.lineItem;
            this.setState({ data: this.props.location.state.lineItem });
            this.calcInvoice();
        }

        let user = JSON.parse(localStorage.getItem('user'));
        //console.log(user);
        this.state.warehouse = user.config.inventoryLocation;
        // wmsService.getInboundActivityPage(this.state.warehouse , 0, 200).then(result => {
        //     if (result && result === 401) {             
        //         this.props.history.push("/login");  
        //     } else {S
        //         this.setState({data: result});
        //     }
        // });
        wmsService.getWmsConfig(user.config.inventoryLocation).then(witm => {
            if (witm && witm === 401) {
                this.props.history.push("/login");
            } else if (witm && witm.length > 0) {
                let cnf = witm[0];
                if (cnf.configuration) {
                    cnf = JSON.parse(cnf.configuration);
                    //console.log('cnf', cnf);
                    if (cnf.tax) {
                        this.setState({ tax: cnf.tax });
                    }
                }
                //console.log(this.state.tax);                       
            }
        });
    }

    getTaxes() {
        let user = JSON.parse(localStorage.getItem('user'));
        let taxes = { 0: '0' };
        if (user.config.tax && user.config.tax.length > 0) {
            for (let i = 0; i < user.config.tax.length; i++) {
                taxes[user.config.tax[i].name] = user.config.tax[i].name;
                i++;
            }
        }
        //console.log('taxes', taxes)
        return taxes;
    }

    goBack = () => {
        //this.props.history.goBack();
        var { from } = { from: { pathname: "/invoiceList"} };
        this.props.history.push(from);        
    }

    goAddLineItem = () => {
        var { from } = { from: { pathname: "/invoicelineitem/" + this.props.location.state.id } };
        this.props.history.push(from);
    }

    sendEmail =() => {
        var { from } = { from: { pathname: "/sendemail"} };  
        var email = {};
        email.id = this.props.location.state.id;
        email.module = 'invoice';
        email.customerid = this.props.location.state.clientId;    
        this.props.history.push(from, email);
    }

    goSign = () => {
        var { from } = { from: { pathname: "/sign"} };
        this.props.history.push(from, this.props.location.state.id);
    }

    render() {
        let titl = <div></div>;
        if (!this.state.data) {
            return titl;
        }

        let date = '';
        if (this.props.location && this.props.location.state && this.props.location.state.invdate) {
            if (this.props.location.state.invdate) {
                date = new Date(this.props.location.state.invdate).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" });
            }

            titl = <div>
                <div>&nbsp;</div>
                <div><strong>{this.props.location.state.clientName}</strong></div>
                <div>Invoice #: {this.props.location.state.invoiceId}</div>
                <div>Date: {date}</div>
            </div>
        }
        let currency = "$";
        if (this.props.location.state.currencyCode) {
            if (this.props.location.state.currencyCode === "USD") {
                currency = "$";
            }
        }

        return (
            <div>

                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Back</Button>
                {/* <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Return</Button> */}
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goSign() }}>Sign</Button>
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.sendEmail() }}>Email</Button>
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Print</Button>
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goAddLineItem() }}>Add Item</Button>

                <div>&nbsp;</div>

                <MaterialTable key={this.state.count}
                    title={titl}
                    icons={tableIcons}
                    columns={this.state.columns}
                    //data={this.state.data}
                    data={this.state.data}

                    options={{
                        search: false,
                        paging: true,
                        actionsColumnIndex: -1,
                        pageSize: 5,
                        exportButton: false,
                        debounceInterval: 1500,
                    }}
                    //editable={this.state.editable}
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Customer name'
                        },
                        body: {
                            editTooltip: 'Edit'
                        }
                    }}
                    // renderSummaryRow={({ columns, data }) =>                                                   
                    //     columns.field === "amountInvoiced"
                    //     ? {
                    //         value: '$ 9090.00',
                    //         style: { background: "red" },
                    //         }
                    //     : undefined                       

                    // }  
                    components={{
                        Pagination: (props) => <>
                            <Grid container style={{ padding: 15, background: "#f5f5f5" }}>
                                <Grid sm={6} item><Typography variant="subtitle2">&nbsp;</Typography></Grid>
                                <Grid sm={6} item alignContent="center">

                                    <Typography variant="subtitle2">Sub Total&nbsp;&nbsp;{currency}&nbsp;<span alignContent="right">{Number(this.state.subTotal).toFixed(2)}</span></Typography>
                                    <Typography variant="subtitle2">Discount&nbsp;&nbsp;&nbsp;{currency}&nbsp;<span alignContent="right">{Number(this.state.totdiscount).toFixed(2)}</span></Typography>
                                    <Typography variant="subtitle2">Tax&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{currency}&nbsp;<span alignContent="right">{Number(this.state.tottax).toFixed(2)}</span></Typography>
                                    <Typography variant="subtitle"><strong>Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{currency}&nbsp;<span alignContent="right">{Number(this.props.location.state.amountInvoiced).toFixed(2)}</span></strong></Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </>
                    }
                    }
                    actions={[
                        {
                            icon: () => <ScannerIcon />,
                            tooltip: 'Scan Item',
                            isFreeAction: true,
                            onClick: () => {
                                this.scan();
                            }
                        }
                    ]}

                    editable={{

                        onRowUpdate: (newData, oldData) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    newData.discount = Number((parseFloat(newData.discount)).toFixed(2));
                                    newData.unitPrice = Number((parseFloat(newData.unitPrice)).toFixed(2));
                                    newData.qty = Number((parseFloat(newData.qty)).toFixed(2));

                                    //
                                    if (newData.tax01 !== oldData.tax01) {
                                        let user = JSON.parse(localStorage.getItem('user'));

                                        if (user.config.tax && user.config.tax.length > 0) {
                                            for (let i = 0; i < user.config.tax.length; i++) {
                                                if (newData.tax01 === user.config.tax[i].name) {
                                                    newData.tax1 = user.config.tax[i].rate;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    //

                                    const dataUpdate = [...this.state.data];
                                    // In dataUpdate, find target
                                    const target = dataUpdate.find(
                                        (el) => el.id === oldData.id
                                    );
                                    const index = dataUpdate.indexOf(target);

                                    dataUpdate[index] = newData;

                                    this.setState({ data: dataUpdate });
                                    this.calcInvoice();
                                    this.updateInvoice();
                                    resolve();
                                }, 100);
                            });
                        },
                        onRowDelete: (oldData) => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    const dataDelete = [...this.state.data];
                                    const target = dataDelete.find(
                                        (el) => el.id === oldData.id
                                    );

                                    const index = dataDelete.indexOf(target);

                                    dataDelete.splice(index, 1);

                                    //setData([...dataDelete]);

                                    this.setState({ data: dataDelete });
                                    this.calcInvoice();
                                    this.updateInvoice();
                                    resolve();
                                }, 100);
                            });
                        },
                    }}

                />
                <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
            </div>
        );
    }

    scan = () => {
        //console.log('Scan item is called');        
        this.setState({ showScanner: true });
    }

    closeScanner() {
        this.setState({ showScanner: false })
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        this.setState({ showScanner: false, sorder: decodedText });
        //console.log('decodedText', decodedText);
        this.addLineItem(decodedText);
    }

    addLineItem(itemCode) {
        let user = JSON.parse(localStorage.getItem('user'));
        //console.log(user);
        wmsService.getWmsInventoryByItemCode(itemCode, user.config.inventoryLocation).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo && pinfo.length > 0) {
                console.log('pinfo', pinfo[0])                
                this.addItemInInvoice(pinfo[0]);
                this.updateInvoice();                
            } else if (pinfo) {
                alert("Item is not found in this warehouse")
            }
        });
    }

    addItemInInvoice(pinfo) {

        let user = JSON.parse(localStorage.getItem('user'));
        let item = {};
        item.companyId = user.companyid;

        item.conversionRate = 1;
        item.currency = this.props.location.state.currencyCode;
        item.deleted = false;
        item.description = pinfo.description;
        item.discount = pinfo.discount;
        item.inFulfillment = false;
        //item.invoiceDate = this.props.location.state.invdate;
        item.invoiceId = this.props.location.state.id;
        item.item = pinfo.item;
        item.itemId = pinfo.itemid;
        item.listPrice = pinfo.actual_unit_cost;
        item.qty = 1;
        item.tax1 = 0;        

        if (this.state.tax) {
            item.tax01 = this.state.tax;
            if (user.config.tax && user.config.tax.length > 0) {
                for (let i = 0; i < user.config.tax.length; i++) {
                    if (this.state.tax === user.config.tax[i].name) {
                        item.tax1 = user.config.tax[i].rate;
                        break;
                    }
                }
            }
        }

        item.tax2 = 0;
        item.unitPrice = pinfo.unit_cost;
        item.whLocation = user.config.inventoryLocation;


        var BreakException = {};
        let found = false;
        try {
            this.state.data.forEach(function (itm) {
                if (itm.item === item.item) {
                    itm.qty = itm.qty + 1;
                    found = true;
                    throw BreakException;
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }

        if (!found) {
            this.state.data.push(item)
        }
        this.calcInvoice();
        this.setState({ count: this.state.count + 1 });

    }

    calcInvoice = () => {
        //console.log('calcInvoice called....', this.state.data.length)
        this.props.location.state.lineItem = this.state.data;
        if (this.state.data && this.state.data.length > 0) {

            let subTotal = 0;
            let discount = 0;
            let tax = 0;

            this.state.data.forEach(function (li) {
                let qty = 0;
                if (li.qty && li.qty > 0) {
                    qty = li.qty;
                }
                //console.log(qty)
                let price = 0;
                if (li.unitPrice && li.unitPrice > 0) {
                    price = li.unitPrice;
                }
                //console.log(price)
                let lt = parseFloat((qty * price).toFixed(2));
                //console.log('lt', lt);

                subTotal = subTotal + lt;


                //console.log('dis', li.discount)
                let disc = 0;
                if (li.discount && li.discount > 0) {
                    disc = parseFloat((lt * li.discount / 100).toFixed(2));
                    discount = discount + disc;
                }
                //console.log('li tax', li.tax1)
                if (li.tax1 && li.tax1 > 0) {
                    //console.log(lt, disc, li.tax1)
                    let t = parseFloat(((lt - disc) * (li.tax1 / 100)).toFixed(2));
                    tax = tax + t;
                }
                //console.log('tax', tax)
            });
            let total = subTotal - discount + tax;
            this.props.location.state.amountInvoiced = total;

            //console.log('res', subTotal, discount, tax );            
            this.setState({ subTotal: subTotal, tottax: tax, totdiscount: discount });
        } else {
            this.props.location.state.amountInvoiced = 0;
            this.setState({ subTotal: 0, tottax: 0, totdiscount: 0 });
        }
        //console.log(this.props.location.state);
    }

    createInvoice = () => {
        invoiceService.createInvoice(this.props.location.state).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo && pinfo.rpcStatus != 0) {
                console.log('New invoice is added')
            } else {
                alert("New invoice creation is failed!")
            }
        });
    }

    updateInvoice = () => {

        this.props.location.state.lineItem.forEach((li) => {
            delete li.invoiceDate;
        })
        delete this.props.location.state.invdate;
        delete this.props.location.state.exchangeRateDate;

        //console.log(this.props.location.state);
        invoiceService.updateInv(this.props.location.state).then(pinfo => {
            if (pinfo && pinfo === 401) {
                this.props.history.push("/login");
            } else if (pinfo && pinfo.rpcStatus != 0) {
                console.log('Invoice is updated')
            } else {
                alert("Invoice update failed!")
            }
        });
    }

}
export default InvoiceView;