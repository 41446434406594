import React, { forwardRef } from "react";
import { Button } from 'react-bootstrap';
import "./../App.css";
import { wmsService } from "../services/wmsService";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import AddTo from '@material-ui/icons/AddToPhotos';
import { TramOutlined } from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    AddTo: forwardRef((props, ref) => <AddTo {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),    
  };

class OperationPending extends React.Component {
    constructor(props) {
        super(props);       
        this.state = {    
            count: new Map(),
            scanitem: null,
            cycle: null,                          
            showScanner: false,  
            reload: 0,
            selectedrows: undefined,
            data: [],
            columns: [
                {
                  field: 'id',
                  title: 'id',  
                  hidden: true,        
                }, 
                {
                    field: 'item',
                    title: 'Item',
                },
                {
                  field: 'batch',
                  title: 'Batch',              
                },
                {
                    field: 'uom',
                    title: 'UOM',              
                },
                {
                    field: 'bin',
                    title: 'Bin',  
                },
                {
                    field: 'qty',
                    title: 'Counted Qty',
                },
                {
                    field: 'wmsqty',
                    title: 'Qty On-hand',
                },
                {
                    field: 'expirationdate',
                    title: 'Exp. Date',
                    render: rowData => {if (rowData.expirationdate) { return new Date(rowData.expirationdate).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}}
                },
                {
                    field: 'userwhoupdated',
                    title: 'Counted By',
                },
                {
                    field: 'updateddate',
                    title: 'Last Update',
                    render: rowData => {if (rowData.updateddate) { return new Date(rowData.updateddate).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}}
                },                                         
              ],
              actions: [
                rowData => ({
                  icon: () => <Update />,
                  tooltip: 'Approve it',
                  onClick: (event, rowData) => this.updateApprove(rowData),
                  hidden: !this.hideupdate(rowData.wmsqty), 
                }),
                rowData => ({
                    icon: () => <AddTo />,
                    tooltip: 'Add Item Template or Approve it',
                    onClick: (event, rowData) => this.addItemTemplateInWmsOrAddCount(rowData),
                    hidden: this.hideupdate(rowData.wmsqty),
                  }),
                {
                    icon: () => <DeleteOutline />,
                    tooltip: 'Reject and Delete',
                    onClick: (event, rowData) => {
                        if(window.confirm("Are you sure you want to reject and delete this?")) {                       
                            this.deleteRow(rowData)                        
                        }
                    }
                  },
              ],             
        };
        this.goBack = this.goBack.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.updateApprove = this.updateApprove.bind(this);
        this.addItemTemplateInWms = this.addItemTemplateInWms.bind(this);
        this.addItemTemplateInWmsOrAddCount = this.addItemTemplateInWmsOrAddCount.bind(this);
        this.hideupdate = this.hideupdate.bind(this);
    }

    hideupdate(wmsqty) {
        console.log('wmsqty', wmsqty)
        if (wmsqty === null) {
            return false;
        }
        if (wmsqty >= 0) {
            console.log('true');
            return true;
        }
        return false;
    }

    componentDidMount() {  
        console.log('props.location.state', this.props.location.state);       
        if (this.props.location.state ) {
            this.setState({cycle: this.props.location.state});
        }        

        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getPendingApprovals(user.config.inventoryLocation).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                this.setState({data: witm});
            }             
        });
    }
    addItemTemplateInWmsOrAddCount(row) {
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getBin(user.config.inventoryLocation, row.bin).then(bin => {
            console.log(bin);
            if (bin && bin === 401) {             
                this.props.history.push("/login");  
            } else {
                if (!bin || bin.length === 0) {
                    alert('You must create bin from Setting before you can approve it.');
                }  
                else {
                    wmsService.getOrAddItem(row).then(it => {
                        console.log(it);
                        if (it && it === 401) {             
                            this.props.history.push("/login");  
                        } else if (it){
                            
                            let obj = {};
                            obj.itemid = it.id;
                            obj.qty = 0;
                            obj.parentbin = row.bin;
                            obj.item = row.item;
                            if (!it.description) {
                                obj.description = " ";
                            } else {
                                obj.description = it.description;
                            }
                            obj.batch = row.batch;
                            obj.uom = it.unitOfMeasure;
                            obj.expirationdate = row.expirationdate;
                            obj.zone = bin[0].zone;
                            obj.invlocation = user.config.inventoryLocation;
                            obj.userwhoupdated = user.username;
                            wmsService.createWmsItem(obj).then(bb => {
                                if (bb && bb === 401) {             
                                    this.props.history.push("/login");  
                                } else {
                                    this.updateApprove(row);
                                    /*
                                    obj = {};
                                    obj.id = row.id;
                                    obj.approver = user.username;
                                    obj.qty = row.qty;
                                    obj.bin = row.bin;
                                    obj.item = row.item;
                                    obj.batch = row.batch;
                                    if (row.uom && row.uom != 'null') {
                                        obj.uom = row.uom;
                                    }
                                    if (row.expirationdate) {
                                        var d = new Date(row.expirationdate);
                                        var n = d.toLocaleDateString();
                                        obj.expirationdate = n;
                                    }

                                    obj.warehouse = user.config.inventoryLocation;

                                    wmsService.approveCount(obj).then(witm => {
                                        console.log(witm);
                                        if (witm && witm === 401) {             
                                            this.props.history.push("/login");  
                                        } else {
                                            wmsService.getPendingApprovals(user.config.inventoryLocation).then(witm => {
                                                console.log(witm);
                                                if (witm && witm === 401) {             
                                                    this.props.history.push("/login");  
                                                } else {
                                                    this.setState({data: witm});
                                                }             
                                            });
                                        }             
                                    });
                                    */
                                }
                            })
                        } else {
                            alert('Failed to create or get item from Datamoto Inventory!')
                        }            
                    });
                }
            }             
        });        
    }


    // Add item in Datamoto if it is not available. Also add item in WMS if it is not available. This will not update count.
    addItemTemplateInWms(row) {
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getBin(user.config.inventoryLocation, row.bin).then(bin => {
            console.log(bin);
            if (bin && bin === 401) {             
                this.props.history.push("/login");  
            } else {
                if (!bin || bin.length === 0) {
                    alert('You must create bin from Setting before you can approve it.');
                }  
                else {
                    wmsService.getOrAddItem(row).then(it => {
                        console.log(it);
                        if (it && it === 401) {             
                            this.props.history.push("/login");  
                        } else if (it){
                            
                            let obj = {};
                            obj.itemid = it.id;
                            obj.qty = 0;
                            obj.parentbin = row.bin;
                            obj.item = row.item;
                            if (!it.description) {
                                obj.description = " ";
                            } else {
                                obj.description = it.description;
                            }
                            obj.batch = row.batch;
                            obj.uom = it.unitOfMeasure;
                            obj.expirationdate = row.expirationdate;
                            obj.zone = bin[0].zone;
                            obj.invlocation = user.config.inventoryLocation;
                            obj.userwhoupdated = user.username;
                            wmsService.createWmsItem(obj).then(bb => {
                                if (bb && bb === 401) {             
                                    this.props.history.push("/login");  
                                } else {
                                    //refresh the page with new rows
                                    wmsService.getPendingApprovals(user.config.inventoryLocation).then(witm => {
                                        console.log(witm);
                                        if (witm && witm === 401) {             
                                            this.props.history.push("/login");  
                                        } else {
                                            this.setState({data: witm});
                                        }             
                                    });
                                }
                            })
                        } else {
                            alert('Failed to create or get item from Datamoto Inventory!')
                        }            
                    });
                }
            }             
        });        
    }

    // It assumes that item is available in WMS and DM Inventory and it just updates the count.
    updateApprove(row) {
        console.log('row', row);
        let user = JSON.parse(localStorage.getItem('user'));
        let obj = {};
        obj.id = row.id;
        obj.approver = user.username;
        obj.qty = row.qty;
        obj.bin = row.bin;
        obj.item = row.item;
        obj.itemid = row.itemid;
        obj.batch = row.batch;
        if (row.uom && row.uom != 'null') {
            obj.uom = row.uom;
        }
        if (row.expirationdate) {
            var d = new Date(row.expirationdate);
            var n = d.toLocaleDateString();
            obj.expirationdate = n;
        }

        obj.warehouse = user.config.inventoryLocation;

        ////
        //find the total qty for the item in the warehouse
        wmsService.getTotalQty(user.config.inventoryLocation, row.item).then(iqty => {
            if (iqty && iqty === 401) {             
                this.props.history.push("/login");  
            } else  if (iqty){
                console.log('iqty', iqty);
                obj.adjustdmqty = row.qty;
                wmsService.approveCount(obj).then(witm => {
                    console.log(witm);
                    if (witm && witm === 401) {             
                        this.props.history.push("/login");  
                    } else {
                        wmsService.getPendingApprovals(user.config.inventoryLocation).then(witm => {
                            console.log(witm);
                            if (witm && witm === 401) {             
                                this.props.history.push("/login");  
                            } else {
                                this.setState({data: witm});
                            }             
                        });
                    }             
                });
            }
        }); 
        ////
        /*
        wmsService.approveCount(obj).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                wmsService.getPendingApprovals(user.config.inventoryLocation).then(witm => {
                    console.log(witm);
                    if (witm && witm === 401) {             
                        this.props.history.push("/login");  
                    } else {
                        this.setState({data: witm});
                    }             
                });
            }             
        });*/
    }
    
    deleteRow(row) {       
        wmsService.removeCycleCount(row.id).then(witm => {
            //console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                let user = JSON.parse(localStorage.getItem('user'));
                wmsService.getPendingApprovals(user.config.inventoryLocation).then(witm => {
                    console.log(witm);
                    if (witm && witm === 401) {             
                        this.props.history.push("/login");  
                    } else {
                        this.setState({data: witm});
                    }             
                });
            }             
        });        
    } 

    
    goBack =() => {
        this.props.history.goBack();
    }
    
    render() {
        return (
                <div>
                <MaterialTable key={this.state.count}
                    title="Pending Approvals"
                    icons={tableIcons}
                    columns={this.state.columns}
                    data={this.state.data}        
                    actions={this.state.actions}
                    options={{
                        search: false,
                        paging: false,
                        actionsColumnIndex: -1,
                    }}  
                    //editable={this.state.editable}                  
                />
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => {this.goBack()}}>Back</Button>                              
                </div>
        );
    }
}
export default OperationPending;