import React, { forwardRef } from "react";
import "./../App.css";
import { Card, Col, InputGroup, Form, Button} from 'react-bootstrap';
import { wmsService } from "../services/wmsService";
import Scanner from "./Scanner";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { TramOutlined } from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class CountCycleItemBinItem extends React.Component {
    constructor(props) {
        super(props);       
        this.state = {    
            scanitem: null,
            cycle: null,                          
            showScanner: false,  
            blind: false,
            reload: 0,
            selectedrows: undefined,
            qtycount: 0,
            data: [],
            columns: [
                {
                  field: 'id',
                  title: 'id',  
                  hidden: true,        
                }, 
                  {
                  field: 'batch',
                  title: 'Batch',              
                },
                {
                    field: 'qty',
                    title: 'Cyl Count',
                  },                                         
              ],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);  
        this.confirm = this.confirm.bind(this);   
        this.deleteRow = this.deleteRow.bind(this);
        this.getItemCountRender = this.getItemCountRender.bind(this);
    }

    componentDidMount() {  
        console.log('props.location.state', this.props.location.state);       
        if (this.props.location.state ) {
            this.state.cycle = this.props.location.state;            
            this.setState({blind: this.props.location.state.blind});
        }        

        let user = JSON.parse(localStorage.getItem('user'));
        console.log('this.state.cycle...2', this.state.cycle); 
        wmsService.getItemForBinCycleCounts(user.config.inventoryLocation, this.state.cycle.item, this.state.cycle.bin, this.state.cycle.batch).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                this.setState({data: witm});
            }             
        });
    }
    
    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

      deleteRow(row) {
        console.log(row)
        wmsService.removeCycleCount(row.id).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                return true;
            }             
        });
    } 

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.cycle.item) {
            alert ('You must provide the item number')
        } else if (!this.state.qtycount || this.state.qtycount == 0) {
            alert ('You must provide the quantity')
        } else {
            let user = JSON.parse(localStorage.getItem('user'));
       
            let obj = {};
            obj.invlocation = user.config.inventoryLocation
            obj.bin = this.state.cycle.bin;
            obj.item = this.state.cycle.item;
            if (this.state.cycle.batch) {
                obj.batch = this.state.cycle.batch; 
            }
            if (this.state.cycle.expirationdate) {
                obj.expirationdate = this.state.cycle.expirationdate; 
            }

            obj.qty = this.state.qtycount;           
            obj.userwhoupdated = user.username;
    
            wmsService.createCycleCount(obj).then(witm => {
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    alert ('Your count for item ' + obj.item + ' is saved.');
                    this.setState({qtyinp: undefined, qtycount: 0});
                    wmsService.getItemForBinCycleCounts(user.config.inventoryLocation, this.state.cycle.item, this.state.cycle.bin, this.state.cycle.batch).then(witm => {
                        console.log(witm);
                        if (witm && witm === 401) {             
                            this.props.history.push("/login");  
                        } else {
                            this.setState({data: witm});
                        }             
                    });
                    this.setState({reload: this.state.reload + 1});
                }             
            });
        }        
    }
    
    goBack =() => {        
        this.props.history.goBack();
    }

    confirm() {
        if (this.state.qtyinp) {
            this.setState({qtycount: this.state.qtycount + Number(this.state.qtyinp)}); 
        }           
    }

    getItemCountRender =() => {
        console.log('getItemCountRender...1', this.state.cycle);
        if (this.state.cycle && this.state.cycle.items ) {
            if (this.state.cycle.items.length === 1) {
                if (this.state.cycle.items[0].batch === '0') {
                    return <div>Batch {this.state.cycle.items[0].sum}</div>
                } else {                    
                    return <div>Batch {this.state.cycle.items[0].batch}: {this.state.cycle.items[0].sum}</div>
                }
            } else if (this.state.cycle.items.length > 1) {
                let d = [];
                for (let o of this.state.cycle.items) {
                    if (o.batch === '0') {
                        d.push(<div>Batch NA: {o.sum}</div>)
                    } else {
                        d.push(<div>Batch {o.batch}: {o.sum}</div>);
                    }
                }
                return d;
            } else {
                return (<div></div>);
            }
        }
    }
    
    render() {
        //console.log('PickItems', this.state);
        if (!this.state.cycle) {
            return (<div></div>);
        }
        console.log('PickItems', this.state.cycle);
        return (
            
            <div>                
                <Card>
                    <Card.Header>Count By Item - Quantity                      
                    </Card.Header>
                    <Card.Body>
                        <div key={this.state.reload}>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text>
                                <div className="font-weight-bold">Bin: {this.state.cycle.bin}</div>
                                <div className="font-weight-bold">Item: {this.state.cycle.item}</div>
                                {!this.state.blind && 
                                <span>
                                <span><div className="font-weight-bold">Quantity On-hand: </div>{this.getItemCountRender()}</span>
                                <div className="font-weight-bold">Your count: {this.state.qtycount}</div>
                                </span>
                                }
                            </Card.Text>                                                                                  
                            <Form.Row>
                            <Form.Group as={Col} controlId="qtyinp" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Quantity #</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control placeholder="Enter item quantity" defaultValue={this.state.qtyinp} />                                
                                    <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.confirm()}}>
                                        Confirm
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            </Form.Row>
                            <Form.Row>
                            <div style={{float: "left"}} >                                                                       
                                <Button variant="outline-primary mr-3 " size="sm" type="submit">Save</Button>                              
                                <Button variant="outline-primary mr-3 " size="sm" type="button" onClick={() => {this.scan()}}>Scan Item</Button>                              
                                <Button variant="outline-primary mr-3 " size="sm" type="button" onClick={() => {this.goBack()}}>Back</Button>                              
                            </div>
                            </Form.Row>
                        </Form>
                        </div>
                        <div>
                        <div>&nbsp;&nbsp;</div>
                        <MaterialTable
                            title="Last Saved Count (not yet submitted for approval)"
                            icons={tableIcons}
                            columns={this.state.columns}
                            data={this.state.data}        
                            
                            options={{
                                search: false,
                                paging: false,
                                actionsColumnIndex: -1,
                            }}

                        />
                        </div>
                        <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>                    
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        console.log('onNewScanResult', decodedText)
        decodedText = 'ITM01';
        console.log('dddd', this.state.cycle.item)
        if (decodedText === this.state.cycle.item) {
            this.setState({qtycount: this.state.qtycount + 1, showScanner: false}); 
        } else {
            alert ('You scanned the wrong item. Please scan the right item.')
        }        
    }
}
export default CountCycleItemBinItem;