import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from "./components/Login";
import HomeMenu from "./HomeMenu"
import './App.css';

export default class extends Component {
  render() {
    console.log('App');
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path={'/login'} component={Login} />
            <Route path={'/'} component={HomeMenu} /> 
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

