import React from 'react';
import "./../App.css";
import { Card, ListGroup, Badge, Form, Button} from 'react-bootstrap';
import { wmsService } from "../services/wmsService";
import Scanner from "./Scanner";

class PickingItems extends React.Component {
    constructor(props) {
        super(props);
        console.log('PickingItems', props);
        console.log('props.location.state', props.location.state);
        this.state = {    
            lp: null,
            sorder: null,            
            items: null,
            wmsgoods: null,   
            wmsempactivityreport: undefined,
            showScanner: false,  
            alreadypicked: undefined,       
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.enterItem = this.enterItem.bind(this);  
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);        
    }

    componentDidMount() {         
        //console.log('ddd', this.props.location.state);
        if (this.props.location.state && this.props.location.state.lp && this.props.location.state.sorder) {
            this.state.transfer = this.props.location.state.transfer;
            this.state.sorder = this.props.location.state.sorder;
            this.state.lp = this.props.location.state.lp;
            //this.state.items = this.state.sorder.lineItem;
            let k = [];
            if (this.state.sorder.lineItem && this.state.sorder.lineItem.length > 0) {
                this.state.sorder.lineItem.forEach(e => {
                    let i = -1;
                    for (let j=0; j< k.length; j++) {
                        let u = k[j];
                        if (u.itemId === e.itemId) {
                            i = j;
                            break;
                        }
                    }
                    if (i >=0) {
                        let it = k[i];
                        it.qty = it.qty + e.qty;
                    } else {
                        k.push(e);
                    }
                });
            }
            console.log('kkkkkkk',k);
            this.state.items = k;
            this.setState({items: k})  
            this.state.wmsempactivityreport = this.props.location.state.wmsempactivityreport;
            // Assign the bin location for each item
            if (this.state.items && this.state.items.length > 0) {
                let itms = [];                
                for (let i of this.state.items) {
                    let it = {};
                    it.item = i.item;
                    let qty = i.qty;
                    let shipped = 0;
                    if (i.qtyShipped) {
                        shipped = i.qtyShipped;
                    }
                    let returned = 0;
                    if (i.qtyReturned) {
                        returned = i.qtyReturned;
                    }
                    let cancelled = 0;
                    if (i.qtyCancelled) {
                        cancelled = i.qtyCancelled;
                    }
                    qty = qty - shipped - cancelled;
                    it.qty = qty;
                    itms.push(it);
                }
                console.log('itms', itms);
                let user = JSON.parse(localStorage.getItem('user'));
                wmsService.getBinsForPick(user.config.inventoryLocation, itms).then(witm => {
                    if (witm && witm === 401) {             
                        this.props.history.push("/login");  
                    } else if (witm && witm.length > 0) {
                        console.log('got date from getBinsForPick...', witm);                        
                        this.setState({wmsgoods: witm});  
                        let itms = [];
                        for (let wt of witm) {
                            if (wt && wt.length > 0) {
                                for (let t of wt) {
                                    let tt = {};
                                    tt.item = t.item;
                                    tt.itemid = t.itemid;
                                    tt.batch = t.batch;
                                    tt.sorder = this.state.sorder.orderNum;
                                    tt.sorderid = this.state.sorder.id;
                                    itms.push(tt);
                                }
                            }
                        }
                        console.log('itms', itms);
                        if (itms && itms.length > 0 ) {
                            wmsService.getPickedItems(user.config.inventoryLocation, itms).then(pick => {
                                if (pick && pick === 401) {             
                                    this.props.history.push("/login");  
                                } else if (pick && pick.length > 0) {
                                    console.log('pick', pick);
                                    let p = [];
                                    for (let k of pick) {
                                        if (k && k.length > 0) {
                                            for (let y of k) {
                                                p.push(y);
                                            }
                                        }
                                    }
                                    this.setState({alreadypicked: p});
                                }
                            });
                        } else {
                            // force reload
                            this.setState({sorder: this.props.location.state.sorder}); 
                        }
                    } 
                });
            }
        }
    }
    
    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();        
        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.releaseSo(user.config.inventoryLocation, this.state.sorder.id, user.username).then(witm => {
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else  {
                this.props.history.push("/picking");
            }
        });        
    }
    
    packReady = () => {
        //console.log('this.state.alreadypicked', this.state.alreadypicked);
        if (this.state.alreadypicked && this.state.alreadypicked.length > 0) {
            for (let e of this.state.alreadypicked) {
                let packed = 0;
                if (e.totalpackedqty) {
                    packed = e.totalpackedqty;
                }
                if (e.qty - packed > 0) {
                    console.log('ture');
                    return true;
                }
            }
        }
        console.log('false');
        return false;
    }

    packAndShip = () => {
        if (this.state.sorder.orderNum) {
            let user = JSON.parse(localStorage.getItem('user')); 
            ////
            if (this.packReady() == true) {
                let pitems = [];   
                for (let i of this.state.alreadypicked) {
                    let p = {};
                    p.pickid = i.id;
                    p.qty = i.qty - i.totalpackedqty;
                    if (p.qty > 0) {
                        pitems.push(p);
                    }
                }
                let packandship = {
                    sorder: this.state.sorder.orderNum,
                    sorderid: this.state.sorder.id,
                    pallet: this.state.lp,
                    warehouse: user.config.inventoryLocation,
                    userwhoupdated: user.username,
                    packitems:  pitems, 
                    wmsempactivityreport: this.props.location.state.wmsempactivityreport,  
                }
                console.log(packandship);
                wmsService.addPackAndShip(packandship).then(pord => {
                    if (pord && pord === 401) {
                        this.props.history.push("/login");
                    } else {
                        this.shipCont();
                    }
                });
            }
            ////
            else {
                this.shipCont();
            }
        } 
    }

    shipCont = () => {
        let user = JSON.parse(localStorage.getItem('user'));         
        wmsService.getShipReadyPackedItemsByOrder(this.state.sorder.orderNum, user.config.inventoryLocation).then(pord => {
            console.log('pord', pord);
            if (pord && pord === 401) {
                this.props.history.push("/login");
            } else {
                if (pord && pord.length > 0) {
                    pord[0].wmsempactivityreport = this.props.location.state.wmsempactivityreport;
                    pord[0].sorder = this.state.sorder;
                    pord[0].lp = this.state.lp;
                    this.props.history.push("/shipitems", pord[0]);
                } else {
                    alert('Order ' + this.state.sorder.orderNum + ' has no items to ship. Please pick items first and then click on ship');
                }
            }
        });

    }

    pack = () => {
        //console.log(this.packReady())
        if (this.packReady() === true) {
            // pack it...
            let user = JSON.parse(localStorage.getItem('user'));   
            let pitems = [];   
            for (let i of this.state.alreadypicked) {
                let p = {};
                p.pickid = i.id;
                p.qty = i.qty - i.totalpackedqty;
                if (p.qty > 0) {
                    pitems.push(p);
                }
            }
            let packandship = {
                sorder: this.state.alreadypicked[0].sorder,
                sorderid: this.state.alreadypicked[0].sorderid,
                pallet: this.state.alreadypicked[0].licenseplate,
                warehouse: user.config.inventoryLocation,
                userwhoupdated: user.username,
                packitems:  pitems, 
                wmsempactivityreport: this.props.location.state.wmsempactivityreport,                    
            }
            if (this.state.sorder.transfernum) {
                packandship.transfer = 0;
            }
            console.log('packandship', packandship);
            
            wmsService.addPackAndShip(packandship).then(pord => {
                if (pord && pord === 401) {
                    this.props.history.push("/login");
                } else {
                    if (this.state.sorder && this.state.sorder.transfernum) {
                        this.state.tranfer = 0;
                    }
                    var { from } = { from: { pathname: "/packinglabelprint" } };  
                    this.props.history.push(from, this.state);
                }
            });
        } else {
            alert ('Failed - no items to pack. You should first pick items then click Pack.')
        }
    }

    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }

    scan =() => {
        console.log('Scan is called');
    }

    enterItem = (e) => {
        if (e && e.buttonText.trim() == "Not Found") {
            alert('There is no stock in the inventory to pick this item!')
            return;
        }
        //alert('Enter item qty', e);
        //receiveitmqty
        //var { from } = { from: { pathname: "/receiveitmqty" } }; 
        if (Number(e.receivableQty) > Number(e.currentRcvedQty)) {
            e.status = 1;
            e.ordertype = 2;
            e.licenseplate = this.state.lp; 
            e.sorder = this.state.sorder.orderNum;
            e.wmsempactivityreport = this.state.wmsempactivityreport;
            let bins = this.getBin(e.item);
            let bb = [];
            for (let b of bins) {
                bb.push(b.bin);
            }
            e.bins = bb;
            //this.props.history.push("/pickitmqty", e);  
            console.log(e);
            e.transfer = this.props.location.state.transfer;
            this.props.history.push("/pickitemsb", e);  
        } else {
            alert ('Nothing is left to pick this.')
        }
    }

    getBin(item) {
        let res = [];
        //console.log('getBin', item, this.state.wmsgoods);
        if (item && this.state.wmsgoods && this.state.wmsgoods.length > 0) {
            for (let i = 0; i < this.state.wmsgoods.length; i++) {
                //console.log('loop: ', i);
                let y = this.state.wmsgoods[i];
                if (y && y.length > 0) {
                    for (let x of y) {
                        console.log('x.item...1', x, item);
                        let b = {}
                        if (x && x.id && this.state.alreadypicked && this.state.alreadypicked.length > 0) {
                            console.log('this.state.alreadypicked', this.state.alreadypicked);
                            for (let pp of this.state.alreadypicked) {
                                if (x.id === pp.wmsgoodid && x.item === item) {
                                    b.pickedqty = pp.qty;
                                    b.totalpackedqty = pp.totalpackedqty;
                                    b.totalshippedqty = pp.totalshippedqty;
                                    break;
                                }
                            }                            
                        }
                        if (x && x.item) {
                            console.log('x.item...2', x.item, item);
                            if (x.item === item) {
                                if (x.parentbin) {
                                    b.bin = x.parentbin;
                                    //res.push(x.parentbin);
                                } else if (x.bin) {
                                    b.bin = x.bin;
                                    //res.push(x.bin);
                                }
                            }
                        }
                        if (Object.keys(b).length != 0) {
                            res.push(b);
                        }
                    }
                }                
            }
        }
        return res;
    }

    render() {
        console.log('PickItems', this.state);
        let user = JSON.parse(localStorage.getItem('user'));        
        if (!this.state.sorder) {
            return (<div></div>);
        }
        let ritms = (<ListGroup as="ol" numbered="true"></ListGroup>);
        if (this.state.items && this.state.items.length > 0) {
            let itms = [];
            for (let ii=0; ii<this.state.items.length; ii++) {
                let i = this.state.items[ii];
                i.currentRcvedQty = 0;
                let qty = i.qty;
                let shipped = 0;
                if (i.qtyShipped) {
                    shipped = i.qtyShipped;
                }
                let returned = 0;
                if (i.qtyReturned) {
                    returned = i.qtyReturned;
                }
                let cancelled = 0;
                if (i.qtyCancelled) {
                    cancelled = i.qtyCancelled;
                }
                qty = qty - shipped - cancelled;                
                i.receivableQty = qty;
                let bins = this.getBin(i.item);
                console.log('bins', bins);
                let bin = '';
                i.qtytopick = qty; // initialize it here: if bin is not found then this will be used.
                i.qtypickedbutnotpacked = 0;
                i.qtypackedbutnotship = 0;
                if (bins && bins.length > 0) {
                    let pickedQty = 0;
                    let packedQty = 0;
                    let shippedQty = 0;
                    let bb = [];
                    for (let b of bins) {
                        bb.push(b.bin);
                        if (b.pickedqty) {
                            pickedQty = pickedQty + b.pickedqty;
                            packedQty = packedQty + b.totalpackedqty;
                            shippedQty =  shippedQty + b.totalshippedqty;
                        }
                    }
                    //i.qtytopick = qty - pickedQty;
                    i.qtypickedbutnotpacked = pickedQty - packedQty;
                    i.qtypackedbutnotship = packedQty - shippedQty;
                    i.qtytopick = qty - i.qtypickedbutnotpacked;
                    //i.bins = bins;
                    i.bins = bb;
                    //bin = bins[0];
                    console.log('bb', bb);
                    bin = bb[0]
                    let pad = 12 - bin.length;
                    if (pad > 0) {
                        if(pad % 2 == 0) {
                            let p = pad / 2;
                            for (let i=0; i<p; i++) {
                                bin = ' ' + bin;
                            }
                            for (let i=0; i<p; i++) {
                                bin = bin + ' ';
                            }
                        } else {
                            let p = pad / 2;
                            let pp = parseInt('' + p);
                            for (let i=0; i<pp; i++) {
                                bin = ' ' + bin;
                            }
                            let len = pp+1;
                            if (bins.length > 1) {
                                len = pp;
                            }
                            
                            for (let i=0; i<len; i++) {
                                bin = bin + ' ';
                            }

                            if (len === pp) {
                                bin = bin + '..';
                            }
                        }
                    }
                } else {
                    bin = ' Not Found  ';
                }
                i.buttonText = bin;
                console.log('i', i);
                if (i.qtytopick > 0) {
                    itms.push(<ListGroup.Item key={ii.toString()}
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                        <div className="font-weight-bold">{i.item}</div>
                        {i.description}
                        <div>Ordered qty: <span className="font-weight-bold">{i.receivableQty}</span></div>
                        <div>Crrently picked: <span className="font-weight-bold">{i.qtypickedbutnotpacked}</span></div>
                        <div>Qty in packing: <span className="font-weight-bold">{i.qtypackedbutnotship}</span></div>
                        </div>
                        
                        <div style={{ display: "flex" }}>
                            <div>
                                <Button style={{minWidth : "80px",  marginRight: "auto" }} variant="outline-secondary" size="sm" type="button" onClick={() => {this.enterItem(i)}}>
                                    {bin}
                                </Button>
                            &nbsp;&nbsp;</div>                        
                        </div>
                    </ListGroup.Item>);
                }
              }
              ritms = (<ListGroup as="ol" numbered="true">
                  {itms}
              </ListGroup>);
        }
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Picking Items                        
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text>
                            {(this.state.sorder.transfernum) && 
                                <div className="font-weight-bold">Transfer: {this.state.sorder.transfernum}</div>
                            }
                            {(!this.state.sorder.transfernum) && 
                                <div className="font-weight-bold">Sales Order: {this.state.sorder.orderNum}</div>
                            }
                            {(!this.state.sorder.transfernum) && 
                                <div className="font-weight-bold">Customer: {this.state.sorder.clientName}</div>
                            }
                            <div className="font-weight-bold">License Plate: {this.state.lp}</div></Card.Text>
                            {ritms}                            

                            <div style={{float: "left"}} >                                       
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.goBack()}}>Back</Button> 
                                {!this.state.sorder.transfernum &&                              
                                <Button variant="outline-primary mr-3 mt-3" size="sm" type="submit">Release Order</Button>                              
                                }
                                {user.config.wmsconfig && user.config.wmsconfig.picktoship &&
                                   <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.packAndShip()}}>Ship Order</Button>                               
                                }
                                {user.config.wmsconfig && !user.config.wmsconfig.picktoship &&
                                   <Button variant="outline-primary mr-3 mt-3" size="sm" type="button" onClick={() => {this.pack()}}>Pack Order</Button>                               
                                }
                                
                            </div>
                        </Form>    
                        <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>                    
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        let e = {};
        let found = false;
        if (this.state.items && this.state.items.length > 0) {            
            for (let ii=0; ii<this.state.items.length; ii++) {
                let i = this.state.items[ii];
                if (i.item === decodedText) {
                    e = i;
                    found = true; 
                    break;
                }
            }
            if (!found) {
                alert('No item in the order matched with the scanned item!');
                return;
            }
        }

        if (Number(e.receivableQty) > Number(e.currentRcvedQty)) {
            e.status = 1;
            e.ordertype = 2;
            e.licenseplate = this.state.lp; 
            this.props.history.push("/pickitmqty", e);  
        }

        this.setState({showScanner: false, purchaseorder: decodedText}); 
    }
}
export default PickingItems;