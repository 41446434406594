import React, { forwardRef } from "react";
import "./../App.css";
import { Card, Col, InputGroup, Form, Button} from 'react-bootstrap';
import { wmsService } from "../services/wmsService";
import Scanner from "./Scanner";
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { TramOutlined } from "@material-ui/icons";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

class CountCycleBinStart extends React.Component {
    constructor(props) {
        super(props);       
        this.state = {    
            count: new Map(),
            scanitem: null,
            cycle: null,                          
            showScanner: false,  
            reload: 0,
            selectedrows: undefined,
            data: [],
            columns: [
                {
                  field: 'id',
                  title: 'id',  
                  hidden: true,        
                }, {
                    field: 'bin',
                    title: 'Bin',  
                  },{
                    field: 'item',
                    title: 'Item',
                  },
                  {
                  field: 'batch',
                  title: 'Batch',              
                },
                {
                    field: 'qty',
                    title: 'Cyl Count',
                  },                                         
              ],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);  
        this.approval = this.approval.bind(this);   
        this.confirm = this.confirm.bind(this);   
        this.getCurrentCount = this.getCurrentCount.bind(this);   
        this.deleteRow = this.deleteRow.bind(this);
    }

    componentDidMount() {  
        console.log('props.location.state', this.props.location.state);       
        if (this.props.location.state ) {
            this.setState({cycle: this.props.location.state});
        }        

        let user = JSON.parse(localStorage.getItem('user'));
        wmsService.getCycleCounts(user.config.inventoryLocation).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                this.setState({data: witm});
            }             
        });
    }
    
    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

      deleteRow(row) {
        console.log(row)
        wmsService.removeCycleCount(row.id).then(witm => {
            console.log(witm);
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else {
                return true;
            }             
        });
    } 

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.scanitem) {
            alert ('You must provide the item number')
        } else if (!this.state.count.get(this.state.scanitem)) {
            alert ('You must provide the quantity')
        } else {
            let user = JSON.parse(localStorage.getItem('user'));
       
            let obj = {};
            obj.invlocation = user.config.inventoryLocation
            obj.bin = this.state.cycle.bin;
            obj.item = this.state.scanitem;
            //obj.batch
            obj.qty = this.state.count.get(this.state.scanitem);                 
            obj.userwhoupdated = user.username;
    
            wmsService.createCycleCount(obj).then(witm => {
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    alert ('Your count for item ' + obj.item + ' is saved.');
                    this.setState({scanitem: undefined, qtyinp: undefined, iteminp: undefined, qtyinp: undefined});
                    wmsService.getCycleCounts(user.config.inventoryLocation).then(witm => {
                        console.log(witm);
                        if (witm && witm === 401) {             
                            this.props.history.push("/login");  
                        } else {
                            this.setState({data: witm});
                        }             
                    });
                    this.setState({reload: this.state.reload + 1});
                    this.state.count.clear();
                }             
            });
        }        
    }
    
    goBack =() => {
        console.log(this.props);
        this.props.history.goBack();
    }

    approval() {
        console.log('approval is called...')
        if (this.state.count.size > 0) {
            alert ('Please save the items before submitting for approval');
        } else if (!this.state.selectedrows || this.state.selectedrows.length === 0) {
            alert ('Please select items to submit for approval');
        } else {
            console.log(this.state.selectedrows);
            let obj = [];
            for (let r of this.state.selectedrows) {
                let tmp = {};
                tmp.id = r.id;
                tmp.qty = r.qty;
                tmp.bin = r.bin;
                tmp.item = r.item;
                tmp.invlocation = r.invlocation;
                tmp.approve = 1;
                obj.push(tmp);
            }
            wmsService.approveCycleCount(obj).then(witm => {
                console.log(witm);
                if (witm && witm === 401) {             
                    this.props.history.push("/login");  
                } else {
                    alert ("Successfully submitted for approval");
                    this.setState({selectedrows: undefined});
                    let user = JSON.parse(localStorage.getItem('user'));
                    wmsService.getCycleCounts(user.config.inventoryLocation).then(witm => {
                        console.log(witm);
                        if (witm && witm === 401) {             
                            this.props.history.push("/login");  
                        } else {
                            this.setState({data: witm});
                        }             
                    });
                }             
            });
        }
    }

    getCurrentCount() {
        if (this.state.scanitem) {
            let count = this.state.count.get(this.state.scanitem);
            return count;
        }
    }

    confirm() {
        if (this.state.iteminp && this.state.qtyinp) {
            let count = this.state.count.get(this.state.iteminp);
            if (count) {
                count = Number(count) + Number(this.state.qtyinp);
                this.state.count.set(this.state.iteminp, count);
            } else {
                count =  Number(this.state.qtyinp);
                this.state.count.set(this.state.iteminp, count);
            }
            this.setState({scanitem: this.state.iteminp});
            this.state.qtyinp = undefined;    
        }
    }

    
    render() {
        //console.log('PickItems', this.state);
        if (!this.state.cycle) {
            return (<div></div>);
        }
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Cycle Count By Bin                        
                    </Card.Header>
                    <Card.Body>
                        <div key={this.state.reload}>
                        <Form onSubmit={this.handleSubmit}>
                            <Card.Text>
                                <div className="font-weight-bold">Bin: {this.state.cycle.bin}</div>
                                <div className="font-weight-bold">Item: {this.state.scanitem}</div>
                                <div className="font-weight-bold">Count: {this.getCurrentCount()}</div>
                            </Card.Text>                                                       
                            <Form.Row>
                            <Form.Group as={Col} controlId="iteminp" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Item #</Form.Label>
                                <Form.Control required={true} placeholder="Enter item code" defaultValue={this.state.iteminp} />                                
                            </Form.Group>
                            </Form.Row> 
                            <Form.Row>
                            <Form.Group as={Col} controlId="qtyinp" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Quantity #</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control required={true} placeholder="Enter item quantity" defaultValue={this.state.qtyinp} />                                
                                    <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.confirm()}}>
                                        Confirm
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            </Form.Row>
                            <Form.Row>
                            <div style={{float: "left"}} >                                                                       
                                <Button variant="outline-primary mr-3 " size="sm" type="submit">Save</Button>                              
                                <Button variant="outline-primary mr-3 " size="sm" type="button" onClick={() => {this.scan()}}>Scan Item</Button>                              
                                <Button variant="outline-primary mr-3 " size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>
                            </Form.Row>
                        </Form>
                        </div>
                        <div>
                        <div>&nbsp;&nbsp;</div>
                        <MaterialTable key={this.state.count}
                            title="Last Saved Count"
                            icons={tableIcons}
                            columns={this.state.columns}
                            data={this.state.data}        
                            
                            options={{
                                search: false,
                                paging: false,
                                actionsColumnIndex: -1,
                                selection: true,
                            }}
                            onSelectionChange={(rows) => this.state.selectedrows = rows}
                        />
                        <Button variant="outline-primary mr-3 mt-2" size="sm" type="button" onClick={() => {this.approval()}}>Submit for Approval</Button>
                        </div>
                        <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>                    
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        let count = this.state.count.get(decodedText);
        if (count) {
            this.state.count.set(decodedText, count+1);
        } else {
            this.state.count.set(decodedText, 1);
        }      
        this.setState({scanitem: decodedText});
        this.setState({showScanner: false}); 
    }
}
export default CountCycleBinStart;