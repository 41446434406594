import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";
import { FormatAlignCenter } from '@material-ui/icons';

class PickingScanBin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {               
            lp: undefined,
            sorder: undefined,
            wmsempactivityreport: undefined,
            enableorderscanner: false,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);    
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);    
            
    }

    componentDidMount() { 
        let user = JSON.parse(localStorage.getItem('user'));            
        //console.log('LOC:', (user.config));
        if (user.config.wmsconfig && user.config.wmsconfig.outboundorderscan && user.config.wmsconfig.outboundorderscan === true) {
            this.setState({enableorderscanner: true});
        } 
        if (this.props.location.state && this.props.location.state.sorder) {
            this.setState({sorder: this.props.location.state.sorder, wmsempactivityreport: this.props.location.state.wmsempactivityreport});
            this.state.transfer = this.props.location.state.transfer;
        }
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        
        if (!this.state.lp) {
            alert ('Please enter or scan license plate');
            return;
        }
        this.state.sorder.lp = this.state.lp;
        this.state.transfer = this.props.location.state.transfer;
        console.log('go...', this.state)
        var { from } = { from: { pathname: "/pickitems" } };  
        this.props.history.push(from, this.state);    
    }
    
    goBack =() => {
        this.props.history.goBack();
    }

    render() {        
        if (!this.state.sorder) {
            return (<div></div>);
        }
        console.log(this.state);

        return (            
            <div>                
                <Card>
                    <Card.Header>Picking Order - License Plate                        
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <div className="font-weight-bold">Sales Order: {this.state.sorder.orderNum}</div>
                            <div className="font-weight-bold">Customer: {this.state.sorder.clientName}</div>                            
                        </Card.Text>  
                      <Form onSubmit={this.handleSubmit}>                                            
                        <Form.Row>
                            <Form.Group as={Col} controlId="lp" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>License Plate</Form.Label>
                                <Form.Control required={true} placeholder="Scan or enter license plate" defaultValue={this.state.lp} autoFocus />                                                                    
                            </Form.Group>
                        </Form.Row> 
                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                                                      
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.scan()}}>Scan</Button>
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, lp: decodedText}); 
    } 
}
export default PickingScanBin;