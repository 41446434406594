import React from "react";
import Layout from './components/Layout'
import Routes from './Routes'


class HomeMenu extends React.Component {
  
  render() {
    return (
      <div>
        <Layout>
          <Routes />
        </Layout>
      </div>
    );
  }
}

export default HomeMenu;
