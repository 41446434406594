import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";

class PickingItemAndQuantityItemBatch extends React.Component {
    constructor(props) {
        super(props);
        console.log('PickingItemAndQuantity', props);
        this.state = {    
            item: {},   
            qty: undefined,
            itemcode: undefined,
            bin: undefined,
            batch: undefined, 
            batchopt: undefined,
            expirationdate: undefined,
            binInput: true,
            itemInput: false,
            qtyInput: true,
            qrcodescan: false,
            resetkey: 1,
            showScanner: false,
            timer: null,
            batchoptions: [{batch: 'Select one', expirationdate: undefined}],
        };        
        this.goBack = this.goBack.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.scanAny = this.scanAny.bind(this);
        this.onNewScanResult = this.onNewScanResult.bind(this);
        this.closeScanner = this.closeScanner.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.next = this.handleNext.bind(this);
        this.getBatches = this.getBatches.bind(this);
    }

    componentDidMount() {       
        console.log('PickingItemAndQuantity...1', this.props);  
        if (this.props && this.props.location && this.props.location.state) {
            this.state = this.props.location.state;
            //this.state.bin = this.props.location.state.bin;
            this.setState({item: this.props.location.state.item, bin: this.props.location.state.bin});            
            console.log('state', this.state); 
            if (this.state.bin) {
                this.getBatches();
            }            
        }               
    }

    handleAndVerifyItemCode = (event) => {   
        const target = event.target;
        const name = target.id;           
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({timer: null});
        }
        this.setState({timer:
            setTimeout(() => {
                this.setState({itemcode: target.value});
                console.log('timer', this.state.itemcode, target.value);
                this.handleNext();     
                event.preventDefault();
            }, 3000)}
        );  
        
        if (event.key === 'Enter' || event.key === "Tab") {            
            event.preventDefault();        
        }        
    }

    getBatches = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false) { 
            wmsService.getPickingBatch(this.state.bin, this.state.item.item, user.config.inventoryLocation).then(witm => {
                console.log('batch', witm);
                if (witm && witm === 401) {
                    this.props.history.push("/login");
                } else {
                    this.setState({batchoptions: witm});
                }
            });                             
        }
    }

    handleNext() {
        //let user = JSON.parse(localStorage.getItem('user'));
        //console.log(this.state.binInput, this.state.itemInput, this.state.qtyInput);
        if (!this.state.binInput) { //disabled is false - meaning it is active
            
            if (!this.state.bin) {
                //alert("Your input is not correct. Please enter or scan correct bin")
                //this.setState({blur: this.state.blur}); // just a reloading react component technicue
            } else {   
                // if (user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false) { 
                //     wmsService.getPickingBatch(this.state.bin, this.state.item.item).then(witm => {
                //         console.log('batch', witm);
                //         if (witm && witm === 401) {
                //             this.props.history.push("/login");
                //         } else {
                //             this.setState({batchoptions: witm});
                //         }
                //     });                             
                // }
                this.getBatches();
                this.setState({binInput: true, itemInput: false});
            }
        } else if (!this.state.itemInput) {
            console.log(this.state.itemcode, this.state.item);
            if (!this.state.itemcode || this.state.itemcode !== this.state.item.item) {
                //alert("Your input is not correct. Please enter or scan correct item")
                this.setState({itemcode: ''});
                this.setState({resetkey: this.state.resetkey+1})
            } else {                
                this.setState({binInput: true, itemInput: true, qtyInput: false});
            }
        } else {
            if (!this.state.qty || this.state.qty <= 0 || this.state.qty > this.state.item.receivableQty) {
                alert("Your input is not correct. Please enter correct quantity")
            } else {                
                this.setState({binInput: true, itemInput: true, qtyInput: true});
            }
        }
        console.log('binInput', this.state.binInput, ' itemInput', this.state.itemInput, ' qtyInput', this.state.qtyInput);
    }

    next() {
        if (!this.state.binInput) { //disabled is false - meaning it is active
            this.setState({binInput: true, itemInput: false});
        } else if (!this.state.itemInput) {
            this.setState({binInput: true, itemInput: true, qtyInput: false});
        }
    }    

    goBack =() => {  
        alert('goBack...')      
        this.props.history.goBack();
    }

    scanAny = () => {
        this.setState({showScanner: true});
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        //console.log('Scanned Result', decodedText, decodedResult);                
        if (this.state.binInput === false) { //disabled is false - user scan bin number
            this.setState({showScanner: false, bin: decodedText, binInput: true, itemInput: false, qtyInput: true});
        } else if (this.state.itemInput === false) { //disabled is false -  user scan item code
            if (decodedText !== this.state.item.item) {
                alert ('Please enter or scan a correct item code.');                 
            } else {
                this.setState({showScanner: false, itemcode: decodedText, binInput: true, itemInput: true, qtyInput: false});
            }
        }   
    }

    resetInput = () => {        
        this.setState({binInput: false, itemInput: true, qtyInput: true,
            qty: undefined, itemcode: '', bin: ''});
        this.setState({resetkey: this.state.resetkey+1})
    }

    closeScanner() {
        this.setState({showScanner: false})
    }

    render() {
        //console.log('qty',this.state.qty, this.state.itemcode);
        let bins = 'Not found';
        if (this.state.item && this.state.item.bins) {
            for (let b of this.state.item.bins) {
                if (bins.length > 0) {
                    if (bins === 'Not found') {
                        bins = '';
                    } else {
                        bins = bins + ' | '
                    }
                }
                bins = bins + b;
            }
        }

        const opt = [];
        for (let p of this.state.batchoptions) {
            //console.log(p);
            let expd = undefined;
            if (p.expirationdate) {
                let date = new Date(p.expirationdate);
                expd = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
            }
            let k = (p.batch === '0' ? 'NA' : p.batch);
            if (expd) {
                k = k + ' - ' + expd;
            }
            opt.push(<option value={k.batch}>{k}</option>)            
        }

        let user = JSON.parse(localStorage.getItem('user'));
        console.log(user.config);
        return (
            
            <div key={this.state.resetkey}>                
                <Card>
                    <Card.Header>Picking Items from Bin</Card.Header>
                    <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Bin: {bins}</Form.Label>
                            </Form.Row>
                            <Form.Row>
                            <Form.Label style={{fontSize: "14px", fontWeight: 'bold'}}>&nbsp;Item Code: {this.state.item.item}</Form.Label>
                            </Form.Row>
                                                       
                            <Form.Row>
                                <Form.Group as={Col} controlId="bin"> 
                                    {/* onKeyDown={this.handleInputChangeBin}> */}
                                <Form.Label style={{fontSize: "14px"}}>Enter or Scan Bin</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control disabled={true} required={true} autoComplete="off" placeholder="Enter or scan bin" defaultValue={this.state.bin} />                           
                                </InputGroup>
                                </Form.Group>
                            </Form.Row>
                                                       
                            <Form.Row>
                                <Form.Group as={Col} controlId="itemcode" 
                                    onKeyDown={this.handleAndVerifyItemCode}>
                                <Form.Label style={{fontSize: "14px"}}>Enter or Scan Item</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control  autoFocus={this.state.itemInput} disabled={this.state.itemInput} required={true} autoComplete="off" placeholder="Enter or scan Item" defaultValue={this.state.itemcode} autoFocus />
                                </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            {(this.state.binInput === true && this.state.itemInput === true) &&   
                            <>
                            <Form.Row>
                                <Form.Group as={Col} controlId="qty" 
                                    onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Quantity</Form.Label>
                                <Form.Control required={true} placeholder="Enter quantity receiving" defaultValue={this.state.qty} />
                                </Form.Group>
                            </Form.Row> 
                            {(user.config.wmsconfig && user.config.wmsconfig.batchnotsupport === false ) &&
                            <Form.Row>                   
                                <Form.Group as={Col} controlId="batch" onChange={this.handleInputChange}>
                                    <Form.Label style={{fontSize: "14px"}}>Select Existing Batch</Form.Label>
                                    <Form.Control as="select" defaultValue={this.state.batch}>{opt}</Form.Control> 
                                </Form.Group>
                            </Form.Row> 
                            }
                            </>
                            }
                            <div style={{float: "left"}} >     
                            {(this.state.binInput === false || this.state.itemInput === false) &&                                  
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.next()}}>Next</Button>  
                            }
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.scanAny()}}>Scan</Button>                                                                               
                            <Button variant="outline-primary mr-3" size="sm" type="button" onClick={() => {this.goBack()}}>Cancel</Button>                              
                            </div>
                            
                            <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }
}
export default PickingItemAndQuantityItemBatch;