import React from 'react';
import "./../App.css";
import { Card, Button, Form, Col, InputGroup, Modal } from 'react-bootstrap';
import { wmsService } from "../services/wmsService"
import Scanner from "./Scanner";
import { FormatAlignCenter } from '@material-ui/icons';

class Picking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {    
            sonum: null,        
            shipings: null,
            zone: undefined,
            whzones: undefined,
            lp: undefined,
            sorder: undefined,
            enableorderscanner: false,
            showScanner: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.goBack = this.goBack.bind(this);    
        this.onNewScanResult = this.onNewScanResult.bind(this);  
        this.scan = this.scan.bind(this);    
        this.closeScanner = this.closeScanner.bind(this);    
            
    }

    componentDidMount() { 
        let user = JSON.parse(localStorage.getItem('user'));         
        //let whz = this.getZones(user.config.inventoryLocation);
        //this.setState({whzones: whz});   
        //console.log('LOC:', (user.config));

        const { sonum } = this.props.match.params        
        if (sonum) {
            this.state.sonum = sonum
        }

        if (user.config.wmsconfig && user.config.wmsconfig.outboundorderscan && user.config.wmsconfig.outboundorderscan === true) {
            this.setState({enableorderscanner: true});
        }
        wmsService.getActiveShippingOrder(user.config.inventoryLocation).then(witm => {
            if (witm && witm === 401) {             
                this.props.history.push("/login");  
            } else if (witm && witm.length > 0) {
                //console.log('got date from getActiveShippingOrder...', witm);
                this.setState({shipings: witm});                
            } 

            wmsService.getZones(user.config.inventoryLocation).then(z => {
                if (z && z === 401) {             
                    this.props.history.push("/login");  
                } else if (z && z.length > 0) {
                    //console.log('got date from getZones...', z);
                    this.setState({whzones: z});                
                } 
            });
        });
        
    }

    handleInputChange = (event) => {    
        const target = event.target;
        const name = target.id;   
        this.setState({
          [name]: target.value
        });   
      }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.sonum || this.state.sonum === 'Select...') {
            alert('You must selct a Sales Order');
            return;
        }

        if (!this.state.zone || this.state.zone === 'Select...') {
            this.state.zone = null;
        }

        // if (!this.state.lp) {
        //     alert ('Please enter or scan license plate');
        //     return;
        // }

        let soid = null;
        for (let s of this.state.shipings) {
            if (this.state.sonum === s.sonumber) {
                soid = s.soid;
                break;
            }
        }
        let user = JSON.parse(localStorage.getItem('user'));
        //
        // First call getPurchaseOrder to see if the PO exists. If not then display error. 
        // If found, then this call also reserves the PO for this user so that other user will not
        // work on it. If this user does not finish picking process he should able to discard the picking
        // so that other user can pick this order. If an order is left middle of picking... user can
        // go back and continue the picking process where they left.
        //
        wmsService.getSoForPick(user.username, soid, user.config.inventoryLocation, this.state.zone).then(sorder => {
            console.log(sorder);
            if (sorder && sorder === 401) {
                this.props.history.push("/login");
            } else {
                if (sorder.lineItem && sorder.lineItem.length > 0) {
                    this.state.sorder = sorder;
                    let wmsempactivityreport = {};
                    wmsempactivityreport.invlocation = user.config.inventoryLocation;
                    wmsempactivityreport.wmsorder = sorder.orderNum;
                    wmsempactivityreport.employee = user.username
                    wmsempactivityreport.type = 1;
                    wmsempactivityreport.starttime = (new Date()).toISOString();
                    this.state.wmsempactivityreport = wmsempactivityreport;
                    var { from } =  { from: { pathname: "/pickbinscan" } };  
                    this.props.history.push(from, this.state);
                } else {
                    alert('Sales order is not found. It could be being picked by other user');
                    return;
                }
            }
        });        
    }
    
    goBack =() => {
        var { from } = { from: { pathname: "/outbound" } };  
        this.props.history.push(from, this.state);
    }

    render() {    
           
        let ships = [];
        ships.push(<option key={0}>Select...</option>);
        if (this.state.shipings && this.state.shipings.length > 0) {
            let i =1;
            for (let x of this.state.shipings) {                
                ships.push(<option key={i}>{x.sonumber}</option>);
                i++;
            }            
        }

        const zz = [];
        zz.push(<option key={0}>Select...</option>);
        if (this.state.whzones && this.state.whzones.length > 0) {
            let i = 1;
            for (let x of this.state.whzones) {
                zz.push(<option key={i}>{x.zone}</option>);
                i++;
            }            
        }
        
        return (
            
            <div>                
                <Card>
                    <Card.Header>Picking Order                        
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={this.handleSubmit}>
                      <Form.Row>
                            <Form.Group as={Col} controlId="zone" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Zone</Form.Label>
                                <Form.Control as="select" defaultValue={this.state.zone} >
                                        {zz}                            
                                </Form.Control>            
                            </Form.Group>
                        </Form.Row>
                        {this.state.enableorderscanner === false &&
                        <Form.Row>
                            <Form.Group as={Col} controlId="sonum" onChange={this.handleInputChange}>
                                <Form.Label style={{fontSize: "14px"}}>Sales Order</Form.Label>
                                <Form.Control as="select" defaultValue={this.state.sonum} value={this.state.sonum}>
                                        {ships}                            
                                </Form.Control>            
                            </Form.Group>
                        </Form.Row>                        
                        }
                        {this.state.enableorderscanner === true &&
                        <Form.Row>
                            <Form.Group as={Col} controlId="sonum" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>Sales Order</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control required={true} placeholder="Scan or enter sales order" defaultValue={this.state.sonum} />                                    
                            </InputGroup>

                            
                            </Form.Group>
                        </Form.Row>
                        }
                        {/* <Form.Row>
                            <Form.Group as={Col} controlId="lp" onChange={this.handleInputChange}>
                            <Form.Label style={{fontSize: "14px"}}>License Plate</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control required={true} placeholder="Scan or enter license plate" defaultValue={this.state.lp} />                                    
                                <Button variant="outline-secondary" id="button-addon2" type="button" onClick={() => {this.scan()}}>
                                    Scan
                                </Button>
                            </InputGroup>
                            </Form.Group>
                        </Form.Row>  */}

                        <div style={{float: "left"}} >                                       
                        <Button variant="outline-primary mr-3" size="sm" type="submit">Next</Button>                       
                        {this.state.enableorderscanner === true &&                              
                        <Button variant="outline-primary mr-3" size="sm" id="button-addon2" type="button" onClick={() => {this.scan()}}>Scan</Button>
                        }
                        <Button variant="outline-primary mr-3" size="sm" onClick={() => {this.goBack()}}>Cancel</Button>                              
                  </div>
                      </Form>
                      <Scanner showScanner={this.state.showScanner} closeScanner={this.closeScanner} onNewScanResult={this.onNewScanResult}></Scanner>
                    </Card.Body>
                </Card>
            </div>
            
        );
    }

    scan =() => {
        //console.log('Scan item is called');        
        this.setState({showScanner: true});
    }
    
    closeScanner() {
        this.setState({showScanner: false})
    }

    onNewScanResult(decodedText, decodedResult) {
        // Handle the result here.
        console.log('Scanned Result', decodedText, decodedResult);  
        this.setState({showScanner: false, sonum: decodedText}); 
    } 
}
export default Picking;