import React, { forwardRef } from "react";
import { Button } from 'react-bootstrap';
import "./../App.css";
import { customerService } from '../services/customerService';

import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Update from '@material-ui/icons/CheckCircle';
import { TramOutlined } from "@material-ui/icons";
import { invoiceService } from "../services/invoiceService";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Update: forwardRef((props, ref) => <Update {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class InvoiceCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: new Map(),
            scanitem: null,
            cycle: null,
            showScanner: false,
            reload: 0,
            selectedrows: undefined,
            warehouse: undefined,
            data: [],
            totalCount: 0,
            page: 0,
            columns: [                
                {
                    field: 'name',
                    title: 'Customer',
                    editable: false,
                },
                {
                    field: 'country',
                    title: 'Address',
                    render: rowData => { if (rowData.contact && rowData.contact.length > 0) { return rowData.contact[0].addr1 + ", " + rowData.contact[0].city} },
                    editable: false,
                },                
            ],            
        };
        this.goBack = this.goBack.bind(this);
        this.addInvoice = this.addInvoice.bind(this);
    }

    componentDidMount() {
    }

    goBack = () => {
        this.props.history.goBack();
    }

    addInvoice = (client) => {
        let user = JSON.parse(localStorage.getItem('user'));         
        let invoice = {};
        invoice.amountInvoiced = 0;
        invoice.billToId = client.contact[0].id;
        invoice.clientId = client.id;
        invoice.clientName = client.name;
        invoice.companyId = user.config.companyid;
        invoice.currencyCode = client.currency;
        invoice.email = client.contact[0].email;
        invoice.exchangeRate = 1;
        //invoice.exchangeRateDate: "Sep 6, 2022"
        //id: 2180
        //invdate: "Sep 6, 2022"
        //invoiceId: "22-XXXX-56"
        invoice.lineItem = [];
        invoice.outstandingAmount = 0;        
        invoice.shippToId =  client.contact[0].id;        
        invoice.status = 1;
        invoice.type = '.1';
        //console.log(invoice);
        invoiceService.createInvoice(invoice).then(inv => {
            if (inv && inv === 401) {
              this.props.history.push("/login");
            } else {    
                console.log(inv);      
                var { from } = this.props.location.state || { from: { pathname: "/InvoiceView" } };
                this.props.history.push(from, inv);
            }
          });
    }

    render() {
        return (
            <div>

                <MaterialTable key={this.state.count}
                    title="Customers"
                    icons={tableIcons}
                    columns={this.state.columns}
                    //data={this.state.data}
                    data={query => (
                        new Promise((resolve, reject) => {
                            console.log('query', query)
                            if (query.search && query.search.length > 0) {
                                customerService.searchCustomer(query.page * query.pageSize, query.pageSize, query.search).then(result => {
                                    if (result && result === 401) {
                                        this.props.history.push("/login");
                                    } else {
                                        resolve({
                                            data: result,
                                            page: 0,
                                            totalCount: result.length,
                                        });
                                    }
                                })
                            } else {
                                customerService.getBatchCustomerList(query.page * query.pageSize, query.pageSize).then(result => {
                                    if (result && result === 401) {
                                        this.props.history.push("/login");
                                    } else {
                                        console.log(result);
                                        resolve({
                                            data: result,
                                            page: 0,
                                            totalCount: result.length,
                                        });
                                    }
                                })
                            }
                        })
                    )}

                    options={{
                        search: true,
                        paging: true,
                        actionsColumnIndex: -1,
                        pageSize: 40,
                        exportButton: false,
                        debounceInterval: 1500,
                    }}
                    //editable={this.state.editable}   
                    localization={{
                        toolbar: {
                            searchPlaceholder: 'Customer name'
                        },
                        body: {
                            editTooltip: 'Create Invoice'
                        }
                    }}
                    actions={[
                        {
                            icon: () => <ViewColumn />,
                            tooltip: "Add Invoice",
                            onClick: (event, rowData) => {
                                console.log(rowData);
                                this.addInvoice(rowData);
                            },
                        },
                        {
                            icon: () => <AddBox />,
                            tooltip: 'Create Customer',
                            isFreeAction: true,
                            onClick: () => {
                                var { from } = this.props.location.state || { from: { pathname: "/createcustomer" } };
                                this.props.history.push(from);
                            }
                        }
                    ]}
                    
                />
                <Button variant="outline-primary mr-3 mt-3" size="sm" onClick={() => { this.goBack() }}>Back</Button>
            </div>
        );
    }
}
export default InvoiceCreate;